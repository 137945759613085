import { MobileDetectorService } from './../../../services/mobile-detector/mobile-detector.service'
import { select, Store } from '@ngrx/store'
import { IProduct } from './../../../../site/modules/product/product.model'
import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { IBusinessResponse } from '../../../services/business/business.model'
import {selectBusinessDetails, selectSiteInitials} from '../../loader/reducers'
import {ISiteInitials} from "../../../initial-data/initial-data.model";
import {Router} from "@angular/router";
import {SiteRoutes} from "../../../../routes.const";

@Component({
  selector: 'bi-products-with-cart',
  templateUrl: './products-with-cart.component.html',
  styleUrls: ['./products-with-cart.component.less'],
})
export class ProductsWithCartComponent implements OnInit {
  @Input()
  products: IProduct[] = []

  isMobile: boolean
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  showInDeal: boolean;

  constructor(private mobileDetectorService: MobileDetectorService, private store: Store, private router: Router) {
    this.showInDeal = this.router.url === '/'+SiteRoutes.AddDeal
    this.isMobile = mobileDetectorService.isMobile()
  }



  ngOnInit(): void {}
}
