import { NgModule } from '@angular/core'
import { PushModule } from '@ngrx/component'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { SharedModule } from '../../../site/modules/shared.module'
import { DividedToppingModule } from './../products/add-product/categories/category/divided-category/divided-topping/divided-topping.module'
import { CartService } from './cart.service'
import { CartEffects } from './effects/cart.effects'
import { fromCart } from './reducers'
import { cartFeatureKey } from './reducers/cart.reducers'

@NgModule({
  imports: [
    StoreModule.forFeature(cartFeatureKey, fromCart.reducer),
    EffectsModule.forFeature([CartEffects]),
    SharedModule,
    PushModule,
    DividedToppingModule,
  ],
  providers: [CartService],
})
export class CartModule {}
