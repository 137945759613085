import { SiteRoutes } from './../../../routes.const'
import { INavItems } from './../header/nav-menu.model'

export const FooterNav: INavItems[] = [
  {
    name: 'אודות',
    url: SiteRoutes.About,
    icon: 'about.png',
  },
  {
    name: 'צור קשר עם הפיצריה',
    url: SiteRoutes.ContactPizziria,
    icon: 'email.png',
  },
  {
    name: 'צור קשר עם Bringit Technologies',
    url: SiteRoutes.ContactBringit,
    icon: 'email.png',
  },
  {
    name: 'הצעות לשיפור',
    url: SiteRoutes.SuggestImporve,
    icon: 'suggestImprovements.png',
  },
  {
    name: 'תקנון',
    url: SiteRoutes.Terms,
    icon: 'terms.png',
  },
]
