import { Action, createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromProducts from './products.reducers'

export function reducer(state: fromProducts.State, action: Action) {
  fromProducts.reducer(state, action)
}

export const selectProductsState = createFeatureSelector<fromProducts.State>(
  fromProducts.productsFeatureKey,
)

export const selectIsBackClick = createSelector(selectProductsState, fromProducts.getIsBackClick)
export const selectDealToAdd = createSelector(selectProductsState, fromProducts.getDeal)
export const selectDealPreAdd = createSelector(selectProductsState, fromProducts.getDealPreAdd)
export const selectDealItemToAdd = createSelector(selectProductsState, fromProducts.getDealItem)
export const selectProductToAdd = createSelector(selectProductsState, fromProducts.getProduct)
export const selectProductPreAdd = createSelector(
  selectProductsState,
  fromProducts.getProductPreAdd,
)
export const selectCategoryToAdd = createSelector(selectProductsState, fromProducts.getCategory)
export const selectToppingToAdd = createSelector(selectProductsState, fromProducts.getTopping)
export const selectDividedToppingToAdd = createSelector(
  selectProductsState,
  fromProducts.getToppingLocation,
)

export { fromProducts }

export const selectDealPreAddProduct = createSelector(
  selectProductsState,
  fromProducts.getDealPreAddProduct,
)

export const selectDealStep = createSelector(selectProductsState, fromProducts.getDealStep)

export const selectIsCategoryContinueDisabled = createSelector(
  selectProductsState,
  (state: fromProducts.State) => {
    let { product: productToAdd, productPreAdd: product, category: currentIndex } = state

    if (currentIndex === null || currentIndex === undefined) {
      return false
    }

    if (product && product.categories && productToAdd?.categories?.length) {
      let currentCategoryId = productToAdd.categories[currentIndex]?.id
      let categoryIndex = product.categories.findIndex(({ id }) => {
        return id === currentCategoryId
      })
      if (categoryIndex !== -1) {
        currentIndex = categoryIndex
      }
      let category = product.categories[currentIndex]
      let is_mandatory = productToAdd?.categories[currentIndex]?.is_mandatory
      if (!is_mandatory || (is_mandatory && category && category.products.length > 0)) {
        return false
      }
    }
    return true
  },
)
