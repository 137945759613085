import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store'
import { selectBusinessDetails, selectSiteInitials } from '../../../common/components/loader/reducers'
import { environment } from '../../../../environments/environment'
import { Observable } from 'rxjs'
import { IBusinessResponse } from '../../../common/services/business/business.model'
import { ISiteInitials } from '../../../common/initial-data/initial-data.model'

@Component({
  selector: 'bi-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {
  staticFilesPath = ''
  commonPath = ''

  business$:Observable<IBusinessResponse|null> = this.store.pipe(select(selectBusinessDetails));
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))


  constructor(private store: Store) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
      this.commonPath = `${environment.staticFiles + 'common'}/`
    })
  }

  ngOnInit(): void {
  }

}
