<form [formGroup]="contactForm" class="contactForm" (ngSubmit)="sendForm()">
  <div class="form-group">
    <div class="form-control">
      <input type="text" formControlName="fName" name="f_name" placeholder="*שם פרטי"  pattern="^(?!\s*$).+"/>
    </div>
    <div class="form-control">
      <input type="text" formControlName="lName" name="l_name" placeholder="*שם משפחה"  pattern="^(?!\s*$).+"/>
    </div>
  </div>

  <div class="form-group">
    <div class="form-control">
      <input type="phone" maxlength="10"  formControlName="phone" name="phone" placeholder="*טלפון"  pattern="^(?!\s*$).+"/>
    </div>
    <div class="form-control">
      <input type="email" formControlName="email" name="email" placeholder="*אימייל" pattern="^(?!\s*$).+" />
    </div>
  </div>

  <div class="form-group">
    <div class="form-control">
      <textarea formControlName="message" name="message" placeholder="*כתוב לנו"></textarea>
    </div>
  </div>

  <div class="feedback">
    <div class="helper">* - שדה חובה</div>
    <span *ngIf="errorText">{{errorText}}</span>
    <span *ngIf="!errorText">{{successText}}</span>
  </div>

  <div class="action">
    <button
      type="submit"
      class="bi-btn full"
      [class.bi-btn-ghost]="contactForm.invalid || isSending"
      [disabled]="contactForm.invalid || isSending"
    >
      שלח
    </button>
  </div>
</form>
