import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { IProduct, ProductType } from 'src/app/site/modules/product/product.model'
import { environment } from './../../../../environments/environment'
import { selectCartTotal } from './../../../common/components/cart/reducers/index'
import { selectBusinessDetails } from './../../../common/components/loader/reducers/index'
import { orderDefaults } from './order.const'
import { DeliveryOption, IAddress, IClient, IOrder, IOrderIn, PaymentType } from './order.model'
import { Router } from '@angular/router'
import { SiteRoutes } from '../../../routes.const'

@Injectable()
export class OrderService {
  selectedDeliveryOptionSubject$: BehaviorSubject<DeliveryOption | undefined> = new BehaviorSubject<
    DeliveryOption | undefined
  >(undefined)

  constructor(private http: HttpClient, private store: Store, private router: Router) {}

  getOrder(): IOrder {
    const order = localStorage.getItem('order')
    return order ? JSON.parse(order) : orderDefaults
  }

  setOrder(order: IOrder) {
    localStorage.setItem('order', JSON.stringify(order))
  }

  setBusinessId(id: string) {
    const order = this.getOrder()
    order.business_id = id
    this.setOrder(order)
  }

  getCart(): IProduct[] {
    const order = this.getOrder()
    return order.cart
  }

  setCart(cart: IProduct[]) {
    const order = this.getOrder()
    order.cart = cart
    this.setOrder(order)
  }

  clearCart() {
    const order = this.getOrder()
    order.cart = []
    this.setOrder(order)
    this.setDeliveryOption(undefined)
    this.router.navigate([SiteRoutes.Home])
  }

  setUserInfo(info: IClient) {
    const order = this.getOrder()
    order.userInfo = { ...order.userInfo, ...info }
    this.setOrder(order)
  }

  getUserInfo(): IClient {
    return this.getOrder().userInfo
  }

  setAddress(address: IAddress) {
    const order = this.getOrder()

    if (!order.userInfo) {
      order.userInfo = { address } as IClient
      this.setOrder(order)
      return
    }

    order.userInfo.address = { ...order.userInfo.address, ...address }
    this.setOrder(order)
  }

  setDeliveryOption(option: DeliveryOption) {
    this.cleanOrderDetailsInStorage()
    const order = this.getOrder()
    order.deliveryOption = option
    this.setOrder(order)
    this.selectedDeliveryOptionSubject$.next(option)
  }

  getDeliveryOption(): DeliveryOption | undefined {
    const order = this.getOrder()
    return order.deliveryOption
  }

  initDeliveryOptionObserver(): void {
    const option = this.getDeliveryOption()
    this.selectedDeliveryOptionSubject$.next(option)
  }

  setPayment(payment: PaymentType) {
    const order = this.getOrder()
    order.payment = payment
    this.setOrder(order)
  }

  setCoupon(coupon: string): void {
    const order = this.getOrder()
    order.coupon = coupon
    this.setOrder(order)
  }

  removeCoupon(): void {
    const order = this.getOrder()
    delete order.coupon
    this.setOrder(order)
  }

  cartHasCoupon(): boolean {
    const order = this.getOrder()
    return !!order.coupon
  }

  updateTotal() {
    const order = this.getOrder()
    order.total = this.calculateTotal(order.cart)
    this.setOrder(order)
  }

  calculateTotal(cart: IProduct[]): number {
    let total = 0
    cart.forEach((product: IProduct) => {
      let prodPrice = this.calcProductPrice(product)
      prodPrice *= product.count ? product.count : 1
      total += prodPrice
    })
    return total
  }

  calcProductPrice(product: IProduct): number {
    let total = product.price

    if (product.type_name === ProductType.deal) {
      product.items?.forEach((item) => {
        item.products.forEach((prod: IProduct) => {
          total += this.getTotalFromCategories(prod)
        })
      })
    } else {
      total += this.getTotalFromCategories(product)
    }

    return total
  }

  getTotal(): number {
    return this.getOrder().total
  }

  createOrder() {
    const order = this.getOrder()
    return this.http.post(`${environment.api2}orders`, order)
  }

  fetchOrder(orderId: string): Observable<IOrderIn> {
    const params = new HttpParams()
      .set('apiCtrl', 'pizziria')
      .set('do', 'getOrderInformation')
      .set('order_id', orderId)

    return this.http
      .get<{ order: IOrderIn }>(environment.api1, { params })
      .pipe(map((response) => response.order))
  }

  isOrderCreditPaid(): Observable<any> {
    const client = this.getUserInfo()
    const params = new HttpParams().set('apiCtrl', 'pizziria').set('do', 'isOrderPaid')

    return this.http.post(environment.api1, { phone: client.phone }, { params })
  }

  preCreditPayment(): Observable<{ token: string }> {
    const order = this.getOrder()
    return this.http.post<{ token: string }>(`${environment.api2}orders/credit`, order)
  }

  preCreditPaymentForCG(): Observable<{ paylink: string }> {
    const order = this.getOrder()
    return this.http.post<{ paylink: string }>(`${environment.api2}orders/credit`, order)
  }

  cleanOrderDetailsInStorage() {
    const business_id = this.getOrder().business_id
    const order = { ...orderDefaults, business_id }
    this.setOrder(order)
  }

  hasErrorMinOrder(): boolean {
    if (this.getDeliveryOption() !== 'delivery') {
      return false
    }

    let total = 0
    let minDeliveryPrice = 0
    combineLatest([
      this.store.pipe(take(1), select(selectCartTotal)),
      this.store.pipe(take(1), select(selectBusinessDetails)),
    ]).subscribe(([t, business]) => {
      total = t
      if (business) {
        minDeliveryPrice = business.business.min_delivery_price
      }
    })
    return total < minDeliveryPrice
  }

  private getTotalFromCategories = (product: IProduct): number => {
    let total = 0
    product.categories?.forEach((category) => {
      if (category.price) {
        total += category.price
      }

      category.products.forEach((p) => {
        const count = p.count ? p.count : 1
        total += p.price * count
      })

      category.multipleProducts?.forEach((p) => {
        const count = p.count ? p.count : 1
        total += p.price * count
      })
    })
    return total
  }
}
