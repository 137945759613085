import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { ISuggestion } from './suggest-improvments.model'

@Injectable()
export class SuggestImprovmentsService {
  constructor(private http: HttpClient) {}

  onAddSuggestion(suggestion: ISuggestion): Observable<any> {
    const params = new HttpParams()
      .set('apiCtrl', 'pizziria')
      .set('do', 'addImprovementsSuggestions')

    return this.http.post(environment.api1, suggestion, { params })
  }
}
