import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { CartActions } from '../../common/components/cart/actions'
import { MobileDetectorService } from '../../common/services/mobile-detector/mobile-detector.service'
import { ProductType } from '../modules/product/product.model'
import { selectCartProducts } from './../../common/components/cart/reducers/index'
import { SiteRoutes } from './../../routes.const'
import { OrderService } from './../modules/order/order.service'

@Component({
  selector: 'bi-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.less'],
})
export class CartPageComponent implements OnInit {
  isMobile: boolean
  productType = ProductType
  showMinOrderError = false
  cartProducts$ = this.store.pipe(select(selectCartProducts))

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private store: Store,
    private orderService: OrderService,
  ) {
    this.isMobile = this.mobileDetectorService.isMobile()
  }

  ngOnInit(): void {}

  onGoTo(type: ProductType) {
    let link = ''
    switch (type) {
      case ProductType.deal:
        link = `${SiteRoutes.Pizza}/${SiteRoutes.DealsSelect}`
        break
      case ProductType.pizza:
        link = `${SiteRoutes.Pizza}/${SiteRoutes.PizzaSelect}`
        break
      case ProductType.drink:
        link = `${SiteRoutes.Drinks}`
        break
      case ProductType.additionalOffer:
        link = `${SiteRoutes.AdditionalOffer}`
        break
    }

    if (link) {
      this.router.navigate([link])
    }
  }

  onGoToPay() {
    if (this.orderService.hasErrorMinOrder()) {
      this.showMinOrderError = true
      return
    }
    this.router.navigate([SiteRoutes.Notes])
  }

  onClearCart() {
    this.store.dispatch(CartActions.onClearCart())
  }
}
