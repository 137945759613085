import { createReducer, on } from '@ngrx/store'
import { IProduct } from '../../../../site/modules/product/product.model'
import { CartActions } from '../actions'
export const cartFeatureKey = 'cartKey'

export interface State {
  cart: IProduct[]
  total: number
}

const initState: State = {
  cart: [],
  total: 0,
}

export const reducer = createReducer(
  initState,
  on(CartActions.onCartTotalUpdated, (state, total) => {
    return {
      ...state,
      total: total.total,
    }
  }),
  on(CartActions.onUpdateCartProducts, (state, { cart }) => {
    return {
      ...state,
      cart,
    }
  }),
)

export const getTotal = (state: State) => state.total
export const getCart = (state: State) => state.cart
