import { selectSiteInitials } from './../../loader/reducers/index'
import { Store, select } from '@ngrx/store'
import { Component, OnInit } from '@angular/core'
import { environment } from './../../../../../environments/environment'
import { SiteRoutes } from './../../../../routes.const'
import { selectCartProducts } from '../../cart/reducers'

@Component({
  selector: 'bi-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.less'],
})
export class MobileHeaderComponent implements OnInit {
  staticFilesPath = ''
  isSideNavOpened = false
  siteRoutes = SiteRoutes
  cartCount:any

  constructor(private store: Store) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
    store.pipe(select(selectCartProducts)).subscribe((cart) => {
      this.cartCount = 0
      if (cart.length) {
        cart.forEach(item => {
          if (item.type_name === 'deal') {
            this.cartCount++
            return
          }
          this.cartCount += item.count
        })
      }
    })
  }

  ngOnInit(): void {}

  openSideNav() {
    this.isSideNavOpened = true
  }

  onCloseNav() {
    this.isSideNavOpened = false
  }
}
