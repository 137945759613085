export enum SiteRoutes {
  Home = '/',
  AddPizza = 'pizza/add',
  AddDeal = 'deal/add',
  Pizza = 'pizza',
  DealsSelect = 'deals',
  PizzaSelect = 'select',
  Drinks = 'drinks',
  AdditionalOffer = 'others',
  Cart = 'cart',
  Notes = 'notes',
  Payment = 'payment',
  ConfirmCash = 'cash',
  Tracker = 'tracker',
  About = 'about',
  DealsPage = 'pizza/deals',
  ContactPizziria = 'contact/pizziria',
  ContactBringit = 'contact/bringit',
  SuggestImporve = 'improvments',
  Terms = 'terms',
  AndroidApp = 'mobile/android',
  IOSApp = 'mobile/ios',
  UnsubscribeAds = 'unsubscribe',
  General = 'general',
}
