import { Action, createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromSiteLoader from './site-loader.reducers'

export function reducer(state: fromSiteLoader.State, action: Action) {
  return fromSiteLoader.reducer(state, action)
}

export const selectSiteLoaderState = createFeatureSelector<fromSiteLoader.State>(
  fromSiteLoader.siteLoaderFeatureKey,
)

export const selectLoadingSite = createSelector(selectSiteLoaderState, fromSiteLoader.getLoading)

export const selectSiteInitials = createSelector(
  selectSiteLoaderState,
  fromSiteLoader.getSiteInitials,
)

export const selectBusinessDetails = createSelector(
  selectSiteLoaderState,
  fromSiteLoader.getBusinessDetails,
)

export const selectIsNewMenu = createSelector(selectSiteLoaderState, fromSiteLoader.getIsNewMenu)

export { fromSiteLoader }
