import { Injectable } from '@angular/core'
import { IProductCategory } from '../../../../../site/modules/product/product.model'
import { ProductsService } from '../../../../../site/modules/product/products.service'

@Injectable()
export class CategoriesService {
  constructor(private productsService: ProductsService) {}
  isShowFixedPrice(category: IProductCategory): boolean {
    if (!category.category_has_fixed_price) {
      return false
    }

    const selectedCount = this.productsService.getSelectetToppingsCount(category)
    return selectedCount < category.products_fixed_price
  }
}
