<ng-container *ngIf="siteData$ | ngrxPush as siteData">
  <div class="header-item header-logo">
    <a [routerLink]="siteRoutes.Home">
      <img [src]="staticFilesPath + 'medium_logo.png'" class="responsive" alt="לוגו" />
    </a>
  </div>
  <div
    class="header-item"
    [class.cart]="item.url === siteRoutes.Cart"
    *ngFor="let item of navItems"
  >
    <ng-template [ngIf]="item.children?.length" [ngIfElse]="category">
      <button
        class="item-link"
        [ngClass]="{'disabled': isRoutesDisabled}"
        [disabled]="isRoutesDisabled"
        [matMenuTriggerFor]="menu"
      >
        {{item.name}}

        <img width="14" height="14" src="assets/icons/dropdown-arrow.svg" alt="Dropdown arrow" />
      </button>
      <mat-menu #menu="matMenu" classList="categories-menu">
        <button mat-menu-item [disabled]="isRoutesDisabled" *ngFor="let i of item.children">
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: {item:i, siteData:siteData}"
          ></ng-container>
        </button>
      </mat-menu>
    </ng-template>

    <ng-template #category>
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: {item:item, siteData:siteData}"
      ></ng-container>
    </ng-template>
  </div>
</ng-container>

<ng-template #itemTemplate let-item="item" let-siteData="siteData">
  <a
    [routerLink]="isRoutesDisabled ? null : item.url"
    routerLinkActive="active"
    class="item-link"
    [ngClass]="{
        'active': item.url?.includes(siteRoutes.Pizza) && pizzasActive.isActive,
        'disabled': isRoutesDisabled
      }"
  >
    <span class="cart-count" *ngIf="item.url === siteRoutes.Cart && cartCount"
      >({{cartCount}})
    </span>
    <span *ngIf="item.url === siteRoutes.Cart else nameBlock">
      <div class="menu-cart-item">
        <img *ngIf='siteData.theme === "light"' [src]="staticFilesPath+'cart_logo.svg'" />
      </div>
    </span>
    <ng-template #nameBlock>{{item.name}}</ng-template>
  </a>
  <!--
   active link for different routes  -->
  <a
    [routerLink]="siteRoutes.PizzaSelect"
    routerLinkActive
    #pizzasActive="routerLinkActive"
    style="display: none"
  ></a>
</ng-template>
