<div class="nav-top">
  <div class="medium_logo">
    <img [src]="staticFilesPath + 'medium_logo.png'" alt="לוגו בינוני" />
  </div>
<!--  <div class="business-name">-->
<!--    <ng-container *ngIf="business$ | ngrxPush as business">-->
<!--      <span> {{business.business.business_name_commercial}} </span>-->
<!--    </ng-container>-->
<!--  </div>-->
  <div class="close-nav" (click)="closeNav()">&#10005;</div>
</div>

<div class="nav-items-container">
  <ul id="nav-menu" *ngIf="business$ | ngrxPush as business">
    <li class="nav-item" *ngFor="let item of navItems" (click)="onLinkClick(item.url)">
      <span class="icon"><img [src]="staticFilesPath + 'icons/' + item.icon" alt="" /></span>
      <span>{{ item.name}}</span>
<!--      <span *ngIf='item.url === siteRoutes.ContactPizziria'>-->
<!--        {{ business.business.business_name_commercial }}-->
<!--        צור קשר עם הפיצריה-->
<!--      </span>-->
    </li>
  </ul>
</div>
<div class='mobile-nav-logo'>
  <img style="width: 100px;" [src]="staticFilesPath + 'bringit_logo.svg'" alt="bringit logo" />
</div>
