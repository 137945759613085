import { createReducer, on } from '@ngrx/store'
import {
  IProduct,
  IProductDeal,
  ToppingLocation,
} from '../../../../site/modules/product/product.model'
import { ProductsActions } from '../actions'

export const productsFeatureKey = 'productsKey'

export interface State {
  deal: IProductDeal | null
  dealPreAdd: IProductDeal | null
  dealItem: number | null
  product: IProduct | null
  productPreAdd: IProduct | null
  category: number | null
  topping: number | null
  location: ToppingLocation | null
  dealPreAddProduct: IProduct | null
  isBackClick: boolean
  dealStep: number
}

const initState: State = {
  deal: null,
  dealPreAdd: null,
  dealItem: null,
  product: null,
  productPreAdd: null,
  category: null,
  topping: null,
  location: null,
  dealPreAddProduct: null,
  isBackClick: false,
  dealStep: -1,
}

export const reducer = createReducer(
  initState,
  on(ProductsActions.onAddDeal, (state, { deal }) => {
    return {
      ...state,
      deal,
      dealStep: deal === null ? -1 : 0,
    }
  }),
  on(ProductsActions.onPreAddDeal, (state, { dealPreAdd }) => {
    return {
      ...state,
      dealPreAdd,
    }
  }),
  on(ProductsActions.onSelectDealItem, (state, { index }) => {
    const { dealStep, isBackClick, dealPreAdd } = state
    let indexToStep: number
    if (index === null) {
      indexToStep = -1
    } else {
      const hasCategories = dealPreAdd?.items[index]?.products[0]?.categories.length
      const indexBy2 = index * 2
      if (indexBy2 > dealStep || indexBy2 + 1 < dealStep) {
        indexToStep = isBackClick && hasCategories ? indexBy2 + 1 : indexBy2
      } else {
        indexToStep = isBackClick ? dealStep - 1 : dealStep
      }
    }

    const dealPreAddProduct = dealPreAdd?.items?.[index ?? -1]?.products[0] ?? null

    return {
      ...state,
      dealItem: index,
      dealStep: indexToStep,
      dealPreAddProduct,
    }
  }),
  on(ProductsActions.onAddProduct, (state, { product }) => {
    return {
      ...state,
      product,
    }
  }),
  on(ProductsActions.onAddProductUpdated, (state, { product }) => {
    return {
      ...state,
      product,
    }
  }),
  on(ProductsActions.onPreAddProduct, (state, { productPreAdd }) => {
    if (productPreAdd === undefined) {
      return { ...state }
    }
    return {
      ...state,
      productPreAdd,
    }
  }),
  on(ProductsActions.onSelectCategoy, (state, { index }) => {
    return {
      ...state,
      category: index,
    }
  }),
  on(ProductsActions.onAddTopping, (state, { index }) => {
    return {
      ...state,
      topping: index,
    }
  }),
  on(ProductsActions.onAddDividedTopping, (state, { location, index }) => {
    return {
      ...state,
      location,
      topping: index,
    }
  }),
  on(ProductsActions.onSelectDealPreAddProduct, (state, { dealPreAddProduct }) => {
    if (dealPreAddProduct === undefined) {
      return { ...state }
    }
    return {
      ...state,
      dealPreAddProduct,
    }
  }),
  on(ProductsActions.onCategoryBack, (state) => {
    return {
      ...state,
      dealPreAddProduct: null,
      isBackClick: true,
    }
  }),
  on(ProductsActions.onPageContinue, (state) => {
    return {
      ...state,
      isBackClick: false,
    }
  }),
  on(ProductsActions.onDealStep, (state, { dealStep }) => {
    return {
      ...state,
      dealStep,
    }
  }),
)

export const getDeal = (state: State) => state.deal
export const getDealPreAdd = (state: State) => state.dealPreAdd
export const getDealItem = (state: State) => state.dealItem
export const getProduct = (state: State) => state.product
export const getProductPreAdd = (state: State) => state.productPreAdd
export const getCategory = (state: State) => state.category
export const getTopping = (state: State) => state.topping
export const getToppingLocation = (state: State) => ({
  location: state.location,
  index: state.topping,
})

export const getDealPreAddProduct = (state: State) => state.dealPreAddProduct
export const getIsBackClick = (state: State) => state.isBackClick
export const getDealStep = (state: State) => state.dealStep
