import { ISiteInitials, IThemes } from './../../initial-data/initial-data.model'
import { selectSiteInitials } from './../../components/loader/reducers/index'
import { Store, select } from '@ngrx/store'
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Injectable()
export class StylesLoaderService {
  private _renderer: Renderer2
  private _themes: IThemes[] = []

  constructor(
    rendererFactory: RendererFactory2,
    private store: Store,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this._renderer = rendererFactory.createRenderer(null, null)
  }

  load() {
    this.store.pipe(select(selectSiteInitials)).subscribe((data: ISiteInitials) => {
      this._themes = data.styles
    })

    const promises: any[] = []
    this._themes.forEach((theme) => promises.push(this._loadTheme(theme.name)))
    return Promise.all(promises)
  }

  private _isThemeLoaded(name: string) {
    if (this.getThemeByName(name).loaded) {
      return true
    }
    return false
  }

  private _renderTheme(name: string) {
    const existingLinkElm = this._document.getElementById(`${name}-style-file`) as HTMLLinkElement
    if (existingLinkElm) {
      existingLinkElm.href = `${this.getThemeByName(name).name}.css`
      return existingLinkElm
    } else {
      const style = this._renderer.createElement('link')
      style.rel = 'stylesheet'
      style.type = 'text/css'
      style.id = `${name}-style-file`
      style.href = `${this.getThemeByName(name).name}.css`
      return style
    }
  }

  private getThemeByName(name: string): IThemes {
    return this._themes.find((theme) => theme.name === name) || ({} as IThemes)
  }

  private _setThemeStatus(name: string, status = true) {
    const theme = this.getThemeByName(name)
    // if (theme) {
    //   theme.loaded = status
    // }

    return {
      name,
      loaded: status,
    }
  }

  private _loadTheme(name: string) {
    return new Promise((resolve) => {
      if (this._isThemeLoaded(name)) {
        return resolve(this._setThemeStatus(name))
      }
      const theme = this._renderTheme(name)
      theme.onload = () => {
        resolve(this._setThemeStatus(name))
      }
      theme.onerror = () => resolve(this._setThemeStatus(name, false))
      this._renderer.appendChild(this._document.getElementsByTagName('head')[0], theme)
    })
  }
}
