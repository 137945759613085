import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { selectBusinessDetails } from '../../../common/components/loader/reducers'

@Component({
  selector: 'bi-ios',
  templateUrl: './ios.component.html',
  styleUrls: ['./ios.component.less'],
})
export class IosComponent implements OnInit {
  showMessage = false
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business?.business.ios_app) {
        window.location.replace(business.business.ios_app)
        this.showMessage = false
      } else {
        this.showMessage = true
      }
    })
  }
}
