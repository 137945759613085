import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { select, Store } from '@ngrx/store'
import { EMPTY, iif, Observable, of } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators'
import { Subscriber } from '../../../../common/classes/subscriber/subscriber'
import {
  ClientAddressService, ICity,
  IStreetSearh,
} from '../../../home/home/select-address/delivery-address/client-address.service'
import { OrderService } from './../../../modules/order/order.service'
import { selectBusinessDetails } from '../../../../common/components/loader/reducers'

@Component({
  selector: 'bi-select-delivery-address',
  templateUrl: './select-delivery-address.component.html',
  styleUrls: ['./select-delivery-address.component.less'],
})
export class SelectDeliveryAddressComponent extends Subscriber implements OnInit {
  @Input()
  addressForm!: UntypedFormGroup

  streets?: IStreetSearh[]
  streetErr = ''
  isLoadingStreets = false
  isStreetSelected = false
  selectedStreet = ''
  selectedCity = ''
  businessCities?:ICity[]
  selectedCityName?: string

  constructor(
    private store: Store,
    orderService: OrderService,
    private clientAddressService: ClientAddressService,
  ) {
    super()

    this.store
      .pipe(takeUntil(this.ngUnsubscribe), select(selectBusinessDetails))
      .subscribe((business) => {
        if (business) {
          this.businessCities = business.business.cities
        }
      })
  }

  ngOnInit() {
    this.onStreetValueChanges()
  }

  get city_id(): AbstractControl {
    return this.addressForm.get('city_id') as AbstractControl
  }

  get city_name(): AbstractControl {
    return this.addressForm.get('city_name') as AbstractControl
  }

  get street(): AbstractControl {
    return this.addressForm.get('street') as AbstractControl
  }

  get house_num(): AbstractControl {
    return this.addressForm.get('house_num') as AbstractControl
  }

  onAutocompleteClose() {
    if (this.selectedCity != this.city_id.value) {
      this.house_num.reset()
      this.house_num.disable()
    } else {
      this.house_num.enable()
    }
    this.isStreetSelected = false
  }

  onSelectOptionCity(selected: MatAutocompleteSelectedEvent) {
    this.selectedCity = selected.option.id
    this.selectedCityName = selected.option.value
    if (this.selectedCity != this.city_id.value) {
      this.street.patchValue('')
    }
    this.addressForm.controls.city_id = new UntypedFormControl(this.selectedCity)
    this.addressForm.controls.city_name = new UntypedFormControl(this.selectedCityName)
    this.streets = this.businessCities?.find(c => c.id === this.selectedCity)?.streets
  }

  onSelectOptionStreet(selected: MatAutocompleteSelectedEvent) {
    this.selectedStreet = selected.option.value
    this.isStreetSelected = !!this.selectedStreet
  }

  private onStreetValueChanges() {
    this.street.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((val) => val.length > 1),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => {
          this.streets = []
          this.isLoadingStreets = true
        }),
        switchMap((str) =>
          iif(
            () => this.selectedStreet === this.street.value,
            of(<IStreetSearh[]>[]),
            this.searchStreet(str),
          ),
        ),
      )
      .subscribe((streets) => {
        this.isLoadingStreets = false
        this.streets = streets
        this.streetErr = ''
      })
  }

  private searchStreet(str: string): Observable<IStreetSearh[]> {
    return this.clientAddressService.searchStreet(this.city_id.value, str).pipe(
      catchError((error) => {
        this.streetErr = error.error.message
        this.streets = []
        this.isLoadingStreets = false
        return EMPTY
      }),
    )
  }
}
