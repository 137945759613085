import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { CartActions } from '../../../../common/components/cart/actions'
import { ProductsActions } from '../../../../common/components/products/actions'
import { OrderActions } from '../actions'
import { OrderService } from './../order.service'

@Injectable()
export class OrderEffects {
  onCompletedOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.onCompletedOrder),
      map(() => {
        this.orderService.cleanOrderDetailsInStorage()
        this.store.dispatch(ProductsActions.onAddDeal({ deal: null }))
        return CartActions.onClearCart()
      }),
    ),
  )

  constructor(
    private store: Store,
    private actions$: Actions,
    private orderService: OrderService,
  ) {}
}
