import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { selectSiteInitials } from '../loader/reducers'
import { environment } from '../../../../environments/environment'
import { SiteRoutes } from './../../../routes.const'

@Component({
  selector: 'bi-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.less'],
})
export class PageNotFoundComponent implements OnInit {
  staticFilesPath = ''
  homeUrl = SiteRoutes.Home

  constructor(private store: Store) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
  }

}
