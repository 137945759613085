import { DeliveryOption } from './../order/order.model'
import { IProduct, IProductCategory, ProductType, ToppingLocation } from './product.model'

export const transformProductsIn = (
  products: IProduct[],
  deliveryOption: DeliveryOption | undefined,
): IProduct[] => {
  const priceProp = deliveryOption === 'delivery' ? 'delivery_price' : 'not_delivery_price'

  products.forEach((product: IProduct) => {
    product.price = product[priceProp]
    if (product.type_name === ProductType.deal) {
      product.items?.forEach((item) => {
        item.products.forEach((itemProd) => {
          itemProd.price = itemProd[priceProp]
          if (itemProd.categories.length) {
            itemProd.categories.forEach((category) => {
              if (category.products?.length) {
                setToppingOriginalPrice(category)
              }
            })
          }
        })
      })
    } else if (product.categories?.length) {
      product.categories.forEach((category) => {
        if (category.products?.length) {
          setToppingOriginalPrice(category)
        }
      })
    }
  })

  return products
}

const setToppingOriginalPrice = (category: IProductCategory): IProductCategory => {
  category.products.forEach((topping) => {
    topping.originalPrice = topping.price
  })
  return category
}

export const quartersCountMap: { [key in ToppingLocation]: number } = {
  full: 4,
  rightHalfPizza: 2,
  leftHalfPizza: 2,
  tl: 1,
  tr: 1,
  bl: 1,
  br: 1,
}
