import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { environment } from './../../../../../../../environments/environment'
import {
  IProductCategory,
  IToppingProduct,
} from './../../../../../../site/modules/product/product.model'

@Component({
  selector: 'bi-draw-topping-circle',
  templateUrl: './draw-topping-circle.component.html',
  styleUrls: ['./draw-topping-circle.component.less'],
})
export class DrawToppingCircleComponent implements OnInit, AfterViewInit {
  @ViewChild('tr')
  tr!: ElementRef

  @ViewChild('tl')
  tl!: ElementRef

  @ViewChild('br')
  br!: ElementRef

  @ViewChild('bl')
  bl!: ElementRef

  @Input()
  categories!: IProductCategory[] | undefined

  toppingUrl = environment.staticFiles + 'topping/'

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngOnChanges({ categories }: SimpleChanges) {
    if (categories && !categories.firstChange && this.categories?.length) {
      this.drawToppings()
    }
  }

  ngAfterViewInit() {
    this.drawToppings()
  }

  private drawToppings() {
    const dividedCategories = this.categories?.filter(
      ({ is_topping_divided }) => is_topping_divided,
    )
    if (!dividedCategories?.length) {
      return
    }

    const toppings = dividedCategories.reduce((acc: IToppingProduct[], category) => {
      acc = acc.concat(category.products)
      return acc
    }, [])

    let tlCss = '' //this.tl.nativeElement.style.backgroundImage
    let trCss = ''
    let blCss = ''
    let brCss = ''

    toppings.forEach((topping) => {
      if (topping.location) {
        const topLeft = `url(${this.toppingUrl}topLeft/${topping.picture}),`
        const topRight = `url(${this.toppingUrl}topRight/${topping.picture}),`
        const bottomLeft = `url(${this.toppingUrl}bottomLeft/${topping.picture}),`
        const bottomRight = `url(${this.toppingUrl}bottomRight/${topping.picture}),`

        if (topping.location === 'full') {
          tlCss += topLeft
          trCss += topRight
          blCss += bottomLeft
          brCss += bottomRight
        } else if (topping.location === 'leftHalfPizza') {
          tlCss += topLeft
          blCss += bottomLeft
        } else if (topping.location === 'rightHalfPizza') {
          trCss += topRight
          brCss += bottomRight
        } else if (topping.location === 'tl') {
          tlCss += topLeft
        } else if (topping.location === 'tr') {
          trCss += topRight
        } else if (topping.location === 'bl') {
          blCss += bottomLeft
        } else if (topping.location === 'br') {
          brCss += bottomRight
        }
      }
    })

    tlCss = tlCss.replace(/(,)$/, '')
    trCss = trCss.replace(/(,)$/, '')
    blCss = blCss.replace(/(,)$/, '')
    brCss = brCss.replace(/(,)$/, '')

    if (tlCss) {
    }
    this.renderer.setStyle(this.tl.nativeElement, 'background-image', tlCss)

    this.renderer.setStyle(this.tr.nativeElement, 'background-image', trCss)
    if (trCss) {
    }

    this.renderer.setStyle(this.bl.nativeElement, 'background-image', blCss)
    if (blCss) {
    }

    this.renderer.setStyle(this.br.nativeElement, 'background-image', brCss)
    if (brCss) {
    }
  }
}
