import { Component, OnInit } from '@angular/core'
import { GuardsCheckEnd, NavigationStart, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { takeUntil } from 'rxjs/operators'
import { SiteRoutes } from '../../../../routes.const'
import { MobileDetectorService } from '../../../services/mobile-detector/mobile-detector.service'
import { selectBusinessDetails } from '../../loader/reducers'
import { Subscriber } from './../../../classes/subscriber/subscriber'

@Component({
  selector: 'bi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent extends Subscriber implements OnInit {
  isMobile: boolean
  isClosed!: boolean
  showNavs: boolean = true
  mobileWidth: any

  constructor(
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
  ) {
    super()
    this.isMobile = mobileDetectorService.isMobile()
    store
      .pipe(takeUntil(this.ngUnsubscribe), select(selectBusinessDetails))
      .subscribe((business) => {
        if (business) {
          this.isClosed = business.business.is_opened == 0
        }
      })
    this.router.events.subscribe((ev) => {
      // update cart state
      if (ev instanceof NavigationStart) {
      } else if (ev instanceof GuardsCheckEnd) {
        this.showNavs = !(this.mobileWidth < 520 && ev.url == `/${SiteRoutes.AddDeal}`)
      }
    })
  }

  ngOnInit(): void {
    this.mobileWidth = window.innerWidth
  }
}
