import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PushModule } from '@ngrx/component'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { SimplebarAngularModule } from 'simplebar-angular'
import { environment } from '../environments/environment'
import { CartModule } from './common/components/cart/cart.module'
import { FooterModule } from './common/components/footer/footer.module'
import { HeaderModule } from './common/components/header/header.module'
import { InfoModalComponent } from './common/components/info-modal/info-modal.component'
import { SiteLoaderModule } from './common/components/loader/site-loader.module'
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component'
import { ProductsModule } from './common/components/products/products.module'
import { TermsAndConditionsComponent } from './common/components/terms-and-conditions/terms-and-conditions.component'
import { ThankYouComponent } from './common/components/thank-you/thank-you.component'
import { RequestsInterceptor } from './common/interceptors/requests/requests.interceptor'
import { SafePipe } from './common/pipes/safe/safe.pipe'
import { BusinessApiService } from './common/services/business/business.api.service'
import { InitSiteService } from './common/services/init-site/init-site.service'
import { MobileDetectorService } from './common/services/mobile-detector/mobile-detector.service'
import { StylesLoaderService } from './common/services/styles-loader/styles-loader.service'
import { TermsAndConditionsService } from './common/services/terms-and-conditions.service'
import { SiteRoutingModule } from './site-routing.module'
import { SiteComponent } from './site.component'
import { AlikesModule } from './site/alikes/alikes.module'
import { AddCouponModalComponent } from './site/cart-page/add-coupon-modal/add-coupon-modal.component'
import { CartPageComponent } from './site/cart-page/cart-page.component'
import { AndroidComponent } from './site/mobile-apps/android/android.component'
import { OrderEffects } from './site/modules/order/effects/order.effects'
import { OrderService } from './site/modules/order/order.service'
import { SharedModule } from './site/modules/shared.module'
import { PreOrderModalComponent } from './site/pre-order-modal/pre-order-modal.component'
import { UnsubscribeAdsComponent } from './site/unsubscribe-ads/unsubscribe-ads.component'
@NgModule({
  imports: [
    BrowserModule,
    SiteRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SiteLoaderModule,
    SimplebarAngularModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    EffectsModule.forRoot([OrderEffects]),
    PushModule,
    HeaderModule,
    FooterModule,
    BrowserAnimationsModule,
    SharedModule,
    AlikesModule,
    MatProgressBarModule,
    CartModule,
    ProductsModule,
    MatDialogModule,
  ],
  declarations: [
    SiteComponent,
    PageNotFoundComponent,
    CartPageComponent,
    TermsAndConditionsComponent,
    SafePipe,
    ThankYouComponent,
    AndroidComponent,
    PreOrderModalComponent,
    AddCouponModalComponent,
    InfoModalComponent,
    UnsubscribeAdsComponent,
  ],
  providers: [
    InitSiteService,
    StylesLoaderService,
    MobileDetectorService,
    BusinessApiService,
    OrderService,
    TermsAndConditionsService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestsInterceptor, multi: true },
  ],
  bootstrap: [SiteComponent],
})
export class SiteModule {}
