import { IOrder, IOrderStatus } from './order.model'

export const orderDefaults: IOrder = {
  cart: [],
  total: 0,
  userInfo: {
    f_name: '',
    l_name: '',
    phone: '',
    in_advertising_list: '',
    notes: {
      order: '',
    },
  },
  business_id: '',
}

export const orderStatuses: IOrderStatus[] = [
  {
    status: 'received',
    displayStatus: 'התקבל',
    sort: 0,
  },
  {
    status: 'preparing',
    displayStatus: 'בהכנה',
    sort: 1,
  },
  {
    status: 'cooking',
    displayStatus: 'בתנור',
    sort: 2,
  },
  {
    status: 'packing',
    displayStatus: 'באריזה',
    sort: 3,
  },
  {
    status: 'sent',
    displayStatus: 'בדרך',
    sort: 4,
  },
  {
    status: 'finished',
    displayStatus: 'מוכן',
    sort: 5,
  },
]
