import {Component, OnInit,} from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { takeUntil } from 'rxjs/operators'
import { selectBusinessDetails, selectSiteInitials } from '../../../common/components/loader/reducers'
import { MobileDetectorService } from '../../../common/services/mobile-detector/mobile-detector.service'
import { TermsAndConditionsService } from '../../../common/services/terms-and-conditions.service'
import { Subscriber } from './../../../common/classes/subscriber/subscriber'
import { SiteRoutes } from './../../../routes.const'
import { DeliveryOption } from './../../modules/order/order.model'
import { OrderService } from './../../modules/order/order.service'
import { Observable } from 'rxjs'
import { ISiteInitials } from '../../../common/initial-data/initial-data.model'
import { MatDialog } from '@angular/material/dialog'
import { SelectPaymentTypeModalComponent } from '../select-payment-type-modal/select-payment-type-modal.component'
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {IBusiness, IBusinessResponse} from "../../../common/services/business/business.model";
import {InfoModalComponent} from "../../../common/components/info-modal/info-modal.component";

@Component({
  selector: 'bi-address-and-notes',
  templateUrl: './address-and-notes.component.html',
  styleUrls: ['./address-and-notes.component.less'],
})

export class AddressAndNotesComponent extends Subscriber implements OnInit {
  title: string
  detailsForm: UntypedFormGroup
  business!: IBusiness

  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  siteData: any

  address: UntypedFormGroup = new UntypedFormGroup({
    city_id: new UntypedFormControl('', Validators.required),
    city_name: new UntypedFormControl('', Validators.required),
    street: new UntypedFormControl('', Validators.required),
    house_num: new UntypedFormControl( '', Validators.required),
    floor: new UntypedFormControl(),
    entrance: new UntypedFormControl(),
    apt_num: new UntypedFormControl(),
  })
  in_advertising_list: UntypedFormControl = new UntypedFormControl(true);
  acceptedTerms = new UntypedFormControl(false, Validators.required)
  deliveryOption: DeliveryOption
  isMobile: boolean
  showPromo: any
  isPickup = false
  businessAddress = ''

  constructor(
    private http: HttpClient,
    private orderService: OrderService,
    private router: Router,
    private termsAndConditionsService: TermsAndConditionsService,
    private mobileDetectorService: MobileDetectorService,
    private store: Store,
    private dialogRef: MatDialog,
  ) {
    super()
    this.siteData$.subscribe(data => this.siteData = data).unsubscribe()
    this.isMobile = this.mobileDetectorService.isMobile()
    const order = orderService.getOrder()
    this.store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business) {
        this.business = business.business
      }
    })
    if (!order.userInfo.notes) {
      // temp fix for not existing notes
      order.userInfo.notes = {
        order: '',
      }
      orderService.setUserInfo(order.userInfo)
    }

    this.deliveryOption = order.deliveryOption || 'pickup'
    this.isPickup = this.deliveryOption === 'pickup'
    this.title = 'פרטי לקוח'

    if (this.isPickup) {
      this.store
        .pipe(takeUntil(this.ngUnsubscribe), select(selectBusinessDetails))
        .subscribe((business) => {
          if (business) {
            this.businessAddress = business.business.business_address
          }
        })
    }




    this.detailsForm = new UntypedFormGroup({
      f_name: new UntypedFormControl(order.userInfo.f_name, Validators.required),
      l_name: new UntypedFormControl(order.userInfo.l_name, Validators.required),
      phone: new UntypedFormControl('', [Validators.required,Validators.pattern("^[0-9]{10}$")]),
      // in_advertising_list: new UntypedFormControl(this.in_advertising_list.value),
      notes: new UntypedFormGroup({
        order: new UntypedFormControl(order.userInfo.notes.order),
      }),
    })
    this.detailsForm.addControl('in_advertising_list', this.in_advertising_list);

    if (order.deliveryOption === 'delivery') {
      this.title += ' ומשלוח'

      this.initControlAddress()
    }
  }


  ngOnInit(): void {
    const showAlert = document.querySelector('#in_advertising_list') as HTMLInputElement

    const phoneField =  document.querySelector('#phone-number') as HTMLInputElement
    phoneField.addEventListener('input', (e)=> {
      if(phoneField.value.length == 10) {
        fetch(`${environment.api2}business/client/${this.business?.business_id}/${phoneField.value}`)
            .then(response => response.json())
            .then( (result) => {
              if (result.data.in_advertising_list == false) {
                this.showPromo = true
              } else  {
                this.showPromo = false
              }
            } )
      } else {
        this.showPromo = false
      }
    })
  }

  toggleCheckbox(event: Event): void {
    this.in_advertising_list.setValue((event.target as HTMLInputElement).checked);
    console.log(this.in_advertising_list)
  }



  get city_id(): AbstractControl {
    return this.address.get('city_id') as AbstractControl
  }

  get city_name(): AbstractControl {
    return this.address.get('city_name') as AbstractControl
  }

  get street(): AbstractControl {
    return this.address.get('street') as AbstractControl
  }

  get house_num(): AbstractControl {
    return this.address.get('house_num') as AbstractControl
  }

  get entrance(): AbstractControl {
    return this.address.get('entrance') as AbstractControl
  }

  get floor(): AbstractControl {
    return this.address.get('floor') as AbstractControl
  }

  get apt_num(): AbstractControl {
    return this.address.get('apt_num') as AbstractControl
  }

  openTermsConditions() {
    this.termsAndConditionsService.openTermsAndConditions()
  }

  onSubmit() {
    this.orderService.setUserInfo(this.detailsForm.value)
    if (this.siteData.theme === 'light') {
      this.dialogRef.open(SelectPaymentTypeModalComponent, {maxWidth: '90%', width: '540px', panelClass: 'select-payment-type-modal'})
      return
    }
    this.router.navigate([SiteRoutes.Payment])
  }

  private initControlAddress() {
    const order = this.orderService.getOrder()
    const city_id = order.userInfo.address?.city_id || ''
    const city_name = order.userInfo.address?.city_name || ''
    const street = order.userInfo.address?.street || ''
    const house_num = order.userInfo.address?.house_num || ''
    const entrance = order.userInfo.address?.entrance || ''
    const floor = order.userInfo.address?.floor || ''
    const apt_num = order.userInfo.address?.apt_num || ''

    this.city_id.patchValue(city_id)
    this.city_name.patchValue(city_name)
    this.street.patchValue(street)
    this.house_num.patchValue(house_num)
    this.entrance.patchValue(entrance)
    this.floor.patchValue(floor)
    this.apt_num.patchValue(apt_num)

    this.detailsForm.addControl('address', this.address)
  }
  showPromoModal() {
    if(this.showPromo && this.in_advertising_list.value) {
      this.dialogRef.open(InfoModalComponent, {
        height: '200px',
        width: '375px',
        panelClass: 'info-modal',
        data: {
          text:
            'אנו שולחים קופוני הנחה, הטבות ומתנות ללקוחות שהזמינו בעבר ולצערינו לא נוכל לעשות זאת יותר - אבל יש לך עוד הזדמנות להתחרט',
          okBtnText: 'הבנתי',
        },
      })
    }
  }
}
