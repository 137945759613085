<div class="container add-product-modal" *ngIf="product">
  <div class="description-content" id="description-popup">
    <div (click)="closeAllDialogs()" mat-dialog-close class="button popup-close">X</div>
    <div class="img-container" *ngIf="!product.shape">
      <img src="{{product.imageUrl}}" alt="{{product.name}}/" />
    </div>

    <ng-container>
      <div class="img-container">
        <h2 class="product-name">{{product.name}}</h2>
        <div class="img-wrapper">
          <img
            *ngIf="imageUrl && product.shape?.length"
            [src]="imageUrl"
            alt="תמונת מוצר"
            class="product-img responsive"
          />
          <!-- Circle -->
          <ng-container *ngIf="product.shape && product.shape === productShape.Circle">
            <bi-draw-topping-circle [categories]="product.categories"></bi-draw-topping-circle>
          </ng-container>

          <!-- Rectangle -->
          <ng-container *ngIf="product.shape && product.shape === productShape.Rectangle">
            <bi-draw-topping-rectangle
              [categories]="product.categories"
            ></bi-draw-topping-rectangle>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="categories-container" *ngIf="product.categories">
      <bi-categories [categories]="product.categories"></bi-categories>
    </div>
    <div class="price-container">
      <div>מחיר סופי למוצר:</div>
      <div class="price shekel">{{total$ | ngrxPush}}</div>
    </div>
    <div class="continue-container">
      <div class="btn-group" *ngIf="siteData$ | ngrxPush as siteData">
        <button
          *ngIf="this.currentIndex !==0 && siteData.theme === 'light'"
          class="bi-btn secondary back"
          (click)="categoryBack(); scroll(true)"
        >
          חזור
        </button>
        <button class="bi-btn full" (click)="onContinueClick()">המשך</button>
      </div>
    </div>
  </div>
</div>
