<ng-container *ngIf="siteData$ | ngrxPush as siteData">
  <ng-container *ngIf='siteData.theme === "light"'>
    <div
      click-stop-propagation
      class="remove"
      *ngIf="allowMultipleSelection"
      (click)="remove($event)"
    >
      <span>&#8722;</span>
    </div>

    <div class="count" *ngIf="allowMultipleSelection">{{count}}</div>

    <div
      class="add"
      [class.hasCount]="count"
      *ngIf="!showAddIcon && allowMultipleSelection"
      (click)="add($event)"
      click-stop-propagation
    >
      +
    </div>
    <div
      class="add"
      [class.hasCount]="count"
      *ngIf="!!showAddIcon && !!allowMultipleSelection"
      (click)="add($event)"
      click-stop-propagation
    >
      +
    </div>

    <button class="add-count" *ngIf="!showAddIcon && !allowMultipleSelection" (click)="add($event)">
      הוסף
    </button>

    <button
      class="added-icon"
      *ngIf="showAddIcon && !allowMultipleSelection"
      (click)="remove($event)"
    >
      <img [src]="'assets/' + businessName + '/images/item_done.svg'" />
    </button>
  </ng-container>
</ng-container>
