import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import {
  IProductCategory,
  ProductShape,
} from '../../../../../../site/modules/product/product.model'
import {IBusinessResponse, ToppingMethod} from '../../../../../services/business/business.model'
import {selectBusinessDetails, selectSiteInitials} from '../../../../loader/reducers'
import {Observable} from "rxjs";
import {environment} from "../../../../../../../environments/environment";
import {ISiteInitials} from "../../../../../initial-data/initial-data.model";

@Component({
  selector: 'bi-cart-category',
  templateUrl: './cart-category.component.html',
  styleUrls: ['./cart-category.component.less'],
})
export class CartCategoryComponent implements OnInit {
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  staticFilesPath = ''

  @Input()
  category!: IProductCategory

  @Input()
  shape: ProductShape | undefined = ProductShape.Circle

  @Input()
  canDeleteItem = true

  @Output()
  removeTopping: EventEmitter<{ id: string; toppingIndex: number }> = new EventEmitter()

  isShowPrice$ = this.store.pipe(
    select(selectBusinessDetails),
    map((business) => {
      return (
        business?.business.topping_method != ToppingMethod.ByLayer ||
        !this.category.is_topping_divided
      )
    }),
  )

  constructor(private store: Store) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {}

  onRemoveTopping(id: string, index: number) {
    this.removeTopping.emit({ id, toppingIndex: index })
  }
}
