<ng-container *ngIf="business$|ngrxPush as business">
  <div class="page-container">
    <bi-card cardTitle="אודות">
      <div class="about-container" *ngIf="siteData$ | ngrxPush as siteData">
        <div class="about-content">
          <h2 *ngIf="siteData.theme === 'light'">
            <span> {{business.business.business_information.business_name_commercial}} </span>
          </h2>
          <p class="address" *ngIf="siteData.theme === 'light'">
            <span>{{business.business.business_information.business_phone}} </span> |
            <span>{{business.business.business_information.business_address}}</span>
          </p>
          <div class="about-text">{{business.business.business_information.business_about}}</div>
          <img
            style="width: 100px"
            [src]="staticFilesPath + 'bringit_logo.svg'"
            alt="לוגו בינוני"
          />
        </div>
        <div class="content-img">
          <img class="responsive" [src]="staticFilesPath + 'about-us.png'" />
        </div>
      </div>
    </bi-card>
  </div>
</ng-container>
