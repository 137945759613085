import { Action, createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromCart from './cart.reducers'

export function reducer(state: fromCart.State, action: Action) {
  return fromCart.reducer(state, action)
}

export const selectCartState = createFeatureSelector<fromCart.State>(fromCart.cartFeatureKey)

export const selectCartTotal = createSelector(selectCartState, fromCart.getTotal)
export const selectCartProducts = createSelector(selectCartState, fromCart.getCart)

export { fromCart }
