<ng-container *ngIf="product">
  <div class="img-section" [ngClass]="{'in-deal': deal$ | ngrxPush}">
    <div class="deal-items-map-container">
      <bi-deal-items-map></bi-deal-items-map>
    </div>
    <div class="img-container">
      <div class="img-wrapper">
        <img
          *ngIf="imageUrl"
          [src]="imageUrl"
          alt="תמונת מוצר"
          class="product-img responsive"
          [class.pizza]="product.shape === productShape.Circle"
        />
        <!-- Circle -->
        <ng-container *ngIf="product.shape && product.shape === productShape.Circle">
          <bi-draw-topping-circle
            class="circle"
            [categories]="product.categories"
          ></bi-draw-topping-circle>
        </ng-container>

        <!-- Rectangle -->
        <ng-container *ngIf="product.shape && product.shape === productShape.Rectangle">
          <bi-draw-topping-rectangle
            class="rectangle"
            [categories]="product.categories"
          ></bi-draw-topping-rectangle>
        </ng-container>
      </div>
    </div>
    <div class="product-info">
      <h2 class="title text-center">{{product.name}}</h2>
      <!--      <bi-category-title [name]="product.name" style="margin-left: 0 !important;"></bi-category-title>-->
      <p class="description-text text-center">{{product.description}}</p>
    </div>

    <div *ngIf="siteData$ | ngrxPush as siteData">
      <div class="btn-group on-add-product">
        <button
          class="bi-btn secondary back"
          *ngIf="siteData.theme === 'light'"
          (click)="onBackClick()"
        >
          חזור
        </button>
        <button class="bi-btn full" (click)="onContinueClick()">המשך</button>
      </div>
    </div>
  </div>

  <div class="categories-container" *ngIf="product.categories">
    <bi-categories [categories]="product.categories"></bi-categories>
  </div>
  <div *ngIf="siteData$ | ngrxPush as siteData">
    <!--    <div *ngIf='isMobile' class="btn-group on-add-product">-->
    <!--      <button-->
    <!--        class="bi-btn secondary back"-->
    <!--        *ngIf="siteData.theme === 'light'"-->
    <!--        (click)="onBackClick()"-->
    <!--      >-->
    <!--        חזור-->
    <!--      </button>-->
    <!--      <button class="bi-btn full" (click)="onContinueClick()">המשך</button>-->
    <!--    </div>-->
  </div>
</ng-container>
