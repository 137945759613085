<div class="container" (click)="goTo(item)">
  <div class="details-container">
    <h3>{{item.name}}</h3>
    <p *ngIf="item.description">{{item.description}}</p>
  </div>

  <!-- The image is temporary, need to fix -->
  <div
    class="image-container"
    [ngStyle]="{'background': 'linear-gradient(270deg, rgba(255, 255, 255, 0.95) 0.83%, rgba(255, 255, 255, 0.00) 24.95%), url(' + (item.picture || '/assets/placeholders/pizza.jpeg') + ') lightgray 50% / cover no-repeat'}"
  ></div>
</div>
