import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { timer } from 'rxjs'
import { environment } from '../../../../../environments/environment'
import { selectSiteInitials } from '../../../../common/components/loader/reducers'
import { ThankYouComponent } from '../../../../common/components/thank-you/thank-you.component'
import { SiteRoutes } from '../../../../routes.const'
import { OrderService } from '../../../modules/order/order.service'
import { IContactForm, IContactFormOut } from '../contact.model'
import { ContactService } from '../contact.service'
import { BusinessMenuService } from './../../../../common/services/business/business-menu.service'

@Component({
  selector: 'bi-contact-bringit',
  templateUrl: './contact-bringit.component.html',
  styleUrls: ['./contact-bringit.component.less'],
})
export class ContactBringitComponent implements OnInit {
  title = ''
  isSending = false
  successText = ''
  errorText = ''
  commonPath = ''
  staticFilesPath = ''
  deliveryOption = this.orderService.getDeliveryOption()

  constructor(
    private contactService: ContactService,
    private dialog: MatDialog,
    public router: Router,
    protected store: Store,
    private orderService: OrderService,
    private businessMenuService: BusinessMenuService,
  ) {
    this.title = `צור קשר עם Bringit Technologies`
    this.commonPath = `${environment.staticFiles + 'common'}/`
    this.store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {}

  onContact(form: IContactForm) {
    const data: IContactFormOut = {
      data: form,
      to: 'bringit',
    }

    this.isSending = true
    this.contactService.sendContactUsForm(data).subscribe(
      () => {
        this.isSending = false
        this.successText = ''
        this.errorText = ''

        const dialogRef = this.dialog.open(ThankYouComponent, {
          disableClose: true,
          panelClass: 'bg-transparent',
        })
        timer(3000).subscribe(() => {
          dialogRef.close()
          this.router.navigate([SiteRoutes.Home])
        })
      },
      () => {
        this.isSending = false
        this.successText = ''
        this.errorText = 'אירעה שגיאה, נא לנסות לשלוח פנייה שוב'
      },
    )
  }

  goToFirstPage(): void {
    this.businessMenuService.goToFirstPage()
  }

  protected readonly SiteRoutes = SiteRoutes
}
