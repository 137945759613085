<div class="category-container" *ngIf="category && siteData$ | ngrxPush as siteData">
  <div class="category-name">{{category.name}}:</div>
  <div class="category-inner" style="display: flex; position: relative">
    <div class="category-toppings">
      <div class="topping" *ngFor="let topping of category.products; let index = index">
        <div class="topping-name">
          <div class="topping-location {{shape}}" *ngIf="topping.location">
            <bi-divided-topping
              [type]="topping.location === 'full' ? 'full' : (topping.location.length === 2 ? 'quarter' : 'half')"
              [selectedLocation]="topping.location"
              [shape]="shape"
            ></bi-divided-topping>
          </div>
          <div class="name">
            {{topping.name}}
            <span *ngIf="topping.count && topping.count > 1">x {{topping.count}}</span>
          </div>
        </div>
        <div class="price shekel" *ngIf="isShowPrice$ | ngrxPush">{{topping.price}}</div>

<!--        <div class="remove" (click)="onRemoveTopping(topping.id, index)" *ngIf="canDeleteItem">-->
<!--          <span *ngIf="siteData.theme === 'light'">-->
<!--          <img [src]="staticFilesPath + 'delete.svg'" class="responsive" alt="לוגו" />-->
<!--      </span>-->
<!--        </div>-->
      </div>
    </div>

    <div class="toppings-layers" *ngIf="category.layers?.length">
      <div class="layer" *ngFor="let layer of category.layers; let index = index">
        <span class="layer-name">שכבה {{index + 1}}</span>
        <span class="price shekel">{{layer.price}}</span>
      </div>
    </div>
  </div>
</div>
