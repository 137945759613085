import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { OutOfStockComponent } from './out-of-stock.component'
import { OutOfStockDirective } from './out-of-stock.directive'

const declarations = [OutOfStockDirective, OutOfStockComponent]
@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule],
})
export class OutOfStockModule {}
