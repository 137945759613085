import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import {
  IProductDeal,
  IProductItem,
  productNameTranslate,
  ProductType,
} from '../../../../site/modules/product/product.model'
import { ProductsService } from '../../../../site/modules/product/products.service'
import { Subscriber } from '../../../classes/subscriber/subscriber'
import { ISiteInitials } from '../../../initial-data/initial-data.model'
import { InitSiteService } from '../../../services/init-site/init-site.service'
import { MobileDetectorService } from '../../../services/mobile-detector/mobile-detector.service'
import { selectSiteInitials } from '../../loader/reducers'
import { ProductsActions } from '../actions'
import { selectDealItemToAdd, selectDealPreAdd, selectDealToAdd } from '../reducers'

@Component({
  selector: 'bi-deal-items-map',
  templateUrl: './deal-items-map.component.html',
  styleUrls: ['./deal-items-map.component.less'],
})
export class DealItemsMapComponent extends Subscriber implements OnInit {
  staticFilesPath = ''
  businessName = ''
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  item!: number
  deal!: IProductDeal
  dealPreAdd!: IProductDeal
  deal$ = combineLatest([
    this.store.pipe(select(selectDealItemToAdd)),
    this.store.pipe(select(selectDealToAdd)),
    this.store.pipe(select(selectDealPreAdd)),
  ]).pipe(
    filter(([item, deal]) => !!deal && item != null),
    map(([index, deal, dealPreAdd]) => {
      if (index !== null && deal && dealPreAdd) {
        deal = this.productsService.mutateDeal(deal)
        deal.items.forEach((item, i) => {
          item.selected = i === index
          const container = document.getElementById('deal-items-container') as HTMLElement | null
          if (
            dealPreAdd.items[i]?.id === item.id &&
            dealPreAdd.items[i].type_id === item.type_id &&
            dealPreAdd.items[i].products.length
          ) {
            item.doneAdding =
              !!dealPreAdd.items[i + 1] &&
              !this.itemHasUnselectedMandatoryCategory(dealPreAdd.items[i])
            if (container && index > 1) {
              setTimeout(() => {
                container.scrollLeft = -90
              }, 500)
            }
            if (container && index > 2) {
              setTimeout(() => {
                container.scrollLeft = -190
              }, 500)
            }
            if (container && index <= 1) {
              setTimeout(() => {
                container.scrollLeft = 170
              }, 500)
            }
          } else {
            item.doneAdding = false
          }
        })
        this.item = index
        this.deal = deal
        this.dealPreAdd = dealPreAdd
      }
      return deal
    }),
    takeUntil(this.ngUnsubscribe),
  )

  constructor(
    private store: Store,
    private productsService: ProductsService,
    private initSiteService: InitSiteService,
    protected mobileDetectorService: MobileDetectorService,
  ) {
    super()
    const { name } = initSiteService.selectSiteInitials()
    this.businessName = name
    this.staticFilesPath = `${environment.staticFiles + name}/`
  }

  ngOnInit(): void {}

  getItemIcon(item: IProductItem): string {
    const icon =
      item.type_name === ProductType.pizza
        ? `${item.type_name}-${item.products[0].shape}`
        : item.type_name

    return `${icon}-icon.svg`
  }

  onItemClick(index: number) {
    if (
      !!this.dealPreAdd.items[this.item + 1] &&
      this.itemHasUnselectedMandatoryCategory(this.dealPreAdd.items[this.item])
    ) {
      return
    }
    this.store.dispatch(ProductsActions.onSelectDealItem({ index }))
  }

  getItemTypeNameTranslate(type_name: ProductType) {
    return productNameTranslate[type_name]
  }

  getCenteredStyle(l: number) {
    if (this.mobileDetectorService.isTablet()) {
      return 'left: ' + (l > 4 ? 10 : 25 - l * 5) + '% !important'
    }
    return ''
  }

  private itemHasUnselectedMandatoryCategory(item: IProductItem): boolean {
    return item.products.some((product) => {
      return product.categories.some((category) => {
        return category.is_mandatory && !category.products.length
      })
    })
  }
}
