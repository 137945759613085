import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, retry } from 'rxjs/operators'
import { SiteRoutes } from '../../../routes.const'
import { environment } from './../../../../environments/environment'
import { OrderService } from './../../../site/modules/order/order.service'
import { INavItems } from './../../components/header/nav-menu.model'
import { IBusiness, IBusinessResponse, ToppingMethod } from './business.model'

@Injectable({
  providedIn: 'root',
})
export class BusinessApiService {
  static toppingMethod: ToppingMethod
  private businessId!: string

  constructor(private http: HttpClient, private orderService: OrderService) {}

  fetchBusiness(): Observable<IBusinessResponse> {
    const data = {
      id: document.location.host.replace('www.', '').replace(':4200', ''),
    }
    const params = new HttpParams().set('apiCtrl', 'pizziria').set('do', 'getBusinessDetails')

    return this.http
      .post<IBusinessResponse>(environment.api1, data, {
        params,
      })
      .pipe(
        retry(2),
        map((details) => {
          if (!!details.business.menuDesktop) {
            details.business.menuDesktop.forEach((i) =>
              this.transformMenuItemsIn(i, details.business),
            )
          }
          if (!!details.business.menuMobile) {
            details.business.menuMobile.forEach((i) =>
              this.transformMenuItemsIn(i, details.business),
            )
          }
          return details
        }),
        map((details) => {
          if (!!details.business.menuDesktop) {
            details.business.menuDesktop.push(
              {
                name: 'מעקב',
                icon: details.business.business_folder + '/svg/m-track-icon',
                url: SiteRoutes.Tracker,
              },
              {
                name: 'עגלה',
                icon: details.business.business_folder + '/svg/shopping-basket-icon',
                url: SiteRoutes.Cart,
              },
            )
          }
          if (!!details.business.menuMobile) {
            details.business.menuMobile.push({
              name: 'מעקב',
              icon: details.business.business_folder + '/svg/m-track-icon.svg',
              url: SiteRoutes.Tracker,
            })
          }
          BusinessApiService.toppingMethod = details.business.topping_method
          return details
        }),
      )
  }

  storeId(id: string) {
    this.businessId = id
    this.orderService.setBusinessId(id)
  }

  private transformMenuItemsIn(item: INavItems, business: IBusiness) {
    if (item.children?.length) {
      item.url = `general/group/${item.type_name}/${item.id}`
      item.children.forEach((i) => this.transformMenuItemsIn(i, business))
    } else {
      item.url = `general/${item.type_name}/${item.product_type_id}`
    }
    item.icon = `icons/${item.icon}.svg`
  }
}
