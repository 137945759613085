import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  ToppingLocation,
  ToppingType,
} from '../../../../../../../../../site/modules/product/product.model'
import { ProductShape } from './../../../../../../../../../site/modules/product/product.model'

@Component({
  selector: 'bi-divided-topping',
  templateUrl: './divided-topping.component.html',
  styleUrls: ['./divided-topping.component.less'],
})
export class DividedToppingComponent implements OnInit {
  @Input()
  type!: ToppingType

  @Input()
  shape: ProductShape | undefined = ProductShape.Circle

  @Input()
  selectedLocation?: ToppingLocation

  @Output()
  onAddTopping: EventEmitter<ToppingLocation> = new EventEmitter()

  locations: ToppingLocation[] = ['full']

  constructor() {}

  ngOnInit() {
    this.initQuarters()
  }

  initQuarters() {
    switch (this.type) {
      case 'half':
        this.locations = ['rightHalfPizza', 'leftHalfPizza']
        break
      case 'quarter':
        this.locations = ['tr', 'tl', 'br', 'bl']
        break
    }

    if (this.shape === ProductShape.Rectangle && this.type === 'quarter') {
      this.locations = ['tr', 'br', 'bl', 'tl']
    }
  }

  addTopping(location: ToppingLocation) {
    this.onAddTopping.emit(location)
  }
}
