import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { SiteRoutes } from '../../../../../routes.const'
import {
  IProduct,
  IProductDeal,
  IProductOther,
} from '../../../../../site/modules/product/product.model'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { MobileDetectorService } from '../../../../services/mobile-detector/mobile-detector.service'
import { CartActions } from '../../../cart/actions'
import { selectSiteInitials } from '../../../loader/reducers'
import { ProductsActions } from '../../actions'
import { selectProductToAdd } from '../../reducers'
import { AddProductModalComponent } from '../add-product-modal/add-product-modal.component'
import { PopupDescriptionComponent } from '../popup-description/popup-description.component'
import { Subscriber } from './../../../../classes/subscriber/subscriber'
import { BusinessMenuService } from './../../../../services/business/business-menu.service'
import { selectIsNewMenu } from './../../../loader/reducers/index'

@Component({
  selector: 'bi-additional-offer',
  templateUrl: './additional-offer.component.html',
  styleUrls: ['./additional-offer.component.less', '../common/styles/common-product.less'],
})
export class AdditionalOfferComponent extends Subscriber implements OnInit {
  @Input()
  product!: IProduct | null

  @Input()
  inDeal = false

  @Output()
  onAddClick: EventEmitter<undefined> = new EventEmitter()

  isMobile: boolean
  isRemoveClick = false
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  siteData?: ISiteInitials | null
  panelClass = ''
  productToAdd$: Observable<IProduct | null> = this.store.pipe(select(selectProductToAdd))
  isNewMenu = false

  constructor(
    private dialogRef: MatDialog,
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private businessMenuService: BusinessMenuService,
  ) {
    super()
    this.isMobile = mobileDetectorService.isMobile()
    this.siteData$.subscribe((data) => (this.siteData = data)).unsubscribe()
    this.store
      .pipe(select(selectIsNewMenu), takeUntil(this.ngUnsubscribe))
      .subscribe((isNewMenu) => {
        this.isNewMenu = isNewMenu
      })
  }

  ngOnInit(): void {}

  onAdd(showDescription = true) {
    if (!this.product?.in_inventory) {
      return
    }
    if (this.siteData?.theme === 'light' && this.inDeal) {
      // animationOnAddProduct
      let productCard = document.querySelector('.deal-image-placeholder') as HTMLElement
      if (productCard) {
        productCard.classList.add('product-added')
        setTimeout(() => {
          productCard.classList.remove('product-added')
        }, 1000)
      }
      // animationOnAddProduct end
      this.store.dispatch(
        ProductsActions.onSelectDealPreAddProduct({
          dealPreAddProduct: cloneDeep(this.product) as IProduct | null,
        }),
      )
      return
    }
    if (!this.inDeal) {
      if (this.product?.description && showDescription) {
        this.showDescription()
        return
      }
      if (this.product?.categories?.length && this.siteData?.theme === 'light') {
        this.showAddDealModal()
        return
      }
    }

    if (this.isRemoveClick) {
      this.isRemoveClick = false
      return
    }

    if (this.product?.original_id == null && !this.isNewMenu) {
      this.router.navigate([`${SiteRoutes.AdditionalOffer}/${this.product?.id}`]).then(() => {
        this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
      })
    } else {
      this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
    }
  }

  showDescription() {
    this.dialogRef
      .open(PopupDescriptionComponent, {
        data: {
          product: this.product,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.showAddDealModal(false)
        }
      })
  }

  showAddDealModal(dispatch = true) {
    this.panelClass = this.product?.type_name + '_modal'
    if (this.product?.shape) {
      this.panelClass = this.product?.type_name + this.product?.shape + '_modal'
    }
    if (dispatch) {
      this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
    }
    this.dialogRef.open(AddProductModalComponent, {
      data: {
        product: this.product,
      },
      panelClass: this.panelClass,
    })
  }

  onRemove() {
    if (!this.product?.in_inventory) {
      return
    }
    this.isRemoveClick = true
    if (this.product) {
      this.store.dispatch(CartActions.onRemoveProduct({ product: cloneDeep(this.product) }))
    }
  }

  getProductImage(product: IProductDeal | IProductOther) {
    if (!product.imageUrl || product.picture == 'new_deal.png') {
      return this.getDefaultImgPlaceholder(product)
    }
    return product.imageUrl
  }

  getDefaultImgPlaceholder(product: IProductDeal | IProductOther) {
    return this.businessMenuService.getDefaultImgPlaceholder(product)
  }
}
