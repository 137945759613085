import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { IProductCategory } from '../../../../../../../site/modules/product/product.model'
import { ISiteInitials } from '../../../../../../initial-data/initial-data.model'
import { MobileDetectorService } from '../../../../../../services/mobile-detector/mobile-detector.service'
import { selectSiteInitials } from '../../../../../loader/reducers'
import { ProductsActions } from '../../../../actions'
import { CategoriesService } from '../../categories.service'
import { IToppingProduct } from './../../../../../../../site/modules/product/product.model'

@Component({
  selector: 'bi-not-divided-category',
  templateUrl: './not-divided-category.component.html',
  styleUrls: ['./not-divided-category.component.less'],
})
export class NotDividedCategoryComponent implements OnChanges {
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  @Input()
  category!: IProductCategory

  isMobile: boolean
  showFixedPrice = false

  constructor(
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private categoriesService: CategoriesService,
  ) {
    this.isMobile = this.mobileDetectorService.isMobile()
  }

  ngOnChanges({ category }: SimpleChanges): void {
    if (category?.currentValue) {
      this.validateFixedPrice()
    }
  }

  onAdd(index: number) {
    this.store.dispatch(ProductsActions.onAddTopping({ index }))
  }

  onRemove(topping: IToppingProduct) {
    this.store.dispatch(ProductsActions.onRemovePreAddTopping({ id: topping.id }))
  }

  categoryBack() {
    this.store.dispatch(ProductsActions.onCategoryBack())
  }

  private validateFixedPrice() {
    this.showFixedPrice = this.categoriesService.isShowFixedPrice(this.category)
  }
}
