import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'bi-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.less'],
})
export class CardComponent implements OnInit {
  @Input()
  cardTitle?: string

  constructor() {}

  ngOnInit(): void {}
}
