<div class="page-container" *ngIf="business">
  <bi-card [cardTitle]="title">
    <div class="text-center form-title">
      <h1>
        {{business.business_information.business_address}} |
        <span class="business-phone">{{business.business_information.business_phone}}</span>
      </h1>
      <img [src]="staticFilesPath + 'medium_logo.png'" class="responsive" alt="לוגו" width="57px" />
    </div>
    <bi-contact-form
      [isSending]="isSending"
      [successText]="successText"
      [errorText]="errorText"
      (onContact)="onContact($event)"
    ></bi-contact-form>
    <div
      class="button popup-close"
      (click)="!this.deliveryOption ? this.router.navigate([SiteRoutes.Home]) : this.goToFirstPage() "
    >
      x
    </div>
  </bi-card>
</div>
