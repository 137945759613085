import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
} from '@angular/core'
import { OutOfStockComponent } from './out-of-stock.component'

@Directive({
  selector: '[biOutOfStock]',
})
export class OutOfStockDirective {
  @Input('biOutOfStock') inInventory!: boolean

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    if (this.inInventory) {
      return
    }
    const component = this.viewContainerRef.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(OutOfStockComponent),
    )
  }
}
