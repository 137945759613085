import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { IContactForm } from '../contact.model'

@Component({
  selector: 'bi-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less'],
})
export class ContactFormComponent implements OnInit {
  @Input()
  isSending = false

  @Input()
  successText = ''

  @Input()
  errorText = ''

  @Output()
  onContact: EventEmitter<IContactForm> = new EventEmitter()

  contactForm: UntypedFormGroup

  constructor() {
    this.contactForm = new UntypedFormGroup({
      fName: new UntypedFormControl('', Validators.required),
      lName: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl('', [Validators.required,Validators.pattern("^[0-9]{10}$")]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      message: new UntypedFormControl('', Validators.required),
    })
  }

  ngOnInit(): void {}

  sendForm() {
    this.onContact.emit(this.contactForm.value)
  }
}
