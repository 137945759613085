import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { map, mergeMap, tap } from 'rxjs/operators'
import { ProductsActions } from '../../products/actions'
import { SiteLoaderActions } from '../actions'
import { IBusinessResponse } from './../../../../common/services/business/business.model'
import { BusinessApiService } from './../../../services/business/business.api.service'
import { InitSiteService } from './../../../services/init-site/init-site.service'
import { StylesLoaderService } from './../../../services/styles-loader/styles-loader.service'

@Injectable()
export class SiteLoaderEffects {
  onSiteInitLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteLoaderActions.onSiteInitialLoading),
      tap(() => this.store.dispatch(SiteLoaderActions.loading({ loading: true }))),
      map(() => {
        const siteInitials = this.initSiteService.getSiteInitials()
        if (siteInitials) {
          return SiteLoaderActions.onSiteInitialLoaded({ siteInitials })
        }
        alert('שגיאת מערכת, נסה שנית')
        console.error('business initials was not found')
        return ProductsActions.noAction()
      }),
    ),
  )

  onSiteInitialLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteLoaderActions.onSiteInitialLoaded),
      map(() => {
        this.stylesLoaderService.load().then(() => {
          this.store.dispatch(SiteLoaderActions.onBusinessDetailsLoading())
        })
        return SiteLoaderActions.loading({ loading: true })
      }),
    ),
  )

  onBusinessDetailsLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteLoaderActions.onBusinessDetailsLoading),
      mergeMap(() => {
        return this.businessApiService.fetchBusiness().pipe(
          map((businessDetails: IBusinessResponse) => {
            this.businessApiService.storeId(businessDetails.business.business_id)
            return SiteLoaderActions.onSuccessBusinessDetailsLoaded({ businessDetails })
          }),
        )
      }),
      mergeMap((action) => {
        return [action, SiteLoaderActions.loading({ loading: false })]
      }),
    ),
  )

  constructor(
    private actions$: Actions,
    private store: Store,
    private initSiteService: InitSiteService,
    private stylesLoaderService: StylesLoaderService,
    private businessApiService: BusinessApiService,
  ) {}
}
