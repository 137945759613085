<ng-container *ngIf="product && (siteData$ | ngrxPush) as siteData">
  <div
    class="item-anchor-container"
    [class.inDeal]="inDeal"
    *ngIf="product"
    (click)="inDeal && onAdd()"
  >
    <div class="item-main-container">
      <!-- PRODUCT IMAGE -->
      <div class="item-image-container" (click)="onAdd()">
        <img [src]="getProductImage(product)" alt="שתיה" />
      </div>

      <!-- PRODUCT NAME -->
      <div class="item-details-container">
        <div class="item-name">{{ product.name }}</div>
      </div>

      <!-- PRODUCT ACTION BUTTONS -->
      <div class="item-actions" *ngIf="!inDeal">
        <bi-add-remove-btns
          [count]="product.count || 0"
          (onRemove)="onRemove()"
          (onAdd)="onAdd()"
        ></bi-add-remove-btns>
      </div>
    </div>

    <!-- PRODUCT PRICE -->
    <div class="item-price-container">
      <span class="price" *ngIf="!inDeal"
        >{{ inDeal ? 'כלול במחיר' : product.price | currency: 'ILS' : 'symbol' : '1.0-2' }}</span
      >
    </div>

    <!-- PRODUCT ADD TO CART BUTTON -->
    <div
      class="item-add-button-container"
      *ngIf="siteData.theme === 'light' && product.in_inventory"
    >
      <button class="bi-btn" (click)="onAdd()">הוסף</button>
    </div>
  </div>
</ng-container>
