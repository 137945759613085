import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { environment } from '../../../../../environments/environment'
import { selectSiteInitials } from '../../loader/reducers'

@Component({
  selector: 'bi-sticker-closed',
  templateUrl: './sticker-closed.component.html',
  styleUrls: ['./sticker-closed.component.less'],
})
export class StickerClosedComponent implements OnInit {
  staticFilesPath = ''

  constructor(private store: Store) {}

  ngOnInit(): void {}
}
