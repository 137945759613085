import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { selectProductPreAdd } from '../components/products/reducers'
import { selectDealPreAdd } from './../components/products/reducers/index'

@Injectable({
  providedIn: 'root',
})
export class CanLeavePageGuard implements CanDeactivate<unknown> {
  constructor(private store: Store) {}
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    state?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let shouldContinue = true
    let found = false

    combineLatest([
      this.store.pipe(select(selectProductPreAdd)),
      this.store.pipe(select(selectDealPreAdd)),
    ]).subscribe(([product, deal]) => {
      if (product || deal) {
        found = true
      }
    })

    // if (found) {
    //     window.location.reload()
    // }
    return shouldContinue
  }
}
