import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { isEqual } from 'lodash'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { SiteRoutes } from '../../../../routes.const'
import { IProduct } from '../../../../site/modules/product/product.model'
import { ProductsService } from '../../../../site/modules/product/products.service'
import { BusinessMenuService } from '../../../services/business/business-menu.service'
import { CartActions } from '../../cart/actions'
import { ProductsActions } from '../actions'
import { OrderService } from './../../../../site/modules/order/order.service'
import { IProductOther } from './../../../../site/modules/product/product.model'
import {
  selectCategoryToAdd,
  selectDealItemToAdd,
  selectDealPreAdd,
  selectDealPreAddProduct,
  selectDealStep,
  selectDealToAdd,
  selectProductPreAdd,
  selectProductToAdd,
} from './../reducers/index'

@Injectable()
export class ProductsEffects {
  onAddProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onAddProduct),
      withLatestFrom(
        this.store.pipe(select(selectDealToAdd)),
        this.store.pipe(select(selectDealPreAdd)),
        this.store.pipe(select(selectDealItemToAdd)),
        this.store.pipe(select(selectDealStep)),
      ),
      map(([{ product }, deal, dealPreAdd, itemIndex, dealStep]) => {
        if (!product) {
          return ProductsActions.onPreAddProduct({ productPreAdd: null })
        }

        let productPreAdd: IProduct
        const cart: IProduct[] = this.orderService.getCart()
        const count: number = cart.reduce((count: number, item: IProduct) => {
          return count + (isEqual(item, product) ? 1 : 0)
        }, 1)

        product = this.productsService.mutateProduct(product as IProductOther)
        product.count = count

        if (deal && product) {
          product.price = 0
        }

        if (!product.categories.length) {
          this.store.dispatch(ProductsActions.onPreAddProduct({ productPreAdd: product }))
          if (deal) {
            this.store.dispatch(ProductsActions.onDealStep({ dealStep: dealStep + 1 }))
            return ProductsActions.onAddProductToDeal()
          }
          return CartActions.onDoneAddingProduct()
        }

        productPreAdd = this.productsService.mutateProduct(product)
        productPreAdd.categories = []
        if (itemIndex !== null && dealPreAdd?.items[itemIndex]?.products[0]) {
          const p = dealPreAdd?.items[itemIndex]?.products[0]
          if (p && p.id !== productPreAdd.id) {
            dealPreAdd = this.productsService.mutateDeal(dealPreAdd)
            dealPreAdd.items[itemIndex].products[0] = productPreAdd
            this.store.dispatch(ProductsActions.onPreAddDeal({ dealPreAdd }))
          }
        }
        const productInDeal = itemIndex !== null && dealPreAdd?.items[itemIndex]?.products[0]
        if (productInDeal) {
          productPreAdd.categories = productInDeal.categories
        }

        return ProductsActions.onPreAddProduct({ productPreAdd })
      }),
    ),
  )

  onPreAddProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onPreAddProduct),
      filter(({ productPreAdd }) => productPreAdd === null),
      map(() => {
        return ProductsActions.onSelectCategoy({ index: null })
      }),
    ),
  )

  onSelectCategoy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onSelectCategoy),
      filter(({ index }) => index === null),
      map(() => {
        return ProductsActions.onAddTopping({ index: null })
      }),
    ),
  )

  onCategoryBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onCategoryBack),
      withLatestFrom(
        this.store.pipe(select(selectCategoryToAdd)),
        this.store.pipe(select(selectDealItemToAdd)),
        this.store.pipe(select(selectProductToAdd)),
        this.store.pipe(select(selectDealPreAdd)),
      ),
      map(([, index, itemIndex, productToAdd, dealPreAdd]) => {
        if (index === null && !productToAdd && !itemIndex) {
          window.history.back()
          return ProductsActions.noAction()
        }

        if (index !== null && index > 0) {
          return ProductsActions.onSelectCategoy({ index: index - 1 })
        }

        if (itemIndex !== null) {
          const i = !productToAdd && itemIndex ? itemIndex - 1 : itemIndex
          const categories = dealPreAdd?.items[i]?.products[0]?.categories
          if (productToAdd || (categories && !categories.length)) {
            this.store.dispatch(ProductsActions.onAddProduct({ product: null }))
          }

          return ProductsActions.onSelectDealItem({ index: i })
        }

        return ProductsActions.noAction()
      }),
    ),
  )

  onUpdateAddProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onUpdateAddProduct),
      withLatestFrom(
        this.store.pipe(select(selectProductToAdd)),
        this.store.pipe(select(selectDealItemToAdd)),
        this.store.pipe(select(selectDealPreAdd)),
      ),
      map(([{ product }, productToAdd, itemIndex, dealPreAdd]) => {
        if (product && productToAdd) {
          productToAdd = this.productsService.mutateProduct(productToAdd as IProductOther)
          product = this.productsService.mutateProduct(product as IProductOther)
          this.productsService.compareToppingsPreAdd(product, productToAdd)

          if (dealPreAdd && itemIndex !== null) {
            dealPreAdd = this.productsService.mutateDeal(dealPreAdd)
            dealPreAdd.items[itemIndex].products[0] = product
            this.store.dispatch(ProductsActions.onPreAddDeal({ dealPreAdd }))
          }

          return ProductsActions.onAddProductUpdated({ product: productToAdd })
        }
        return ProductsActions.noAction()
      }),
    ),
  )

  onPageContinue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onPageContinue),
      withLatestFrom(
        this.store.pipe(select(selectProductToAdd)),
        this.store.pipe(select(selectDealPreAddProduct)),
        this.store.pipe(select(selectCategoryToAdd)),
        this.store.pipe(select(selectDealToAdd)),
        this.store.pipe(select(selectDealStep)),
      ),
      withLatestFrom(this.store.pipe(select(selectProductPreAdd))),
      map(([[, product, productToAdd, category, deal, dealStep], productPreAdd]) => {
        const index = Math.floor(dealStep / 2)
        if ((product === null || category === null) && productToAdd === null && !deal) {
          if (document.location.href.includes(SiteRoutes.Drinks)) {
            this.router.navigate([SiteRoutes.AdditionalOffer])
          } else {
            this.router.navigate([SiteRoutes.Cart])
          }
          return ProductsActions.noAction()
        }

        if (!product && productToAdd && deal?.items[index]) {
          this.store.dispatch(
            ProductsActions.onSelectDealPreAddProduct({ dealPreAddProduct: productToAdd }),
          )
          this.store.dispatch(ProductsActions.onDealStep({ dealStep: dealStep + 1 }))
          return ProductsActions.onAddProduct({ product: productToAdd })
        } else if (this.isMandatoryCategoryEmpty(productPreAdd, product, category)) {
          return ProductsActions.noAction()
        } else if (category != null && product?.categories?.[category + 1]) {
          return ProductsActions.onSelectCategoy({ index: category + 1 })
        } else if (deal) {
          if (productToAdd) {
            this.store.dispatch(ProductsActions.onAddProduct({ product: productToAdd }))
          }
          return ProductsActions.onAddProductToDeal()
        }

        return CartActions.onDoneAddingProduct()
      }),
    ),
  )

  private isMandatoryCategoryEmpty(
    productPreAdd: IProduct | null,
    productToAdd: IProduct | null,
    category: number | null,
  ): boolean {
    if (category === null || productPreAdd === null || productToAdd === null) {
      return false
    }

    if (!productToAdd.categories?.[category].is_mandatory) {
      return false
    }

    const categoryId = productToAdd.categories[category].id
    const theCategory = productPreAdd.categories?.find(({ id }) => id === categoryId)
    return !theCategory?.products.length
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private productsService: ProductsService,
    private orderService: OrderService,
    private router: Router,
    private businessMenuService: BusinessMenuService,
  ) {}
}
