<div class="category-product" *ngFor="let product of category.products; let index = index">
  <div class="details">
    <div class="name">{{product.name}}</div>
    <div class="price shekel">{{ showFixedPrice ? category.fixed_price : product.price }}</div>
  </div>
  <div class="actions">
    <div class="topping-container quarters {{shape}}">
      <bi-divided-topping
        type="quarter"
        [shape]="shape"
        [selectedLocation]="product.location"
        (onAddTopping)="onToggleTopping($event, index)"
      ></bi-divided-topping>
    </div>
    <div class="topping-container halfs {{shape}}">
      <bi-divided-topping
        type="half"
        [selectedLocation]="product.location"
        [shape]="shape"
        (onAddTopping)="onToggleTopping($event, index)"
      ></bi-divided-topping>
    </div>
    <div class="topping-container full {{shape}}">
      <bi-divided-topping
        [selectedLocation]="product.location"
        [shape]="shape"
        (onAddTopping)="onToggleTopping($event, index)"
      ></bi-divided-topping>
    </div>
  </div>
</div>
