import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DividedToppingComponent } from './divided-topping/divided-topping.component'

const declarations = [DividedToppingComponent]
@NgModule({
  imports: [CommonModule],
  declarations: declarations,
  exports: declarations,
})
export class DividedToppingModule {}
