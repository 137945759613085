import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { MobileDetectorService } from './../../../../common/services/mobile-detector/mobile-detector.service'

@Component({
  selector: 'bi-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.less'],
})
export class ClientDetailsComponent implements OnInit {
  @Input()
  detailsForm!: UntypedFormGroup

  @Input()
  isPickup = false

  isMobile: boolean

  constructor(private mobileDetectorService: MobileDetectorService) {
    this.isMobile = this.mobileDetectorService.isMobile()
  }

  ngOnInit(): void {}
}
