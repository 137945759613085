import { createReducer, on } from '@ngrx/store'
import { SiteLoaderActions } from '../actions'
import { IBusinessResponse } from './../../../../common/services/business/business.model'
import { ISiteInitials } from './../../../initial-data/initial-data.model'
import { IBusiness } from './../../../services/business/business.model'

export const siteLoaderFeatureKey = 'siteLoaderKey'
export interface State {
  loading: boolean
  siteInitailData: ISiteInitials
  businessDetails: IBusinessResponse | null
  isNewMenu: boolean
}

const initState: State = {
  loading: false,
  siteInitailData: {} as ISiteInitials,
  businessDetails: null,
  isNewMenu: false,
}

export const reducer = createReducer(
  initState,
  on(SiteLoaderActions.loading, (state, { loading }) => {
    return {
      ...state,
      loading,
    }
  }),
  on(SiteLoaderActions.onSiteInitialLoaded, (state, { siteInitials }) => {
    return {
      ...state,
      siteInitailData: siteInitials,
    }
  }),
  on(SiteLoaderActions.onSuccessBusinessDetailsLoaded, (state, { businessDetails }) => {
    return {
      ...state,
      businessDetails,
      isNewMenu: isNewMenu(businessDetails.business),
    }
  }),
)

function isNewMenu(business: IBusiness): boolean {
  return !!business.menuDesktop || !!business.menuMobile
}

export const getLoading = (state: State) => state.loading
export const getSiteInitials = (state: State) => state.siteInitailData
export const getBusinessDetails = (state: State) => state.businessDetails
export const getIsNewMenu = (state: State) => state.isNewMenu
