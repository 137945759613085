import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { PushModule } from '@ngrx/component'
import { DesktopFooterComponent } from './desktop-footer/desktop-footer.component'
import { FooterComponent } from './footer/footer.component'
import { MobileFooterComponent } from './mobile-footer/mobile-footer.component'
import { HeaderModule } from '../header/header.module'

const declarations = [FooterComponent, DesktopFooterComponent, MobileFooterComponent]

@NgModule({
  imports: [BrowserModule, PushModule, RouterModule, HeaderModule],
  declarations: declarations,
  exports: declarations,
})
export class FooterModule {}
