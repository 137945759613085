import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import { SiteRoutes } from '../../../../../routes.const'
import { Subscriber } from '../../../../classes/subscriber/subscriber'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { CartActions } from '../../../cart/actions'
import { selectSiteInitials } from '../../../loader/reducers'
import { ProductsActions } from '../../actions'
import { AddProductModalComponent } from '../add-product-modal/add-product-modal.component'
import { PopupDescriptionComponent } from '../popup-description/popup-description.component'
import {
  IProduct,
  IProductDeal,
  IProductOther,
} from './../../../../../site/modules/product/product.model'
import { BusinessMenuService } from './../../../../services/business/business-menu.service'

@Component({
  selector: 'bi-drink',
  templateUrl: './drink.component.html',
  styleUrls: ['./drink.component.less', '../common/styles/common-product.less'],
})
export class DrinkComponent extends Subscriber implements OnInit {
  @Input()
  product!: IProduct | null

  @Input()
  inDeal = false

  panelClass = ''
  siteData?: any

  isRemoveClick = false
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  constructor(
    private store: Store,
    private router: Router,
    private dialogRef: MatDialog,
    private businessMenuService: BusinessMenuService,
  ) {
    super()
    this.siteData$.subscribe((data) => (this.siteData = data)).unsubscribe()
  }

  ngOnInit(): void {}

  onAdd(showDescription = true) {
    if (!this.product?.in_inventory) {
      return
    }
    if (this.siteData?.theme === 'light' && this.inDeal) {
      // animationOnAddProduct
      let productCard = document.querySelector('.deal-image-placeholder') as HTMLElement
      if (productCard) {
        productCard.classList.add('product-added')
        setTimeout(() => {
          productCard.classList.add('product-apear-size')
        }, 500)
        setTimeout(() => {
          productCard.classList.remove('product-added')
          productCard.classList.remove('product-apear-size')
        }, 1000)
      }
      // animationOnAddProduct end
      this.store.dispatch(
        ProductsActions.onSelectDealPreAddProduct({
          dealPreAddProduct: cloneDeep(this.product) as IProduct | null,
        }),
      )
      return
    }
    if (this.isRemoveClick && this.product?.count) {
      this.isRemoveClick = false
      return
    }

    if (!this.inDeal) {
      if (this.product!.description && showDescription) {
        this.showDescription()
        return
      }
      if (this.product?.categories?.length && this.siteData.theme === 'light') {
        this.showAddDealModal()
        return
      }
    }

    if (this.product?.categories?.length == null) {
      this.router.navigate([`${SiteRoutes.Drinks}/${this.product?.id}`]).then(() => {
        this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
      })
    } else {
      this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
    }
  }

  onRemove() {
    if (!this.product?.in_inventory) {
      return
    }
    this.isRemoveClick = true
    if (this.product) {
      this.store.dispatch(CartActions.onRemoveProduct({ product: cloneDeep(this.product) }))
    }
  }

  showDescription() {
    if (this.product?.description) {
      this.dialogRef.open(PopupDescriptionComponent, {
        data: {
          product: this.product,
        },
      })
    }
  }

  showAddDealModal() {
    this.panelClass = this.product?.type_name + '_modal'
    if (this.product?.shape) {
      this.panelClass = this.product?.type_name + this.product?.shape + '_modal'
    }
    this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
    this.dialogRef.open(AddProductModalComponent, {
      data: {
        product: this.product,
      },
      panelClass: this.panelClass,
    })
  }

  getProductImage(product: IProductDeal | IProductOther) {
    if (!product.imageUrl || product.picture == 'new_deal.png') {
      return this.getDefaultImgPlaceholder(product)
    }
    return product.imageUrl
  }

  getDefaultImgPlaceholder(product: IProductDeal | IProductOther) {
    return this.businessMenuService.getDefaultImgPlaceholder(product)
  }
}
