import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { environment } from '../../../../../../environments/environment'
import {
  IProduct,
  IProductDeal,
  IProductOther,
  ProductShape,
} from '../../../../../site/modules/product/product.model'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { selectSiteInitials } from '../../../loader/reducers'
import { ProductsActions } from '../../actions'
import { MandatoryCategoryComponent } from '../../common/pop-up/mandatory-category/mandatory-category/mandatory-category.component'
import {
  selectCategoryToAdd,
  selectIsCategoryContinueDisabled,
  selectProductPreAdd,
  selectProductToAdd,
} from '../../reducers'

@Component({
  selector: 'bi-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.less'],
})
export class AddProductModalComponent implements OnInit {
  currentIndex: any
  currentIndex$ = this.store.pipe(select(selectCategoryToAdd))

  product$ = this.store.pipe(select(selectProductToAdd))
  product: IProductDeal | IProductOther | null | undefined
  total$: Observable<number> = this.store.pipe(
    select(selectProductPreAdd),
    map((product: IProduct | null) => {
      if (!product) {
        return 0
      }

      return (
        product.categories?.reduce((price, category) => {
          return category.products
            .filter(({ count }) => !!count)
            .reduce((_price, topping) => {
              // if(!count) {return _price}
              return _price + topping.price * (topping.count ?? 1)
            }, price)
        }, product.price) || product.price
      )
    }),
  )
  imageUrl = ''
  productShape = ProductShape
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  isContinueDisabled$: Observable<boolean> = this.store.pipe(
    select(selectIsCategoryContinueDisabled),
  )

  constructor(
    protected store: Store,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.currentIndex$.subscribe((index) => {
      if (index !== null && index !== undefined) {
        this.currentIndex = index
      }
    })

    this.product$.subscribe((data) => {
      this.product = data
    })

    this.store.pipe(take(1), select(selectSiteInitials)).subscribe((site) => {
      if (site && this.product) {
        if (this.product.shape) {
          this.imageUrl = `${environment.staticFiles}${site.name}/${this.product.shape}Pizza.png`
        } else {
          this.imageUrl = this.product.imageUrl
        }
      }
    })
  }

  onContinueClick() {
    let canContinue = false

    this.isContinueDisabled$.subscribe((val) => {
      canContinue = !val
    })

    if (canContinue) {
      this.resetCategoryPosition()
      this.store.dispatch(ProductsActions.onPageContinue())

      this.store.pipe(select(selectProductToAdd), take(1)).subscribe((data) => {
        if (!data) {
          this.dialogRef.closeAll()
        }
      })
      this.scroll()
    } else this.openInfoModalMandatoryCategory()
  }

  categoryBack() {
    this.resetCategoryPosition()
    this.store.dispatch(ProductsActions.onCategoryBack())
  }

  closeAllDialogs() {
    this.dialogRef.closeAll()
  }

  scroll(reverse = false) {
    let toppingActions = document.getElementById('topping-actions')
    if (toppingActions) {
      let left = reverse ? toppingActions.scrollLeft + 175 : toppingActions.scrollLeft + -175
      toppingActions.scroll({ left, behavior: 'smooth' })
    }
  }

  resetCategoryPosition() {
    let categoryBox = document.querySelector('.simplebar-content-wrapper') as HTMLElement
    if (categoryBox) {
      categoryBox.scrollTop = 0
    }
  }

  // dialogs

  openInfoModalMandatoryCategory() {
    this.dialogRef.open(MandatoryCategoryComponent)
  }
}
