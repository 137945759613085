<bi-products-with-cart>
  <bi-category-title
    page-title="asdsadsa"
    [name]="title"
    [extraName]="categoryName"
    [showBackBtn]="showBackBtn"
  ></bi-category-title>

  <div class="container" page-content>
    <div class="products-container" *ngIf="!showProductToAdd || !(productToAdd$ | ngrxPush)">
      <ng-container *ngFor="let product of products">
        <ng-container [ngSwitch]="type">
          <div
            class="product-wrapper {{product.type_name}}"
            [class.out-of-stock]="!product.in_inventory"
          >
            <bi-drink
              *ngSwitchCase="productType.drink"
              [product]="product"
              [biOutOfStock]="product.in_inventory"
            ></bi-drink>
            <!-- <bi-pizza *ngSwitchCase="productType.pizza"></bi-pizza> -->
            <bi-additional-offer
              *ngSwitchDefault
              [product]="product"
              [biOutOfStock]="product.in_inventory"
            ></bi-additional-offer>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div
      class="product-container"
      *ngIf="showProductToAdd && (productToAdd$ | ngrxPush) as productToAdd"
    >
      <bi-add-product [product]="productToAdd"></bi-add-product>
    </div>
  </div>

  <div class="continue-container" content-footer>
    <div class="btn-group on-product">
      <button class="bi-btn full" *ngIf="siteData?.theme === 'light'" (click)="onNextPage()">
        המשך
      </button>
      <script></script>
    </div>
  </div>
</bi-products-with-cart>
