<div class="page-container">
  <bi-card [cardTitle]="title">
    <div class="text-center form-title">
      <h1>
        ברינגיט מאפשרת ללקוחותיה להיות בחזית הטכנולוגיה <br />
        המערכת מנוהלת על ידי חברת ברינגיט טכנולוגיות המספקת מערכות אונליין CRM ו ERP לעסקים
      </h1>
      <img [src]="commonPath + 'bringit_logo.svg'" class="logo" />
    </div>
    <bi-contact-form
      [isSending]="isSending"
      [successText]="successText"
      [errorText]="errorText"
      (onContact)="onContact($event)"
    ></bi-contact-form>
    <div
      class="button popup-close"
      (click)="!this.deliveryOption ? this.router.navigate([SiteRoutes.Home]) : this.goToFirstPage() "
    >
      x
    </div>
  </bi-card>
</div>
