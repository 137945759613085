import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'

@Component({
  selector: 'bi-out-of-stock',
  templateUrl: './out-of-stock.component.html',
  styleUrls: ['./out-of-stock.component.less'],
})
export class OutOfStockComponent implements OnInit {
  @ViewChild('outOfStock', { static: true }) outOfStockTemplate!: TemplateRef<OutOfStockComponent>

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.outOfStockTemplate)
  }
}
