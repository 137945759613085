<ng-container *ngIf="business$ | ngrxPush">
  <ng-container *ngIf="siteData$ | ngrxPush as siteData">
    <div class="nav-item" *ngFor="let item of navItems" (click)="scrollTop()">
      <a
        [routerLink]="isRoutesDisabled ? null : item.url"
        routerLinkActive="active"
        [ngClass]="{
      'active': item.url?.includes(siteRoutes.Pizza) && pizzasActive.isActive,
      'disabled': isRoutesDisabled
    }"
      >
        <img [src]="staticFilesPath + item.icon" class="nav-icon" alt="אייקון" />
        <span>{{item.name}}</span>
      </a>

      <!-- active link for different routes -->
      <a
        [routerLink]="siteRoutes.PizzaSelect"
        routerLinkActive
        #pizzasActive="routerLinkActive"
        style="display: none"
      ></a>
    </div>
  </ng-container>
</ng-container>
