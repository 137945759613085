import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { ProductsService } from '../../../../site/modules/product/products.service'
import { CartActions } from '../../cart/actions'
import { ProductsActions } from '../actions'
import { IProductOther } from './../../../../site/modules/product/product.model'
import {
  selectDealItemToAdd,
  selectDealPreAdd,
  selectDealToAdd,
  selectProductPreAdd,
} from './../reducers'
import { selectDealStep } from './../reducers/index'

@Injectable()
export class DealsEffects {
  onAddDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onAddDeal),
      map(({ deal }) => {
        if (!deal) {
          return ProductsActions.onPreAddDeal({ dealPreAdd: null })
        }
        const dealPreAdd = this.productsService.mutateDeal(deal)
        dealPreAdd.items = []
        return ProductsActions.onPreAddDeal({ dealPreAdd })
      }),
    ),
  )

  onSelectDealItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onSelectDealItem),
      withLatestFrom(
        this.store.pipe(select(selectDealToAdd)),
        this.store.pipe(select(selectDealPreAdd)),
        this.store.pipe(select(selectDealStep)),
      ),
      map(([{ index }, dealToAdd, dealPreAdd, step]) => {
        if (index === null || !dealPreAdd || !dealToAdd) {
          return ProductsActions.onAddProduct({ product: null })
        }

        dealPreAdd = this.productsService.mutateDeal(dealPreAdd)
        if (!dealPreAdd.items[index]) {
          dealPreAdd.items[index] = dealToAdd.items[index]
          dealPreAdd = this.productsService.mutateDeal(dealPreAdd)
          dealPreAdd.items[index].products = []
          if (dealToAdd.items[index].products.length) {
            const product =
              dealPreAdd.items[index].products[0] ?? dealToAdd.items[index].products[0]
            this.store.dispatch(
              ProductsActions.onSelectDealPreAddProduct({
                dealPreAddProduct: cloneDeep(product),
              }),
            )
            if (dealToAdd.items[index].products.length === 1) {
              if (dealToAdd.items[index].products[0].categories.length) {
                this.store.dispatch(ProductsActions.onPreAddDeal({ dealPreAdd }))
                return ProductsActions.onPageContinue()
              }
            }
          }
        } else if (step % 2 === 0) {
          const product = dealPreAdd.items[index].products[0] ?? dealToAdd.items[index].products[0]
          this.store.dispatch(
            ProductsActions.onSelectDealPreAddProduct({
              dealPreAddProduct: cloneDeep(product),
            }),
          )
          this.store.dispatch(ProductsActions.onAddProduct({ product: null }))
        }
        return ProductsActions.onPreAddDeal({ dealPreAdd })
      }),
    ),
  )

  onPreAddDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onPreAddDeal),
      filter(({ dealPreAdd }) => dealPreAdd === null),
      map(() => {
        return ProductsActions.onSelectDealItem({ index: null })
      }),
    ),
  )

  onAddProductToDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onAddProductToDeal),
      withLatestFrom(
        this.store.pipe(select(selectDealPreAdd)),
        this.store.pipe(select(selectDealToAdd)),
        this.store.pipe(select(selectDealItemToAdd)),
        this.store.pipe(select(selectProductPreAdd)),
      ),
      map(([, dealPreAdd, dealToAdd, itemIndex, productPreAdd]) => {
        if (!dealPreAdd || !dealToAdd || itemIndex === null || !productPreAdd) {
          return ProductsActions.noAction()
        }

        dealPreAdd = this.productsService.mutateDeal(dealPreAdd)
        if (!dealPreAdd.items[itemIndex]?.products.length) {
          dealPreAdd.items[itemIndex].products.push(productPreAdd as IProductOther)
        } else {
          dealPreAdd.items[itemIndex].products[0] = productPreAdd as IProductOther
        }

        if (dealPreAdd.items[itemIndex + 1]) {
          // dealPreAdd.items[itemIndex + 1].products = []
        }
        this.store.dispatch(ProductsActions.onPreAddDeal({ dealPreAdd }))
        if (dealToAdd.items[itemIndex + 1]) {
          this.store.dispatch(ProductsActions.onSelectDealItem({ index: itemIndex + 1 }))
          return ProductsActions.onAddProduct({ product: null })
          // this.store.dispatch(ProductsActions.onAddProduct({ product: null }))
          // return ProductsActions.onSelectDealItem({ index: itemIndex + 1 })
        }

        return CartActions.onDoneAddingDeal()
      }),
    ),
  )

  constructor(
    private actions$: Actions,
    private store: Store,
    private productsService: ProductsService,
  ) {}
}
