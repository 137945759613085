import { NgModule } from '@angular/core'
import { MatMenuModule } from '@angular/material/menu'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { PushModule } from '@ngrx/component'
import { DesktopHeaderComponent } from './desktop-header/desktop-header.component'
import { HeaderComponent } from './header/header.component'
import { MobileHeaderComponent } from './mobile-header/mobile-header.component'
import { MobileNavComponent } from './mobile-header/mobile-nav/mobile-nav.component'
import { StickerClosedComponent } from './sticker-closed/sticker-closed.component'

const declarations = [
  HeaderComponent,
  MobileHeaderComponent,
  DesktopHeaderComponent,
  MobileNavComponent,
  StickerClosedComponent,
]
@NgModule({
  imports: [BrowserModule, PushModule, RouterModule, MatMenuModule],
  declarations: declarations,
  exports: declarations,
})
export class HeaderModule {}
