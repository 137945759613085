import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ThankYouComponent } from '../../../common/components/thank-you/thank-you.component'
import { BusinessMenuService } from '../../../common/services/business/business-menu.service'
import { SiteRoutes } from '../../../routes.const'
import { OrderService } from '../../modules/order/order.service'
import { selectBusinessDetails } from './../../../common/components/loader/reducers/index'
import { SuggestImprovmentsService } from './suggest-improvments.service'

@Component({
  selector: 'bi-suggest-improvments',
  templateUrl: './suggest-improvments.component.html',
  styleUrls: ['./suggest-improvments.component.less'],
})
export class SuggestImprovmentsComponent implements OnInit {
  suggestForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]),
    email: new UntypedFormControl('', Validators.required),
    message: new UntypedFormControl('', Validators.required),
  })

  result = ''
  isSending = false
  deliveryOption = this.orderService.getDeliveryOption()

  constructor(
    private store: Store,
    private orderService: OrderService,
    private suggestImprovmentsService: SuggestImprovmentsService,
    private dialog: MatDialog,
    public router: Router,
    private businessMenuService: BusinessMenuService,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business) {
        this.suggestForm.addControl('b', new UntypedFormControl(business.business.business_id))
      }
    })
  }

  onAddSuggestion() {
    this.result = ''
    this.isSending = true
    this.suggestImprovmentsService
      .onAddSuggestion(this.suggestForm.value)
      .pipe(
        catchError(() => {
          this.result = 'פניתכם לא התקבלה עקב תקלת מערכת, נסו שוב'
          this.isSending = false
          return throwError('')
        }),
      )
      .subscribe((response) => {
        this.isSending = false
        if (response) {
          this.result = ''
          const dialogRef = this.dialog.open(ThankYouComponent, {
            disableClose: true,
            panelClass: 'bg-transparent',
          })
        }
      })
  }

  goToFirstPage(): void {
    this.businessMenuService.goToFirstPage()
  }

  protected readonly SiteRoutes = SiteRoutes
}
