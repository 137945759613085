import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { INavItems } from '../../../header/nav-menu.model'

@Component({
  selector: 'bi-group-product-item',
  templateUrl: './group-product-item.component.html',
  styleUrls: ['./group-product-item.component.less'],
})
export class GroupProductItemComponent {
  @Input() item!: INavItems

  constructor(private router: Router) {}
  goTo(item: INavItems): void {
    this.router.navigate(['general', item.type_name, item.product_type_id], {
      queryParams: { fromGroup: true },
    })
  }
}
