import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'bi-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.less']
})
export class InfoModalComponent implements OnInit {
  data: any
  okBtnText: string = 'אישור'
  cancelBtnText: string|boolean = false

  okBtnCallback: Function = () => {}
  cancelBtnCallback: Function = () => {}

  constructor(@Inject(MAT_DIALOG_DATA) public dataIn: any) {
    this.data = dataIn
    this.okBtnText = dataIn.okBtnText
    this.cancelBtnText = dataIn.cancelBtnText
    if (this.data.cancelBtnCallback) {
      this.cancelBtnCallback = this.data.cancelBtnCallback
    }
    if (this.data.okBtnCallback) {
      this.okBtnCallback = this.data.okBtnCallback
    }
  }


  ngOnInit(): void {
  }

}
