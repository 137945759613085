import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { memoizeObservable } from '../../../../modules/decorators/memoize-observable/memoizeObservable.decorator'
import { environment } from './../../../../../../environments/environment'

interface ISearchStreetResponse {
  status: boolean
  streets: IStreetSearh[]
}

export interface IStreetSearh {
  city_id: string
  id: string
  name: string
}

export interface ICity {
  id: string
  name: string
  streets: IStreetSearh[]
}

@Injectable()
export class ClientAddressService {
  constructor(private http: HttpClient) {}

  @memoizeObservable()
  searchStreet(city_id: string, str: string): Observable<IStreetSearh[]> {
    const params = new HttpParams()
      .set('apiCtrl', 'pizziria')
      .set('do', 'searchStreets')
      .set('city_id', city_id)
      .set('q', str)

    return this.http
      .get<ISearchStreetResponse>(environment.api1, { params })
      .pipe(map((respone: ISearchStreetResponse) => respone.streets))
  }
}
