<div class="category-product" *ngFor="let product of category.products; let index = index" >
  <div class="details" (click)="onAdd(index)">
    <div class="name">{{product.name}}</div>
    <div class="price shekel">{{ showFixedPrice ? category.fixed_price : product.price }}</div>
  </div>
  <div class="actions">
    <bi-add-remove-btns
      [allowMultipleSelection]="category.is_multiple_selection"
      [selectionLimit]="category.products_limit"
      [showAddIcon]="!!product.selected"
      [count]="product.count || 0"
      (onAdd)="onAdd(index)"
      (onRemove)="onRemove(product)"
    ></bi-add-remove-btns>
  </div>
</div>
