import {Component, Inject, OnInit} from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { interval } from 'rxjs'
import { mergeMap, take, takeUntil, takeWhile } from 'rxjs/operators'
import {
  selectBusinessDetails,
  selectSiteInitials,
} from '../../../common/components/loader/reducers'
import { OrderActions } from '../../modules/order/actions'
import { environment } from '../../../../environments/environment'
import { Subscriber } from '../../../common/classes/subscriber/subscriber'
import { SiteRoutes } from '../../../routes.const'
import { OrderService } from '../../modules/order/order.service'
import { PickupComponent } from '../../home/home/select-address/pickup/pickup.component'
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog'
import { SelectAddressComponent } from '../../home/home/select-address/select-address.component'
import { MobileDetectorService } from '../../../common/services/mobile-detector/mobile-detector.service'
import { PreOrderModalComponent } from '../../pre-order-modal/pre-order-modal.component'
import {InfoModalComponent} from "../../../common/components/info-modal/info-modal.component";

@Component({
  selector: 'bi-select-payment',
  templateUrl: './select-payment.component.html',
  styleUrls: ['./select-payment.component.less'],
})
export class SelectPaymentComponent extends Subscriber implements OnInit {
  staticFilesPath = ''
  dialogWidth: string

  constructor(
    private dialog: MatDialog, private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private router: Router, private orderService: OrderService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super()
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
    this.dialogWidth = this.mobileDetectorService.isMobile()
      ? '340px'
      : '520px'
  }

  ngOnInit(): void {}

  onCashPayment() {
    this.orderService.setPayment('cash')
    this.router.navigate([SiteRoutes.ConfirmCash])
  }

  onCreditPayment() {
    this.orderService.setPayment('visa')
    this.orderService.preCreditPayment().subscribe(
      ({ token }) => {
        const order = this.orderService.getOrder()
        let total = order.total
        if (order.deliveryOption === 'delivery') {
          this.store.pipe(take(1), select(selectBusinessDetails)).subscribe((business) => {
            if (business?.business) {
              total += business.business.delivery_cost
            }
          })
        }

        const payLink = `${environment.paymentLink}${order.business_id}/${total}/${token}`
        this.openPaymentWindow(payLink)
      },
      () => {
        alert('אירעה שגיאה, נא לנסות שנית')
      },
    )
  }

  onCreditPaymentForCG() {
    this.orderService.setPayment('visa')
    this.orderService.preCreditPaymentForCG().subscribe(
      ({paylink}) => {
        const order = this.orderService.getOrder()
        let total = order.total
        if (order.deliveryOption === 'delivery') {
          this.store.pipe(take(1), select(selectBusinessDetails)).subscribe((business) => {
            if (business?.business) {
              total += business.business.delivery_cost
            }
          })
        }
        this.matDialog.open(InfoModalComponent, {
          panelClass: 'coupon-info-modal',
          disableClose: true,
          data: {
            text: 'הקופון כבר נוצל',
            okBtnText: 'שלח הזמנה ללא הקופון',
            cancelBtnText: 'החזר אותי לעגלת הקניות',
            okBtnCallback: () => {
              this.orderService.removeCoupon()
              this.matDialog.closeAll()
            },
            cancelBtnCallback: () => {
              this.router.navigate([SiteRoutes.Cart])
              this.matDialog.closeAll()
            }
          }
        })
        // this.openPaymentWindow(paylink)
      },
      () => {
        // alert('אירעה שגיאה, נא לנסות שנית')
      },
    )
  }

  private openPaymentWindow(payLink: string) {
    const win = window.open(payLink, '_blank', 'top=20,left=500,width=500,height=650')

    // check if payment is done every 5 seconds
    // interval(5000)
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe),
    //     mergeMap(() => this.orderService.isOrderCreditPaid()),
    //     takeWhile((response: any) => !!response.error, true),
    //   )
    //   .subscribe((response: any) => {
    //     if (!response.error && response.order_id) {
    //       localStorage.setItem('order_id', response.order_id)
    //       win?.close()
    //       this.store.dispatch(OrderActions.onCompletedOrder())
    //       this.router.navigate([SiteRoutes.Tracker])
    //     }
    //   })



  }
}
