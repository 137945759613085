<ng-container *ngIf='deal$ | ngrxPush as deal'>
  <div class='centered'>
    <div *ngIf='siteData$ | ngrxPush as siteData' class='w-100 d-flex'>
      <div *ngIf="siteData.theme === 'light'" id='deal-items-container' class='deal-products-name'
           [ngClass]="deal.items.length <= 3 ? 'justify-center' : 'justify-start'"
      >
        <ng-container *ngFor='let item of deal.items; let index = index'>
          <div
            class='item-name'
            [class.selected]='item.selected'
            [class.done]='item.doneAdding'
            (click)='item.doneAdding && onItemClick(index)'
          >
            {{
            item.products.length == 1 ? item.products[0].name :
              getItemTypeNameTranslate(item.type_name)
            }}

            <img
              *ngIf='businessName && item.doneAdding'
              [src]="'assets/' + businessName + '/images/item_done.svg'"
              class='item-done-adding'
            />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
