<bi-card-standard>
  <div class="title" page-title>בחר אמצעי תשלום</div>
  <div class="card-content" page-content>
    <div class="payment cash" (click)="onCashPayment()">
      <div class="img-container">
        <img [src]="staticFilesPath + 'icons/cash.png'" class="responsive" alt="תשלום במזומן" />
      </div>
      <div class="name">מזומן</div>
    </div>

    <div class="payment credit" (click)="onCreditPaymentForCG()">
      <div class="img-container">
        <img [src]="staticFilesPath + 'icons/credit.png'" class="responsive" alt="תשלום באשראי" />
      </div>
      <div class="name">אשראי</div>
    </div>
  </div>
</bi-card-standard>
