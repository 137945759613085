<bi-products-with-cart *ngIf="siteData">
  <bi-category-title
    *ngIf="!isMobile"
    page-title
    [name]="deal.name"
    [extraName]="categoryName"
    [showBackBtn]="showBackBtn"
  ></bi-category-title>

  <div class="content" page-content>
    <!--    <bi-deal-items-map></bi-deal-items-map>-->
    <div class="products-container" *ngIf="!showProductToAdd || !(productToAdd$ | ngrxPush)">
      <div class="products-in-card">
        <!--        <div *ngIf="isMobile"  class="btn-group on-deal">-->
        <!--          <button class="bi-btn secondary back" (click)="onBackClick()">חזור</button>-->
        <!--          <button class="bi-btn next" (click)="product && onNextClick()" [disabled]="!product">-->
        <!--            המשך-->
        <!--          </button>-->
        <!--        </div>-->
        <div class="bordered shadowed">
          <h2 class="text-center my-1">בחר מוצר</h2>
          <div class="products-wrapper">
            <div
              class="1 bordered box shadowed product {{product.type_name}}"
              *ngFor="let product of products; let index = index"
              (click)="toggleSelected(this.product.type_name, index)"
              [ngClass]="{'selected': index === selectedProductIndices[this.product.type_name]}"
            >
              <ng-container [ngSwitch]="type">
                <bi-pizza
                  *ngSwitchCase="productType.pizza"
                  [product]="product"
                  [inDeal]="true"
                ></bi-pizza>
                <bi-drink
                  *ngSwitchCase="productType.drink"
                  [product]="product"
                  [inDeal]="true"
                ></bi-drink>
                <bi-additional-offer
                  *ngSwitchDefault
                  [product]="product"
                  [inDeal]="true"
                ></bi-additional-offer>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="product-img-placeholder" *ngIf="siteData.theme === 'light'">
          <div class="deal-items-map-container" *ngIf="deal$ | ngrxPush">
            <bi-deal-items-map></bi-deal-items-map>
          </div>
          <div class="deal-image-placeholder" [ngClass]="type">
            <img [src]="dealImgPlaceholder$ | ngrxPush" alt="deal-placeholder" />
          </div>
          <div class="product-info">
            <h2 class="title text-center">{{product?.name}}</h2>
            <p *ngIf="!isMobile" class="description-text text-center">{{product?.description}}</p>
          </div>
          <div class="btn-group on-deal">
            <button class="bi-btn secondary back" (click)="onBackClick()">חזור</button>
            <button class="bi-btn next" (click)="product && onNextClick()" [disabled]="!product">
              המשך
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="product-container"
      *ngIf="showProductToAdd && (productToAdd$ | ngrxPush) as productToAdd"
    >
      <bi-add-product [product]="productToAdd"></bi-add-product>
    </div>
  </div>
</bi-products-with-cart>
