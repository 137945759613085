import { Component, OnInit, SimpleChanges } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { ISiteInitials } from '../../../initial-data/initial-data.model'
import { selectCartProducts } from '../../cart/reducers'
import { selectBusinessDetails, selectSiteInitials } from '../../loader/reducers'
import { navMenuItems } from '../nav-menu.const'
import { INavItems } from '../nav-menu.model'
import { environment } from './../../../../../environments/environment'
import { IBusinessResponse } from './../../../../common/services/business/business.model'
import { SiteRoutes } from './../../../../routes.const'
import { DeliveryOption } from './../../../../site/modules/order/order.model'
import { OrderService } from './../../../../site/modules/order/order.service'

@Component({
  selector: 'bi-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.less'],
})
export class DesktopHeaderComponent implements OnInit {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  staticFilesPath = ''
  navItems: INavItems[] = navMenuItems
  siteRoutes = SiteRoutes
  isRoutesDisabled = false
  cartCount = 0
  cartData = {}
  isNewHeader = false

  constructor(private store: Store, private orderService: OrderService) {
    this.business$.subscribe((businessDetails) => {
      if (!businessDetails) {
        return
      }
      this.isNewHeader = !!businessDetails.business.menuDesktop?.length
      if (this.isNewHeader) {
        this.navItems = businessDetails.business.menuDesktop
      }
    })
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
    store.pipe(select(selectCartProducts)).subscribe((cart) => {
      this.cartCount = 0
      if (cart.length) {
        cart.forEach((item) => {
          if (item.type_name === 'deal') {
            this.cartCount++
            return
          }
          this.cartCount = this.cartCount + item.count
        })
      }
    })
  }

  ngOnInit(): void {
    this.initRoutesDisabled()
  }

  ngOnChanges(changes: SimpleChanges) {}

  private initRoutesDisabled(): void {
    this.orderService.selectedDeliveryOptionSubject$
      .asObservable()
      .subscribe((opt: DeliveryOption | undefined) => {
        this.isRoutesDisabled = !opt
      })
  }
}
