import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { isEqual, omit } from 'lodash'
import { take } from 'rxjs/operators'
import { ProductsActions } from '../products/actions'
import { OrderService } from './../../../site/modules/order/order.service'
import {
  IProduct,
  IProductDeal,
  IProductOther,
  IRemoveCartTopping,
} from './../../../site/modules/product/product.model'
import { ProductsService } from './../../../site/modules/product/products.service'
import { selectDealPreAdd, selectProductPreAdd } from './../products/reducers/index'

@Injectable()
export class CartService {
  constructor(
    private orderService: OrderService,
    private store: Store,
    private productsService: ProductsService,
  ) {}

  addProductToCart() {
    this.store.pipe(select(selectProductPreAdd), take(1)).subscribe((product: IProduct | null) => {
      if (product) {
        const order = this.orderService.getOrder()
        const productIndex = order.cart.findIndex((p) => {
          const p1 = omit(p, 'count')
          const p2 = omit(product, 'count')
          return isEqual(p1, p2)
        })
        if (productIndex > -1) {
          order.cart[productIndex].count++
        } else {
          order.cart.push(product)
        }
        this.orderService.setOrder(order)
        this.store.dispatch(ProductsActions.onAddProduct({ product: null }))
      }
    })
  }

  addDealToCart() {
    this.store.pipe(select(selectDealPreAdd), take(1)).subscribe((product: IProductDeal | null) => {
      if (product) {
        const order = this.orderService.getOrder()
        order.cart.push(product)
        this.orderService.setOrder(order)
        this.store.dispatch(ProductsActions.onAddDeal({ deal: null }))
      }
    })
  }

  clearCart() {
    this.orderService.clearCart()
  }

  removeProductFromCart(product: IProduct, index:any) {

    const cart = this.orderService.getCart()
    // const index = cart.findIndex((p) => p.id === product.id && p.type_id === product.type_id)
    if (index === undefined) {
      index = cart.findIndex((p) => p.id === product.id && p.type_id === product.type_id)
    }
    if (index !== -1) {
      if (cart[index].count > 1) {
        cart[index].count--
      }
      else {
        cart.splice(index, 1)
      }
      this.orderService.setCart(cart)
    }
  }

  removeToppingFromCart(data: IRemoveCartTopping) {
    const { productIndex, dealIndex, itemIndex } = data
    const cart = this.orderService.getCart()

    if (dealIndex != undefined && itemIndex != undefined) {
      const items = cart[dealIndex].items
      if (items) {
        items[itemIndex].products[productIndex] = this.removeToppingFrom(
          items[itemIndex].products[productIndex],
          data,
        ) as IProductOther

        cart[dealIndex].items = items
      }
    } else {
      if (cart[productIndex]) {
        const product = this.removeToppingFrom(cart[productIndex], data)
        this.productsService.setLayersPrice(product.categories?.[data.categoryIndex])
        cart[productIndex] = product
      }
    }

    this.orderService.setCart(cart)
  }

  private removeToppingFrom(product: IProduct, data: IRemoveCartTopping): IProduct {
    const { categoryIndex, toppingIndex } = data
    if (product.categories && product.categories[categoryIndex].products[toppingIndex]) {
      product.categories[categoryIndex].products.splice(toppingIndex, 1)
    }
    return product
  }
}
