import { Subject } from 'rxjs'
import { Injectable, OnDestroy } from '@angular/core'

@Injectable()
export class Subscriber implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>()

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  $onDestroy() {
    this.ngOnDestroy()
  }
}
