import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { PushModule } from '@ngrx/component'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { SharedModule } from '../../../site/modules/shared.module'
import { SiteLoaderEffects } from './effects/site-loader.effects'
import { LoaderComponent } from './loader/loader.component'
import { fromSiteLoader } from './reducers'
import { siteLoaderFeatureKey } from './reducers/site-loader.reducers'

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(siteLoaderFeatureKey, fromSiteLoader.reducer),
    EffectsModule.forFeature([SiteLoaderEffects]),
    PushModule,
    SharedModule,
  ],
  declarations: [LoaderComponent],
  providers: [],
  exports: [LoaderComponent],
})
export class SiteLoaderModule {}
