<div class="dd" [formGroup]="addressForm">
<!--  <div class="form-control">-->
<!--    <input type="text" class="prmBorder" placeholder="*עיר" [value]="city_name.value" disabled />-->
<!--  </div>-->

  <div class="form-field city-field form-control">
    <input type="text" matInput [value]="city_name.value" placeholder="עיר" formControlName="city_name" [matAutocomplete]="autoCity"/>
    <input type='hidden' [value]='city_id' formControlName="city_id">
    <mat-autocomplete
      #autoCity="matAutocomplete"
      (optionSelected)="onSelectOptionCity($event)"
    >
      <mat-option *ngFor="let city of businessCities; let index = index;" [value]="city.name" [id]='city.id'>
        {{city.name}}
      </mat-option>
    </mat-autocomplete>


  </div>

  <div class="form-field street-field form-control">
    <input type="text" placeholder="רחוב" formControlName="street" [matAutocomplete]="autoStreet" />
    <mat-autocomplete
      #autoStreet="matAutocomplete"
      (closed)="onAutocompleteClose()"
      (optionSelected)="onSelectOptionStreet($event)"
    >
      <mat-option *ngFor="let street of streets; let index = index; " [value]="street.name" [id]='street.id'>
        {{street.name}}
      </mat-option>
    </mat-autocomplete>

    <div class="street-error" *ngIf="streetErr">{{streetErr}}</div>
    <div class="spinner-container" *ngIf="isLoadingStreets">
      <bi-spinner></bi-spinner>
    </div>
  </div>

  <div class="form-control">
    <input type="text" class="prmBorder" placeholder="*מס' בית" formControlName="house_num" />
  </div>

  <div class="form-control">
    <input type="text" class="prmBorder" placeholder="כניסה" formControlName="entrance" />
  </div>

  <div class="form-control">
    <div class="wrapper">
      <div class="right">
        <input type="text" class="prmBorder" placeholder="קומה" formControlName="floor" />
      </div>
      <div class="left">
        <input type="text" class="prmBorder" placeholder="מס' דירה" formControlName="apt_num" />
      </div>
    </div>
  </div>
</div>
