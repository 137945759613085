import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { PushModule } from '@ngrx/component'
import { CardStandardComponent } from '../../common/components/card-standard/card-standard.component'
import { CartComponent } from '../../common/components/cart/cart/cart.component'
import { CartCategoryComponent } from '../../common/components/cart/cart/regular-product/cart-category/cart-category.component'
import { DividedToppingModule } from '../../common/components/products/add-product/categories/category/divided-category/divided-topping/divided-topping.module'
import { PizzaItemComponent } from '../deals/deals/select-pizza/pizza-item/pizza-item.component'
import { CardComponent } from './../../common/components/card/card.component'
import { AddCouponComponent } from './../../common/components/cart/cart/add-coupon/add-coupon.component'
import { DealInCartComponent } from './../../common/components/cart/cart/deal-in-cart/deal-in-cart.component'
import { RegularProductComponent } from './../../common/components/cart/cart/regular-product/regular-product.component'
import { SpinnerComponent } from './../../common/components/spinner/spinner.component'

const declarations = [
  SpinnerComponent,
  CardComponent,
  CartComponent,
  RegularProductComponent,
  DealInCartComponent,
  CartCategoryComponent,
  CardStandardComponent,
  PizzaItemComponent,
  AddCouponComponent,
]

@NgModule({
  imports: [CommonModule, PushModule, MatDialogModule, DividedToppingModule, ReactiveFormsModule],
  declarations: declarations,
  exports: declarations,
})
export class SharedModule {}
