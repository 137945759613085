<div class="page-container">
  <div class="page-title">
    <ng-content select="[page-title]"></ng-content>
  </div>
  <ng-container *ngIf="siteData$ | ngrxPush as siteData">
    <div class="content-container">
      <div class="page-content">
        <ng-content select="[page-content]"></ng-content>
      </div>
      <div class="cart-container" *ngIf="siteData.theme === 'light' && showInDeal">
        <bi-cart [isDealPage]="true"></bi-cart>
      </div>
    </div>
  </ng-container>
  <!--  <div class='content-container'>-->
  <!--    <ng-content select="[content-footer]"></ng-content>-->
  <!--  </div>-->
</div>
