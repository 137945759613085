import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bi-add-coupon-modal',
  templateUrl: './add-coupon-modal.component.html',
  styleUrls: ['./add-coupon-modal.component.less']
})
export class AddCouponModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
