import { ISiteInitials } from './initial-data.model'

const siteInitDataMap: Iterable<readonly [String, ISiteInitials]> = [
  [
    'localhost:4200',
    {
      name: 'il_napoli',
      styles: [
        {
          name: 'il_napoli',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'dev.client.bringit.org.il',
    {
      name: 'donpedro',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'dev2.client.bringit.org.il',
    {
      name: 'italiya',
      styles: [
        {
          name: 'italiya',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'meri.dev.bringit.org.il',
    {
      name: 'meri',
      styles: [
        {
          name: 'meri',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'meri.test.bringit.org.il',
    {
      name: 'meri',
      styles: [
        {
          name: 'meri',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'meri.stg.bringit.co.il',
    {
      name: 'meri',
      styles: [
        {
          name: 'meri',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'mari.stg.bringit.co.il',
    {
      name: 'meri',
      styles: [
        {
          name: 'meri',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'american.dev.client.bringit.org.il',
    {
      name: 'american',
      styles: [
        {
          name: 'american',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'test.client.bringit.org.il',
    {
      name: 'donpedro',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'american.test.bringit.org.il',
    {
      name: 'american',
      styles: [
        {
          name: 'american',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'test2.client.bringit.org.il',
    {
      name: 'italiya',
      styles: [
        {
          name: 'italiya',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'stg.client.bringit.co.il',
    {
      name: 'donpedro',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'donpedro.stg.bringit.co.il',
    {
      name: 'donpedro',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'stg2.client.bringit.co.il',
    {
      name: 'italiya',
      styles: [
        {
          name: 'italiya',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'american.stg.bringit.co.il',
    {
      name: 'american',
      styles: [
        {
          name: 'american',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'donpedro.co.il',
    {
      name: 'donpedro',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'etalya.co.il',
    {
      name: 'italiya',
      styles: [
        {
          name: 'italiya',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'apizza.co.il',
    {
      name: 'american',
      styles: [
        {
          name: 'american',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'pizzameri.co.il',
    {
      name: 'meri',
      styles: [
        {
          name: 'meri',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'pizzahook.co.il',
    {
      name: 'hook',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'hook.dev.bringit.org.il',
    {
      name: 'hook',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'hook.test.bringit.org.il',
    {
      name: 'hook',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'hook.stg.bringit.co.il',
    {
      name: 'hook',
      styles: [
        {
          name: 'donpedro',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'il-napoli.dev.bringit.org.il',
    {
      name: 'il_napoli',
      styles: [
        {
          name: 'il_napoli',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'panino.dev.bringit.org.il',
    {
      name: 'panino',
      styles: [
        {
          name: 'panino',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'il-napoli.test.bringit.org.il',
    {
      name: 'il_napoli',
      styles: [
        {
          name: 'il_napoli',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'panino.test.bringit.org.il',
    {
      name: 'panino',
      styles: [
        {
          name: 'panino',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'il-napoli.stg.bringit.co.il',
    {
      name: 'il_napoli',
      styles: [
        {
          name: 'il_napoli',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'panino.stg.bringit.co.il',
    {
      name: 'panino',
      styles: [
        {
          name: 'panino',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'panino.co.il',
    {
      name: 'panino',
      styles: [
        {
          name: 'panino',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
  [
    'ilnapoli.co.il',
    {
      name: 'il_napoli',
      styles: [
        {
          name: 'il_napoli',
          loaded: false,
        },
      ],
      theme: 'light',
    },
  ],
]

export const siteInitailMap: Map<String, ISiteInitials> = new Map(siteInitDataMap)
