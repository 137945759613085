import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { FooterNav } from '../../../footer/footer.const'
import { selectBusinessDetails, selectSiteInitials } from '../../../loader/reducers'
import { environment } from './../../../../../../environments/environment'
import { SiteRoutes } from './../../../../../routes.const'
import { IBusinessResponse } from './../../../../services/business/business.model'
import { TermsAndConditionsService } from './../../../../services/terms-and-conditions.service'

@Component({
  selector: 'bi-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.less'],
})
export class MobileNavComponent implements OnInit {
  @Output()
  onCloseNav: EventEmitter<boolean> = new EventEmitter()

  staticFilesPath = ''
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  navItems = FooterNav
  siteRoutes = SiteRoutes

  constructor(
    private store: Store,
    private router: Router,
    private termsAndConditionsService: TermsAndConditionsService,
  ) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {}

  closeNav() {
    this.onCloseNav.emit()
  }

  onLinkClick(url: string | undefined) {
    if (!url) {
      return
    }

    if (url === SiteRoutes.Terms) {
      this.termsAndConditionsService.openTermsAndConditions()
    } else {
      this.router.navigate([url])
    }
    this.closeNav()
  }
}
