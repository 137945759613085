import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'bi-mandatory-category',
  templateUrl: './mandatory-category.component.html',
  styleUrls: ['./mandatory-category.component.less'],
})
export class MandatoryCategoryComponent {
  constructor(private dialogRef: MatDialogRef<MandatoryCategoryComponent>) {}

  closeDialog() {
    this.dialogRef.close()
  }
}
