import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { environment } from '../../../../../../../environments/environment'
import { IProductCategory } from '../../../../../../site/modules/product/product.model'

@Component({
  selector: 'bi-draw-topping-rectangle',
  templateUrl: './draw-topping-rectangle.component.html',
  styleUrls: ['./draw-topping-rectangle.component.less'],
})
export class DrawToppingRectangleComponent implements AfterViewInit, OnChanges {
  @ViewChild('right')
  right!: ElementRef

  @ViewChild('rightMiddle')
  rightMiddle!: ElementRef

  @ViewChild('leftMiddle')
  leftMiddle!: ElementRef

  @ViewChild('left')
  left!: ElementRef

  @Input()
  categories!: IProductCategory[] | undefined

  toppingUrl = environment.staticFiles + 'topping/rectangle/'

  constructor(private renderer: Renderer2) {}

  ngOnChanges({ categories }: SimpleChanges) {
    if (categories && !categories.firstChange && this.categories?.length) {
      this.drawToppings()
    }
  }

  ngAfterViewInit() {
    this.drawToppings()
  }

  private drawToppings() {
    const dividedCategory = this.categories?.find(({ is_topping_divided }) => is_topping_divided)
    if (!dividedCategory) {
      return
    }

    let right = '' //this.tl.nativeElement.style.backgroundImage
    let rightMiddle = ''
    let leftMiddle = ''
    let left = ''

    dividedCategory.products.forEach((topping) => {
      if (topping.location) {
        const picture = `url(${this.toppingUrl}${topping.picture}),`

        if (topping.location === 'full') {
          right += picture
          rightMiddle += picture
          leftMiddle += picture
          left += picture
        } else if (topping.location === 'leftHalfPizza') {
          left += picture
          leftMiddle += picture
        } else if (topping.location === 'rightHalfPizza') {
          right += picture
          rightMiddle += picture
        } else if (topping.location === 'tl') {
          left += picture
        } else if (topping.location === 'tr') {
          right += picture
        } else if (topping.location === 'bl') {
          leftMiddle += picture
        } else if (topping.location === 'br') {
          rightMiddle += picture
        }
      }
    })

    right = right.replace(/(,)$/, '')
    rightMiddle = rightMiddle.replace(/(,)$/, '')
    leftMiddle = leftMiddle.replace(/(,)$/, '')
    left = left.replace(/(,)$/, '')

    this.renderer.setStyle(this.right.nativeElement, 'background-image', right)
    this.renderer.setStyle(this.rightMiddle.nativeElement, 'background-image', rightMiddle)
    this.renderer.setStyle(this.leftMiddle.nativeElement, 'background-image', leftMiddle)
    this.renderer.setStyle(this.left.nativeElement, 'background-image', left)
  }
}
