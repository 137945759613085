import { HttpErrorResponse } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import {UntypedFormControl, Validators} from '@angular/forms'
import { Store } from '@ngrx/store'
import { debounceTime, first, takeUntil } from 'rxjs/operators'
import { IProduct } from 'src/app/site/modules/product/product.model'
import { ProductsActions } from '../../../products/actions'
import { CartActions } from '../../actions'
import { OrderService } from './../../../../../site/modules/order/order.service'
import { ProductsService } from './../../../../../site/modules/product/products.service'
import { Subscriber } from './../../../../classes/subscriber/subscriber'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'bi-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.less'],
})
export class AddCouponComponent extends Subscriber implements OnInit {
  @Output()
  onValidCoupon: EventEmitter<boolean> = new EventEmitter()

  @Output()
  onCouponChange: EventEmitter<string> = new EventEmitter()

  couponCtr = new UntypedFormControl('', [Validators.required, Validators.minLength(4)])
  isLoading = false
  errorMessage = ''

  constructor(
    private productsService: ProductsService,
    private store: Store,
    private orderService: OrderService,
    private dialogRef: MatDialogRef<AddCouponComponent>,
  ) {
    super()
  }

  ngOnInit(): void {
    this.subscribeToCtrl()
  }

  get coupon(): string {
    return this.couponCtr.value
  }

  onClick() {
    this.onCouponChange.emit(this.coupon)
  }

  onAddCoupon(event: MouseEvent | Event): void {

    this.isLoading = true
    event.preventDefault()
    if (!this.coupon) {
      return
    }

    if (this.orderService.cartHasCoupon()) {
      this.errorMessage = '' +
        'חד'
      return
    }

    this.isLoading = true
    this.errorMessage = ''
    this.productsService
      .getProductsByCoupon(this.coupon)
      .pipe(first())
      .subscribe({
        next: (products: IProduct[]) => {
          this.isLoading = false
          this.orderService.setCoupon(this.coupon)
          this.store.dispatch(ProductsActions.onAddProduct({ product: products[0] }))
          this.store.dispatch(CartActions.onDoneAddingProduct())
          this.dialogRef.close()
        },
        error: (err: HttpErrorResponse) => {
          this.isLoading = false
          const { error } = err

          if (!error.errorCode) {
            this.errorMessage = 'שגיאת מערכת, נסה מאוחר יותר'
            alert( 'שגיאת מערכת, נסה מאוחר יותר')
            return
          }

          this.errorMessage = 'לא נמצא קופון מתאים'
          alert(this.errorMessage = 'לא נמצא קופון מתאים')
        },
      })
    if (this.isLoading) {
      return;
    }
  }

  private subscribeToCtrl() {
    this.couponCtr.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(300))
      .subscribe((coupon) => {
        this.onCouponChange.emit(coupon)
      })
  }
}
