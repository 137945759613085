import { ISiteInitials } from './../../../initial-data/initial-data.model'
import { createAction, props } from '@ngrx/store'
import { IBusinessResponse } from './../../../../common/services/business/business.model'

export const loading = createAction('[Site Initials] Loading', props<{ loading: boolean }>())

export const onSiteInitialLoading = createAction('[Site Initials] Site Initials Loading')
export const onSiteInitialLoaded = createAction(
  '[Site Initials] Site Initials Loaded',
  props<{ siteInitials: ISiteInitials }>(),
)

export const onBusinessDetailsLoading = createAction('[Business Details] Business Details Loading')
export const onSuccessBusinessDetailsLoaded = createAction(
  '[Business Details] Business Details Loaded',
  props<{ businessDetails: IBusinessResponse }>(),
)
