<div mat-dialog-close class="button popup-close">X</div>
<div class='modal-container'>
  <h2 class='title'>בחר אמצעי תשלום</h2>
  <div class='card-content'>
    <div class='payment cash' [class.active]="paymentType === 'cash'" (click)="setPaymentType('cash'); this.router.navigate([SiteRoutes.ConfirmCash])">
      <div class='img-container'>
        <img [src]="staticFilesPath + 'icons/cash.svg'" class='responsive' alt='תשלום במזומן' />
      </div>
      <div class='name'>מזומן</div>
    </div>


    <div class='payment credit' [class.active]="paymentType === 'visa'" (click)="setPaymentType('visa'); ">
      <div class='img-container'>
        <img [src]="staticFilesPath + 'icons/credit.svg'" class='responsive' alt='תשלום באשראי' />
      </div>
      <div class='name'>אשראי</div>
    </div>
  </div>

<!--  <button class="bi-btn checkout-btn" [disabled]='!paymentType' (click)='doPayment()'>לתשלום</button>-->

</div>
