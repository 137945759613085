import { INavItems } from '../../components/header/nav-menu.model'

export interface IBusinessResponse {
  business: IBusiness
  status: boolean
  utoken: string
}

export interface IBusiness {
  cities: any
  additional_delivery_time: number
  android_app: string
  business_address: string
  business_folder: string
  business_id: string
  business_name_commercial: string
  business_name_formal: string
  business_phone: string
  delivery_cost: number
  delivery_time: number
  ios_app: string
  is_opened: number
  logo_url: string
  min_delivery_price: number
  status: number
  topping_method: ToppingMethod
  theme: string
  business_information: IBusinessInformation
  menuDesktop: INavItems[]
  menuMobile: INavItems[]
  businessProductTypes: BusinessProductType[]
}

export interface BusinessProductType {
  icon: string
  id: string
  name: string
  product_type_id: string
  type: string
}

export interface IBusinessInformation {
  business_about: string
  business_address: string
  business_email: string
  business_name_commercial: string
  business_name_formal: string
  business_phone: string
}
export enum ToppingMethod {
  ByLocation = 1,
  ByLayer = 2,
  ByPrice = 3,
}
