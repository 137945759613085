import { createAction, props } from '@ngrx/store'
import { IProduct, IRemoveCartTopping } from '../../../../site/modules/product/product.model'

export const onDoneAddingProduct = createAction('[Cart] On Done Adding Product')
export const onDoneAddingDeal = createAction('[Cart] On Done Adding Deal')
export const onUpdateCartTotal = createAction('[Cart] On Update Cart Total')
export const onCartTotalUpdated = createAction(
  '[Cart] On Cart Total Updated',
  props<{ total: number }>(),
)
export const onUpdateCartProducts = createAction(
  '[Cart] On Cart Products Updated',
  props<{ cart: IProduct[] }>(),
)

export const onClearCart = createAction('[Cart] On Clear Cart')

export const onRemoveProduct = createAction(
  '[Cart] On Remove Product',
  props<{ product: IProduct, index?: number }>(),
)

export const onRemoveTopping = createAction(
  '[Products] On Remove Topping',
  props<IRemoveCartTopping>(),
)
