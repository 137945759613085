import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { timer } from 'rxjs'
import { environment } from '../../../../../environments/environment'
import { ThankYouComponent } from '../../../../common/components/thank-you/thank-you.component'
import { BusinessMenuService } from '../../../../common/services/business/business-menu.service'
import { SiteRoutes } from '../../../../routes.const'
import { OrderService } from '../../../modules/order/order.service'
import { IContactForm, IContactFormOut } from '../contact.model'
import { ContactService } from '../contact.service'
import {
  selectBusinessDetails,
  selectSiteInitials,
} from './../../../../common/components/loader/reducers/index'
import { IBusiness } from './../../../../common/services/business/business.model'

@Component({
  selector: 'bi-contact-pizziria',
  templateUrl: './contact-pizziria.component.html',
  styleUrls: ['./contact-pizziria.component.less'],
})
export class ContactPizziriaComponent implements OnInit {
  title = ''
  business!: IBusiness
  isSending = false
  successText = ''
  errorText = ''
  staticFilesPath = ''
  deliveryOption = this.orderService.getDeliveryOption()

  constructor(
    private store: Store,
    private orderService: OrderService,
    private contactService: ContactService,
    private dialog: MatDialog,
    public router: Router,
    private businessMenuService: BusinessMenuService,
  ) {
    store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business) {
        this.business = business.business
        this.title = `צור קשר עם ${this.business.business_information.business_name_commercial} פיצה`
      }
    })

    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {}

  onContact(form: IContactForm) {
    const data: IContactFormOut = {
      data: form,
      to: 'business',
      business_id: this.business.business_id,
    }

    this.isSending = true
    this.contactService.sendContactUsForm(data).subscribe(
      () => {
        this.isSending = false
        this.successText = ''
        this.errorText = ''

        const dialogRef = this.dialog.open(ThankYouComponent, {
          disableClose: true,
          panelClass: 'bg-transparent',
        })
        timer(3000).subscribe(() => {
          dialogRef.close()
          this.router.navigate([SiteRoutes.Home])
        })
      },
      () => {
        this.isSending = false
        this.successText = ''
        this.errorText = 'אירעה שגיאה, נא לנסות לשלוח פנייה שוב'
      },
    )
  }

  goToFirstPage(): void {
    this.businessMenuService.goToFirstPage()
  }

  protected readonly SiteRoutes = SiteRoutes
}
