import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import { environment } from '../../../../../../environments/environment'
import { OrderService } from '../../../../../site/modules/order/order.service'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { selectSiteInitials } from '../../../loader/reducers'
import { CartActions } from '../../actions'
import {
  IProductDeal,
  IProductOther,
  IRemoveCartTopping,
} from './../../../../../site/modules/product/product.model'
import { BusinessMenuService } from './../../../../services/business/business-menu.service'

@Component({
  selector: 'bi-regular-product',
  templateUrl: './regular-product.component.html',
  styleUrls: ['./regular-product.component.less'],
})
export class RegularProductComponent implements OnInit {
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  staticFilesPath = ''

  @Input()
  product!: IProductOther

  @Input()
  inDeal = false

  @Input()
  index?: number

  @Output()
  onRemoveTopping: EventEmitter<Partial<IRemoveCartTopping>> = new EventEmitter()

  canDeleteItem!: boolean

  constructor(
    private store: Store,
    private orderService: OrderService,
    private businessMenuService: BusinessMenuService,
  ) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
    this.canDeleteItem = !this.inDeal
  }

  onRemove() {
    if (this.product) {
      if (this.product.isCoupon) {
        this.orderService.removeCoupon()
      }
      this.store.dispatch(
        CartActions.onRemoveProduct({ product: cloneDeep(this.product), index: this.index }),
      )
    }
  }

  removeTopping({ id, toppingIndex }: { id: string; toppingIndex: number }, categoryIndex: number) {
    this.onRemoveTopping.emit({ id, toppingIndex, categoryIndex })
  }

  getProductImage(product: IProductDeal | IProductOther) {
    if (product.picture === 'new_deal.png' || product.picture === null) {
      if (product.type_name == 'pizza') {
        return `${this.staticFilesPath}/${this.product.shape}Pizza.png`
      }
      return this.getDefaultImgPlaceholder(this.product)
    }

    if (!product.picture) {
      return `${environment.staticFiles}icons/${product.icon}.svg`
    }

    return product.imageUrl
  }
  getDefaultImgPlaceholder(product: IProductOther) {
    return this.businessMenuService.getDefaultImgPlaceholder(product)
  }
}
