<div class="categories-wrapper" *ngIf="siteData$ | ngrxPush as siteData">
  <div class="toppings-container" *ngIf="siteData.theme === 'light'">
    <span
      *ngIf="(isMobile && categories.length >= 2)  || categories.length >= 4"
      (click)="!isContinueDisabled && scroll(); !isContinueDisabled && changeCategory(currentIndex+1)"
      class="left"
      [style.--arrovImg]="'url(assets/' + businessName + '/images/arrow.svg)'"
    ></span>
    <span
      *ngIf="(isMobile && categories.length >= 2)  || categories.length >= 4"
      (click)="scroll(true); changeCategory(currentIndex-1) "
      class="right"
      [style.--arrovImg]="'url(assets/' + businessName + '/images/arrow.svg)'"
    ></span>
    <div id="topping-actions">
      <div class="topping-actions">
        <div
          class="topping-name"
          (click)="currentIndex > i && changeCategory(i)"
          *ngFor="let category of categories; let i = index"
          [class.active]="currentIndex === i"
          [class.selected]="categoryIsSelected(category)"
        >
          {{category.name}}
          <img
            *ngIf="businessName && categoryIsSelected(category)"
            [src]="'assets/' + businessName + '/images/item_done.svg'"
            class="item-done-adding"
          />
        </div>
      </div>
    </div>
  </div>
  <ngx-simplebar class="ngx-scrollable-bar" [options]="options">
    <div class="categories" [ngStyle]="categoryPosition">
      <div
        class="category"
        *ngFor="let category of categories; let i = index"
        [class.active]="currentIndex === i"
      >
        <ng-container [ngSwitch]="category.is_topping_divided">
          <ng-container *ngSwitchCase="true">
            <bi-divided-category [category]="category"></bi-divided-category>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <bi-not-divided-category [category]="category"></bi-not-divided-category>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ngx-simplebar>
</div>
