import { Location } from '@angular/common'
import { Component, DoCheck } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'bi-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.less'],
})
export class GoBackComponent implements DoCheck {
  hidden: boolean = true

  constructor(private activatedRoute: ActivatedRoute, private _location: Location) {}

  ngDoCheck() {
    this.hidden = this.activatedRoute.snapshot.queryParamMap.get('fromGroup') !== 'true'
  }

  goBack() {
    this._location.back()
  }
}
