<bi-card-standard >
  <div class="title" page-title>{{title}}</div>
  <div class="card-content box-padding" page-content [ngClass]="{'is-pickup': deliveryOption === 'pickup'}">
    <form class="card-form" name="cardForm" [formGroup]="detailsForm" (ngSubmit)="onSubmit()">
      <div class="card-details">
        <div class="client-details" [ngClass]="{'is-pickup': deliveryOption === 'pickup'}">
          <ng-template [ngIf]="isPickup" [ngIfElse]="notPickupTitle">
            <div class="section-header" *ngIf="business$|ngrxPush as business">
              <div class="pickup-from-business">איסוף מבית העסק בכתובת:</div>
              <div class="business-address">  <span>{{business.business.business_information.business_phone}} </span> | <span>{{business.business.business_information.business_address}}</span></div>
            </div>
          </ng-template>
          <ng-template #notPickupTitle>
            <div class="section-header">פרטים אישים</div>
          </ng-template>

          <div class="section-body">
            <bi-client-details
              [detailsForm]="detailsForm"
              [isPickup]="deliveryOption === 'pickup'"
            ></bi-client-details>
          </div>
        </div>

        <div class="address-details" *ngIf="deliveryOption === 'delivery'">
          <div class="section-header">כתובת</div>
          <div class="section-body">
            <bi-select-delivery-address [addressForm]="address"></bi-select-delivery-address>
          </div>
        </div>

        <div class="accept-terms checkbox-core">
          <div class="accept-container">
            <input
              type="checkbox"
              id="acceptTerms"
              [formControl]="acceptedTerms"
              name="accept_terms"
              class=""
            />
            <label for="acceptTerms">
            </label>
            <div (click)="openTermsConditions()" style="cursor: pointer">
              <span>מסכים&nbsp;</span>
              <span class="use-terms">לתנאי שימוש</span>
            </div>
          </div>
          <div class="accept-container " [ngStyle]="{'display':showPromo== true ? 'flex' : 'none' }">
            <input
              type="checkbox"
              id="in_advertising_list"
              [checked]="in_advertising_list.value"
              (change)="toggleCheckbox($event)"
              name="in_advertising_list"
              class=""
            />
            <label for="in_advertising_list" (click)="showPromoModal()">
            </label>
            <div style="text-decoration: none">
              <span class="use-terms" >אני רוצה שתשלחו לי קופוני מתנה והנחה </span>
            </div>
          </div>

          <div class="helper-container" *ngIf="deliveryOption === 'delivery' || isMobile">
            <div class="helper">* - שדה חובה</div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
          class="bi-btn bi-btn-ghost"
          type="submit"
          [disabled]="detailsForm.invalid || !acceptedTerms.value"
        >
          לתשלום
        </button>
      </div>
    </form>
  </div>
</bi-card-standard>
