import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { map, withLatestFrom } from 'rxjs/operators'
import { BusinessMenuService } from '../../../services/business/business-menu.service'
import { ProductsActions } from '../../products/actions'
import { CartActions } from '../actions'
import { CartService } from '../cart.service'
import { OrderService } from './../../../../site/modules/order/order.service'
import { selectProductToAdd } from './../../products/reducers/index'

@Injectable()
export class CartEffects {
  onDoneAddingProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onDoneAddingProduct),
      map(() => {
        this.cartService.addProductToCart()
        this.addAnimation()
        return CartActions.onUpdateCartTotal()
      }),
    ),
  )

  onDoneAddingDeal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onDoneAddingDeal),
      map(() => {
        this.cartService.addDealToCart()
        history.back()
        // this.businessMenuService.goToFirstPage()
        this.addAnimation()
        return CartActions.onUpdateCartTotal()
      }),
    ),
  )

  onUpdateCartTotal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onUpdateCartTotal),
      map(() => {
        this.orderService.updateTotal()
        const total = this.orderService.getTotal()
        return CartActions.onCartTotalUpdated({ total })
      }),
    ),
  )

  onCartTotalUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onCartTotalUpdated),
      map(() => {
        const cart = this.orderService.getOrder().cart
        return CartActions.onUpdateCartProducts({ cart })
      }),
    ),
  )

  // onUpdateCartProducts$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CartActions.onUpdateCartProducts),
  //     map(() => {
  //       return ProductsActions.onAddDeal({ deal: null })
  //     }),
  //   ),
  // )

  onClearCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onClearCart),
      map(() => {
        this.cartService.clearCart()
        return CartActions.onUpdateCartTotal()
      }),
    ),
  )

  onRemoveProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onRemoveProduct),
      map(({ product, index }) => {
        this.cartService.removeProductFromCart(product, index)
        return CartActions.onUpdateCartTotal()
      }),
    ),
  )

  onRemoveTopping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.onRemoveTopping),
      withLatestFrom(this.store.pipe(select(selectProductToAdd))),
      map(([data, product]) => {
        if (product) {
          return ProductsActions.onRemovePreAddTopping({ id: data.id })
        } else {
          this.cartService.removeToppingFromCart(data)
          return CartActions.onUpdateCartTotal()
        }
      }),
    ),
  )

  constructor(
    private actions$: Actions,
    private store: Store,
    private cartService: CartService,
    private orderService: OrderService,
    private businessMenuService: BusinessMenuService,
  ) {}
  addAnimation() {
    let productCard = document.querySelector('.cart-count') as HTMLElement
    if (productCard) {
      productCard.classList.add('added')
      setTimeout(() => {
        productCard.classList.remove('added')
      }, 500)
    }
  }
}
