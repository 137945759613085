import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { take } from 'rxjs/operators'
import { environment } from './../../../../environments/environment'
import { selectBusinessDetails, selectSiteInitials } from './../loader/reducers/index'
import { Observable } from 'rxjs'
import { ISiteInitials } from '../../initial-data/initial-data.model'

@Component({
  selector: 'bi-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.less'],
})
export class TermsAndConditionsComponent implements OnInit {
  filesPath = ''
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  constructor(private store: Store, private dialogRef: MatDialogRef<TermsAndConditionsComponent>) {
    store.pipe(take(1), select(selectBusinessDetails)).subscribe((business) => {
      if (business) {
        this.filesPath = environment.files
      }
    })
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close()
  }
}
