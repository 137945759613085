import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { quartersCountMap } from 'src/app/site/modules/product/products.const'
import {
  IProduct,
  IProductCategory,
  IProductOther,
  ToppingLocation,
} from '../../../../site/modules/product/product.model'
import { ProductsService } from '../../../../site/modules/product/products.service'
import { ToppingMethod } from '../../../services/business/business.model'
import { InfoModalComponent } from '../../info-modal/info-modal.component'
import { selectBusinessDetails } from '../../loader/reducers'
import { ProductsActions } from '../actions'
import {
  selectCategoryToAdd,
  selectDividedToppingToAdd,
  selectProductPreAdd,
  selectProductToAdd,
  selectToppingToAdd,
} from '../reducers'

@Injectable()
export class ToppingsEffects {
  onAddTopping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onAddTopping),
      withLatestFrom(
        this.store.pipe(select(selectToppingToAdd)),
        this.store.pipe(select(selectCategoryToAdd)),
        this.store.pipe(select(selectProductToAdd)),
        this.store.pipe(select(selectProductPreAdd)),
      ),
      filter(([{ index }]) => index !== null),
      map(([, topping, category, productToAdd, productPreAdd]) => {
        if (!productToAdd || category === null || topping === null) {
          return ProductsActions.noAction()
        }

        let product!: IProduct
        productPreAdd = this.productsService.mutateProduct(productPreAdd as IProductOther)
        productToAdd = this.productsService.mutateProduct(productToAdd as IProductOther)
        product = this.productsService.onAddTopping({
          productPreAdd,
          productToAdd,
          category,
          topping,
        })

        let currentCategoryId = productToAdd.categories[category].id
        let categoryIndex = productPreAdd.categories.findIndex((cat) => {
          return cat.id === currentCategoryId
        })

        if (productPreAdd && productPreAdd.categories && productPreAdd.categories[categoryIndex]) {
          this.shouldShowFixedPriceAlert(productPreAdd.categories[categoryIndex])
        }
        this.store.dispatch(ProductsActions.onUpdateAddProduct({ product: product }))
        return ProductsActions.onPreAddProduct({ productPreAdd: product })
      }),
    ),
  )

  onAddDividedTopping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onAddDividedTopping),
      withLatestFrom(
        this.store.pipe(select(selectDividedToppingToAdd)),
        this.store.pipe(select(selectCategoryToAdd)),
        this.store.pipe(select(selectProductToAdd)),
        this.store.pipe(select(selectProductPreAdd)),
      ),
      filter(([{ index }, topping]) => index !== null),
      map(([, topping, category, productToAdd, productPreAdd]) => {
        if (
          !productToAdd?.categories?.length ||
          category === null ||
          topping === null ||
          topping.location === null ||
          topping.index === null
        ) {
          return ProductsActions.noAction()
        }

        let product!: IProduct | null
        const { index, location } = topping
        productPreAdd = this.productsService.mutateProduct(productPreAdd as IProductOther)
        productToAdd = this.productsService.mutateProduct(productToAdd as IProductOther)
        product = this.productsService.onAddTopping({
          productPreAdd,
          productToAdd,
          category,
          topping: index,
          location,
        })

        this.lastToppting = topping
        if (product && product.categories && product.categories[category]) {
          this.shouldShowFixedPriceAlert(product.categories[category])
        }

        this.store.dispatch(ProductsActions.onUpdateAddProduct({ product }))
        return ProductsActions.onPreAddProduct({ productPreAdd: product })
      }),
    ),
  )

  shouldShowFixedPriceAlert(category: IProductCategory): void {
    if (!category.category_has_fixed_price) {
      return
    }

    const selectedProductsCount = this.productsService.getSelectetToppingsCount(category)

    let lastToppingCount = 0
    if (this.lastToppting?.location) {
      lastToppingCount = quartersCountMap[this.lastToppting.location] / 4
    }

    if (selectedProductsCount - lastToppingCount >= category.products_fixed_price) {
      return
    }

    // fixed price alert
    if (selectedProductsCount >= category.products_fixed_price) {
      this.dialogRef.open(InfoModalComponent, {
        height: '200px',
        width: '300px',
        panelClass: 'info-modal',
        data: {
          text:
            '  שים לב כי עברת את כמות התוספות שניתנות במחיר מיוחד\n' +
            'תוספות הבאות יופיעו במחיר שונה',
          okBtnText: 'הבנתי',
        },
      })
    }
  }

  onRemovePreAddTopping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.onRemovePreAddTopping),
      withLatestFrom(
        this.store.pipe(select(selectCategoryToAdd)),
        this.store.pipe(select(selectProductPreAdd)),
        this.store.pipe(select(selectBusinessDetails)),
        this.store.pipe(select(selectProductToAdd)),
      ),
      map(([{ id }, category, productPreAdd, business, productToAdd]) => {
        if (!productPreAdd || category === null || !business) {
          return ProductsActions.noAction()
        }

        const toppingMethod = business.business.topping_method
        productPreAdd = this.productsService.mutateProduct(productPreAdd as IProductOther)
        let currentCategoryIndex = category
        let currentCategory = productPreAdd.categories[currentCategoryIndex]
        if (productToAdd?.categories) {
          currentCategoryIndex = productPreAdd.categories.findIndex(
            (c) => c.id === productToAdd.categories[category].id,
          )
          currentCategory = productPreAdd.categories[currentCategoryIndex]
        }

        let index = -1
        if (currentCategory.category_has_fixed_price) {
          // toppings with original price
          index = currentCategory.products.findIndex(
            (p) => p.id === id && p.price === p.originalPrice,
          )
        }

        if (index === -1) {
          index = currentCategory.products.findIndex((p) => p.id === id)
        }

        if (index === -1) {
          return ProductsActions.noAction()
        }

        let count = currentCategory.products[index].count
        if (count != undefined && count > 1 && currentCategory.is_multiple_selection) {
          count--
          currentCategory.products[index].count = count
        } else {
          currentCategory.products.splice(index, 1)
        }

        // set fixed price
        if (currentCategory.category_has_fixed_price) {
          const toppingsWithFixedPriceCount = currentCategory.products.reduce((count, topping) => {
            if (topping.price !== topping.originalPrice) {
              return count + (topping.count ?? 0)
            }
            return count
          }, 0)

          if (toppingsWithFixedPriceCount < currentCategory.products_fixed_price) {
            // find topping with original price and separate it
            const index = currentCategory.products.findIndex(
              ({ price, originalPrice }) => price === originalPrice,
            )
            if (index !== -1) {
              let { count } = currentCategory.products[index]
              if (count && count > 1) {
                count--
                currentCategory.products[index].count = count
                const newTopping = cloneDeep(currentCategory.products[index])
                newTopping.count = 1
                newTopping.price = currentCategory.fixed_price
                currentCategory.products.push(newTopping)
              } else {
                // if has same topping with fixed price
                const id = currentCategory.products[index].id
                const sameItemIndex = currentCategory.products.findIndex((p) => {
                  return p.id === id && p.price !== p.originalPrice
                })

                if (sameItemIndex !== -1) {
                  let { count } = currentCategory.products[sameItemIndex]
                  if (count) {
                    count++
                  }
                  currentCategory.products[sameItemIndex].count = count
                  currentCategory.products.splice(index, 1)
                } else {
                  currentCategory.products[index].count = 1
                  currentCategory.products[index].price = currentCategory.fixed_price
                }
              }
            }
          }
        }

        if (toppingMethod === ToppingMethod.ByLayer && currentCategory.is_topping_divided) {
          this.productsService.setLayersPrice(currentCategory)
        }

        this.store.dispatch(ProductsActions.onUpdateAddProduct({ product: productPreAdd }))
        return ProductsActions.onPreAddProduct({ productPreAdd })
      }),
    ),
  )

  private lastToppting!: { location: ToppingLocation | null; index: number | null }

  constructor(
    private actions$: Actions,
    private store: Store,
    private productsService: ProductsService,
    private dialogRef: MatDialog,
  ) {}
}
