import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { SiteRoutes } from './../../../routes.const'

@Component({
  selector: 'bi-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.less'],
})
export class ThankYouComponent implements OnInit {
  constructor(private router: Router, private dialogRef: MatDialogRef<ThankYouComponent>) {}

  ngOnInit(): void {}

  onBtnClick() {
    this.dialogRef.close()
    this.router.navigate([SiteRoutes.Home])
  }
}
