import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { environment } from '../../../../environments/environment'
import { SiteRoutes } from '../../../routes.const'
import {
  IProductDeal,
  IProductOther,
  ProductType,
} from '../../../site/modules/product/product.model'
import { INavItems } from '../../components/header/nav-menu.model'
import { InitSiteService } from '../init-site/init-site.service'
import { selectBusinessDetails, selectIsNewMenu } from './../../components/loader/reducers/index'
import { MobileDetectorService } from './../mobile-detector/mobile-detector.service'
import { IBusinessResponse } from './business.model'

@Injectable({
  providedIn: 'root',
})
export class BusinessMenuService {
  isMobile = false
  isNewMenu = false
  businessDetails!: IBusinessResponse

  constructor(
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private initSiteService: InitSiteService,
    private router: Router,
  ) {
    this.isMobile = this.mobileDetectorService.isMobile()
    this.store.pipe(select(selectIsNewMenu)).subscribe((isNewMenu) => {
      this.isNewMenu = isNewMenu
    })
    this.store.pipe(select(selectBusinessDetails)).subscribe((details) => {
      this.businessDetails = details as IBusinessResponse
    })
  }

  goToFirstPage(): void {
    if (this.isNewMenu && !this.isMobile) {
      this.goToItemMenu(this.businessDetails.business.menuDesktop[0], this.isMobile)
    } else if (this.isNewMenu && this.isMobile) {
      this.goToItemMenu(this.businessDetails.business.menuMobile[0], this.isMobile)
    } else {
      this.router.navigate([`${SiteRoutes.Pizza}/${SiteRoutes.DealsSelect}`])
    }
  }

  getDefaultImgPlaceholder({ type_name, icon }: Partial<IProductDeal | IProductOther>) {
    if (this.isNewMenu) {
      return `${environment.staticFiles}icons/${icon}.svg`
    }
    const path = `assets/${this.initSiteService.selectSiteInitials().name}/images/`

    switch (type_name) {
      case ProductType.pizza:
        return `${path}pizza.svg`
      case ProductType.drink:
        return `${path}drink.svg`
      default:
        return `${path}deal.svg`
    }
  }

  private goToItemMenu(item: INavItems, isMobile: boolean): void {
    if (item.children?.length) {
      if (isMobile) this.router.navigate([`${SiteRoutes.General}/group/null/${item.id}`])
      else
        this.router.navigate([
          `${SiteRoutes.General}/${item.children[0].type_name}/${item.children[0].product_type_id}`,
        ])
    } else {
      this.router.navigate([`${SiteRoutes.General}/${item.type_name}/${item.product_type_id}`])
    }
  }
}
