import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { selectSiteInitials } from '../../components/loader/reducers'
import { siteInitailMap } from '../../initial-data/initial-data'
import { ISiteInitials } from '../../initial-data/initial-data.model'
import { environment } from './../../../../environments/environment'

@Injectable()
export class InitSiteService {
  constructor(@Inject(DOCUMENT) private document: Document, private store: Store) {}

  getSiteInitials(): ISiteInitials | undefined {
    let host = this.document.location.host.replace('www.', '')
    // if (!environment.production && !environment.development) {
    //   host = 'localhost'
    // }
    return siteInitailMap.get(host)
  }

  selectSiteInitials(): ISiteInitials {
    let site: ISiteInitials = {} as ISiteInitials
    this.store.pipe(select(selectSiteInitials)).subscribe((business) => {
      site = business
    })
    return site
  }
}
