<bi-card-standard>
  <div class="card-content" page-content>
    <div class="content-wrapper">
      <div class="title text-center" page-title>אימות מספר טלפון</div>
      <div class="no-phone" *ngIf="!phone">חסר מספר טלפון</div>

      <div class="" *ngIf="phone">
        <div class="sending-code" *ngIf="isSending"> שולח קוד אימות לטפון {{phone}} </div>
        <div class="code-sent" *ngIf="isSent">
          <div class="title">
            <div> קוד אימות נשלח לטלפון {{phone}}</div>
            <div>נא להזין את קוד האימות</div>
          </div>

          <div class="code-form">
            <input type="text" [formControl]="confirmationCode" (keyup.enter)="onAddConfirmCode()" required placeholder="קוד אימות" />
            <div class="helper">שדה חובה</div>
            <div class="error-code" *ngIf="isErrorCode">לא הצלחנו לאמת את הקוד</div>
          </div>

          <div class="action">
            <button
              class="bi-btn bi-btn-ghost full"
              [disabled]="!confirmationCode.value || isConfirming"
              (click)="onAddConfirmCode()"
            >
              המשך
            </button>
          </div>
        </div>
        <div class='text-center resend-code' (click)="resendCode()">שלח קוד חדש</div>
        <div class="code-error" *ngIf="isError">אירעה שגיאה, נסה שוב</div>
      </div>
    </div>
  </div>
</bi-card-standard>
