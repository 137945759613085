import { Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { map, withLatestFrom } from 'rxjs/operators'
import { environment } from '../../../../../../../../environments/environment'
import {selectBusinessDetails, selectSiteInitials} from '../../../../../loader/reducers'
import { ProductsActions } from '../../../../actions'
import { selectCategoryToAdd } from '../../../../reducers'
import { selectDealItemToAdd } from './../../../../reducers/index'
import {ActivatedRoute} from '@angular/router'
import {Observable} from "rxjs";
import {IBusinessResponse} from "../../../../../../services/business/business.model";

@Component({
  selector: 'bi-category-title',
  templateUrl: './category-title.component.html',
  styleUrls: ['./category-title.component.less'],
})
export class CategoryTitleComponent implements OnInit {
  @Input()
  name!: string

  @Input()
  extraName = ''

  @Input()
  showBackBtn = true

  backIcon = 'back.png'
  staticFilesPath = ''

  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  currentItemName = ''

  showBackBtn$ = this.store.pipe(
    select(selectCategoryToAdd),
    withLatestFrom(this.store.pipe(select(selectDealItemToAdd))),
    map(([category, item]) => {
      return !!item || !!category
    }),
  )

  constructor(private store: Store, private router: ActivatedRoute,) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      if (params) {
        this.business$.subscribe((businessDetails) => {
          if (!businessDetails) {
            this.currentItemName = this.name
            return
          }
          const currentItem = businessDetails.business.businessProductTypes.find(i => i.id === params.id)
          this.currentItemName = currentItem ? currentItem.name : this.name
        })
      }
    })
  }

  categoryBack() {
    this.store.dispatch(ProductsActions.onCategoryBack())
  }
}
