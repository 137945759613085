import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from './../../../../environments/environment'
import { IContactFormOut } from './contact.model'

@Injectable()
export class ContactService {
  constructor(private http: HttpClient) {}

  sendContactUsForm(form: IContactFormOut) {
    const params = new HttpParams().set('apiCtrl', 'pizziria').set('do', 'contactUs')
    return this.http.post(environment.api1, form, { params })
  }
}
