import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { IProductCategory } from 'src/app/site/modules/product/product.model'
import { SiteRoutes } from '../../../../routes.const'
import { MobileDetectorService } from '../../../services/mobile-detector/mobile-detector.service'
import { selectProductPreAdd } from '../../products/reducers/index'
import { AbstractAddProducts } from '../abstract-add-products'
import { ProductsActions } from '../actions'
import { ProductsService } from './../../../../site/modules/product/products.service'

@Component({
  selector: 'bi-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products_new.component.less'],
})
export class ProductsComponent extends AbstractAddProducts implements OnInit {
  isContinueDisabled = false

  categories!: IProductCategory[]

  private currentIndex = 0
  constructor(
    protected store: Store,
    protected mobileDetectorService: MobileDetectorService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected productsService: ProductsService,
    protected router: Router,
  ) {
    super(store, mobileDetectorService, changeDetectorRef, productsService)
  }

  ngOnInit(): void {
    if (!this.type) {
      console.warn('@type input should be specified')
      return
    }

    this.init()
  }

  onContinueClick(): void {
    this.store.dispatch(ProductsActions.onPageContinue())
  }

  validateContinueBtn() {
    this.isContinueDisabled = true
    this.store.pipe(select(selectProductPreAdd)).subscribe((product) => {
      if (product && product.categories) {
        product.categories.forEach((category) => {
          if (!category.is_mandatory || (category.is_mandatory && category.products.length > 0)) {
            this.isContinueDisabled = false
          }
        })
      }
    })
    return this.isContinueDisabled
  }

  private init() {
    this.initTitle()
    this.subscribeToAddProduct()
    if (this.isMobile) {
      this.subscribeToCategoryChanges()
    }
  }

  onNextPage() {
    let route = this.router.url.substr(1, this.router.url.length)
    switch (route) {
      case SiteRoutes.PizzaSelect:
        this.router.navigate([SiteRoutes.Drinks])
        break
      case SiteRoutes.Drinks:
        this.router.navigate([SiteRoutes.AdditionalOffer])
        break
      case SiteRoutes.AdditionalOffer:
        this.router.navigate([SiteRoutes.Cart])
        break
    }
  }
}
