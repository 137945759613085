<div class="loader-container" *ngIf="isDealPage && (isSiteInitailsLoading$ | ngrxPush)">
  <div class="logo-container">
    <img [src]="staticImagesPath + '/logo.png'" alt="לוגו" />
  </div>
  <div class="welcome-container">ברוכים הבאים</div>
  <div class="welcome-loading">טוען נתונים</div>
  <div class="welcome-spinner">
    <bi-spinner></bi-spinner>
  </div>
</div>
