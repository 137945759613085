import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component'
import { CanLeavePageGuard } from './common/guards/can-leave-page.guard'
import { SiteRoutes } from './routes.const'
import { AboutComponent } from './site/alikes/about/about.component'
import { AddressAndNotesComponent } from './site/alikes/address-and-notes/address-and-notes.component'
import { ConfirmCashPaymentComponent } from './site/alikes/confirm-cash-payment/confirm-cash-payment.component'
import { ContactBringitComponent } from './site/alikes/contact/contact-bringit/contact-bringit.component'
import { ContactPizziriaComponent } from './site/alikes/contact/contact-pizziria/contact-pizziria.component'
import { SelectPaymentComponent } from './site/alikes/select-payment/select-payment.component'
import { SuggestImprovmentsComponent } from './site/alikes/suggest-improvments/suggest-improvments.component'
import { CartPageComponent } from './site/cart-page/cart-page.component'
import { AndroidComponent } from './site/mobile-apps/android/android.component'
import { IosComponent } from './site/mobile-apps/ios/ios.component'
import { UnsubscribeAdsComponent } from './site/unsubscribe-ads/unsubscribe-ads.component'

const routes: Routes = [
  { path: '', loadChildren: () => import('./site/home/home.module').then((m) => m.HomeModule) },
  {
    path: SiteRoutes.General,
    loadChildren: () =>
      import('./site/product-workspace/product-workspace.module').then(
        (m) => m.ProductWorkspaceModule,
      ),
    canDeactivate: [CanLeavePageGuard],
  },
  {
    path: SiteRoutes.Pizza,
    loadChildren: () => import('./site/deals/deals.module').then((m) => m.DealsModule),
  },
  { path: SiteRoutes.SuggestImporve, component: SuggestImprovmentsComponent },
  {
    path: SiteRoutes.Drinks,
    loadChildren: () => import('./site/drinks/drinks.module').then((m) => m.DrinksModule),
    canDeactivate: [CanLeavePageGuard],
  },
  {
    path: SiteRoutes.AdditionalOffer,
    loadChildren: () =>
      import('./site/additional-offer/additional-offer.module').then(
        (m) => m.AdditionalOfferModule,
      ),
    canDeactivate: [CanLeavePageGuard],
  },
  {
    path: SiteRoutes.AdditionalOffer + '/:id',
    loadChildren: () =>
      import('./site/additional-offer/additional-offer.module').then(
        (m) => m.AdditionalOfferModule,
      ),
    canDeactivate: [CanLeavePageGuard],
  },
  {
    path: SiteRoutes.AddPizza,
    loadChildren: () => import('./site/add-pizza/add-pizza.module').then((m) => m.AddPizzaModule),
    canDeactivate: [CanLeavePageGuard],
  },
  {
    path: SiteRoutes.AddDeal,
    loadChildren: () => import('./site/add-deal/add-deal.module').then((m) => m.AddDealModule),
    canDeactivate: [CanLeavePageGuard],
  },
  { path: SiteRoutes.Cart, component: CartPageComponent },
  {
    path: SiteRoutes.About,
    component: AboutComponent,
  },
  {
    path: SiteRoutes.Notes,
    component: AddressAndNotesComponent,
  },
  {
    path: SiteRoutes.Payment,
    component: SelectPaymentComponent,
  },
  {
    path: SiteRoutes.ConfirmCash,
    component: ConfirmCashPaymentComponent,
  },
  {
    path: SiteRoutes.ContactPizziria,
    component: ContactPizziriaComponent,
  },
  {
    path: SiteRoutes.ContactBringit,
    component: ContactBringitComponent,
  },
  {
    path: SiteRoutes.Tracker,
    loadChildren: () =>
      import('./site/order-tracker/order-tracker.module').then((m) => m.OrderTrackerModule),
  },
  {
    path: SiteRoutes.AndroidApp,
    component: AndroidComponent,
  },
  {
    path: SiteRoutes.IOSApp,
    component: IosComponent,
  },
  {
    path: SiteRoutes.UnsubscribeAds,
    component: UnsubscribeAdsComponent,
  },
  { path: '**', component: PageNotFoundComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class SiteRoutingModule {}
