import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { SiteRoutes } from '../../../routes.const'
import {
  IProduct,
  IToppingProduct,
  productNameTranslate,
  ProductType,
} from '../../../site/modules/product/product.model'
import { ISiteInitials } from '../../initial-data/initial-data.model'
import { IBusinessResponse } from '../../services/business/business.model'
import { MobileDetectorService } from '../../services/mobile-detector/mobile-detector.service'
import { selectBusinessDetails, selectSiteInitials } from '../loader/reducers'
import { IProductOther } from './../../../site/modules/product/product.model'
import { ProductsService } from './../../../site/modules/product/products.service'
import { Subscriber } from './../../classes/subscriber/subscriber'
import { ProductsActions } from './actions'
import {
  selectCategoryToAdd,
  selectDealItemToAdd,
  selectDealPreAdd,
  selectProductToAdd,
} from './reducers'

@Component({ template: '' })
export abstract class AbstractAddProducts extends Subscriber {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  @Input()
  products: IProduct[] = []

  @Input()
  type?: ProductType

  productType = ProductType
  title = ''
  categoryName = ''
  showProductToAdd = false
  isMobile: boolean
  staticFilesPath = ''
  showBackBtn = false
  siteData?: ISiteInitials | null

  productToAdd$: Observable<IProduct | null> = this.store.pipe(
    select(selectProductToAdd),
    withLatestFrom(
      this.store.pipe(select(selectDealPreAdd)),
      this.store.pipe(select(selectDealItemToAdd)),
    ),
    map(([product, deal, index]) => {
      if (product && deal && index !== null) {
        const item =
          deal.items[index] || deal.items.find((item) => item.type_id === product?.type_id)
        if (item) {
          const theProduct = item.products.find((p) => p.id === product?.id)
          if (theProduct) {
            product = this.productsService.mutateProduct(product as IProductOther)
            product.count = theProduct.count
            theProduct.categories.forEach((theCategory) => {
              const category = product?.categories?.find((c) => c.id === theCategory.id)
              if (category) {
                // join toppings with the same name
                const toppings: Record<string, Partial<IToppingProduct>> = {}
                theCategory.products.forEach((theTopping) => {
                  if (!toppings[theTopping.name]) {
                    toppings[theTopping.name] = {
                      name: theTopping.name,
                      selected: theTopping.selected,
                      location: theTopping.location,
                      count: 0,
                    }
                  }
                  toppings[theTopping.name].count =
                    (toppings[theTopping.name].count ?? 0) + (theTopping.count ?? 0)
                })

                Object.values(toppings).forEach((theTopping) => {
                  const topping = category.products.find((t) => t.name === theTopping.name)
                  if (topping) {
                    topping.selected = theTopping.selected
                    topping.location = theTopping.location
                    topping.count = theTopping.count
                  }
                })
              }
            })
          }
        }
      }
      return product
    }),
  )

  constructor(
    protected store: Store,
    protected mobileDetectorService: MobileDetectorService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected productsService: ProductsService,
    protected router?: Router,
  ) {
    super()
    this.isMobile = mobileDetectorService.isMobile()
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })

    this.siteData$.subscribe((data) => (this.siteData = data))
  }

  categoryBack() {
    this.store.dispatch(ProductsActions.onCategoryBack())
  }

  protected subscribeToAddProduct() {
    this.store
      .pipe(takeUntil(this.ngUnsubscribe), select(selectProductToAdd))
      .subscribe((product) => {
        if (this.siteData?.theme === 'light' && this.router?.url !== '/' + SiteRoutes.AddDeal) {
          this.showProductToAdd = false
          return
        }
        if (product === null || product === undefined) {
          this.showProductToAdd = false
          this.initTitle()
          return
        }

        if (product && !product.categories?.length) {
          this.store.pipe(select(selectDealItemToAdd), take(1)).subscribe((index) => {
            if (index !== null) {
              this.products = [product]
            }

            this.showProductToAdd = false
            this.initTitle()
          })
        } else {
          this.showProductToAdd = true
          this.title = product.name
        }
      })
  }

  protected initTitle() {
    if (this.type) {
      this.title = productNameTranslate[this.type]
      this.categoryName = ''
    }
  }

  protected subscribeToCategoryChanges() {
    this.store
      .pipe(
        takeUntil(this.ngUnsubscribe),
        select(selectCategoryToAdd),
        withLatestFrom(this.productToAdd$),
      )
      .subscribe(([categoryIndex, product]) => {
        if (categoryIndex !== null && product?.categories) {
          const name = product.categories[categoryIndex].name
          this.categoryName = name
          this.showBackBtn = true
          this.changeDetectorRef.detectChanges()
        } else {
          this.showBackBtn = false
        }
      })
  }
}
