<div class="page-container">
  <div class="content-container">
    <div class="wrapper-container">
      <div class="wrapper">
        <bi-cart [isFinal]="true" [showMinOrderError]="showMinOrderError"></bi-cart>
      </div>

      <div class="cart-actions" *ngIf="0">
        <div class="action">
          <div class="to-pay-btn" *ngIf="cartProducts$ | ngrxPush as cartProducts">
            <button class="bi-btn full" [disabled]="!cartProducts.length" (click)="onGoToPay()">
              לתשלום
            </button>
          </div>
        </div>
        <div class="action">
          <button class="bi-btn bi-btn-ghost full" (click)="onClearCart()">רוקן עגלה</button>
        </div>
      </div>
    </div>
  </div>
</div>
