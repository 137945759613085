import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { InitSiteService } from '../../../../services/init-site/init-site.service'
import { MobileDetectorService } from '../../../../services/mobile-detector/mobile-detector.service'
import { selectSiteInitials } from '../../../loader/reducers'
import { ProductsActions } from '../../actions'
import { IProductCategory } from './../../../../../site/modules/product/product.model'
import { Subscriber } from './../../../../classes/subscriber/subscriber'
import {
  selectCategoryToAdd,
  selectIsCategoryContinueDisabled,
  selectProductPreAdd,
} from './../../reducers/index'

@Component({
  selector: 'bi-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.less'],
})
export class CategoriesComponent extends Subscriber implements OnInit {
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  @Input()
  categories: IProductCategory[] = []

  currentIndex = 0
  private currentIndex$ = this.store.pipe(select(selectCategoryToAdd))
  categoryPosition: any = {}
  isContinueDisabled$: Observable<boolean> = this.store.pipe(
    select(selectIsCategoryContinueDisabled),
  )
  isContinueDisabled = false
  businessName: string
  isMobile: boolean
  options = { autoHide: false, direction: 'rtl', scrollX: false }

  constructor(
    private store: Store,
    private initSiteService: InitSiteService,
    private mobileDetectorService: MobileDetectorService,
  ) {
    super()
    const { name } = initSiteService.selectSiteInitials()
    this.businessName = name
    let wasNull = true
    this.isContinueDisabled$.subscribe((isDisabled) => {
      this.isContinueDisabled = isDisabled
    })
    this.store.pipe(select(selectProductPreAdd), takeUntil(this.ngUnsubscribe)).subscribe((p) => {
      if (p === null) {
        wasNull = true
      } else if (wasNull) {
        wasNull = false
        this.updateSelectedCategory(0)
      }
    })
    this.isMobile = this.mobileDetectorService.isMobile()
  }

  ngOnInit(): void {
    this.subscribeToCategoryChanges()
    if (this.categories.length > 1) {
      this.categoryPosition = { transform: `translateX(200%)`, display: 'none', transition: '0.3s' }
      setTimeout(() => {
        this.categoryPosition = { transform: `translateX(0%)` }
      }, 300)
    }
  }

  ngOnChanges({ categories }: SimpleChanges) {
    if (categories) {
      // this.validateContinueBtn()
    }
  }

  private subscribeToCategoryChanges() {
    this.currentIndex$.subscribe((index) => {
      if (index !== null) {
        this.currentIndex = index
        // this.categoryPosition = {
        //   transform: `translateX(${this.currentIndex * 100}%)`,
        //   'transition' : '0.3s'
        // }
        // this.validateContinueBtn()
      }
    })
  }

  private updateSelectedCategory(index: number | null) {
    this.store.dispatch(ProductsActions.onSelectCategoy({ index }))
  }

  private get selectedToppingsCount(): number {
    return this.categories[this.currentIndex].products.reduce(
      (acc, product) => acc + (product.selected ? 1 : 0),
      0,
    )
  }

  private get hasSelectedToppings(): boolean {
    return this.categories[this.currentIndex].products.some((product) => product.selected)
  }

  private validateContinueBtn() {
    this.isContinueDisabled = false
    if (
      this.categories[this.currentIndex] &&
      this.categories[this.currentIndex].is_mandatory &&
      !this.hasSelectedToppings
    ) {
      this.isContinueDisabled = true
    }
  }

  changeCategory(i: number) {
    let toppingActions = document.getElementById('topping-actions') as HTMLElement
    if (i !== this.categories.length && i > 0) {
      this.updateSelectedCategory(i)
    } else {
      this.updateSelectedCategory(0)
      this.categoryPosition = {
        transform: `translateX(0%)`,
      }
      toppingActions.scrollLeft = 0
    }
  }

  scroll(reverse = false) {
    let toppingActions = document.getElementById('topping-actions')
    if (toppingActions) {
      let left = reverse ? toppingActions.scrollLeft + 175 : toppingActions.scrollLeft + -175
      toppingActions.scroll({ left, behavior: 'smooth' })
    }
  }

  categoryIsSelected(category: IProductCategory) {
    if (!category.is_multiple_selection) {
      return category.products.map((item) => item.selected).includes(true)
    } else {
      return category.products.filter((item) => item.count).length > 0
    }
  }
}
