import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { combineLatest } from 'rxjs'
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import {
  IProduct,
  IProductDeal,
  IProductOther,
  ProductShape,
  ProductType,
} from '../../../../site/modules/product/product.model'
import { ProductsService } from '../../../../site/modules/product/products.service'
import { InitSiteService } from '../../../services/init-site/init-site.service'
import { MobileDetectorService } from '../../../services/mobile-detector/mobile-detector.service'
import { selectIsNewMenu } from '../../loader/reducers'
import { AbstractAddProducts } from '../abstract-add-products'
import { ProductsActions } from '../actions'
import * as fromProducts from '../reducers'
import { selectDealItemToAdd, selectDealPreAdd, selectDealToAdd } from '../reducers'
import { BusinessMenuService } from './../../../services/business/business-menu.service'

@Component({
  selector: 'bi-deal-product',
  templateUrl: './deal-product.component.html',
  styleUrls: ['./deal-product.component.less'],
})
export class DealProductComponent extends AbstractAddProducts implements OnInit {
  selectedProductIndices: { [key: string]: number } = {}

  @Input()
  deal!: IProductDeal

  product: IProduct | null | undefined

  backIcon = 'back'
  businessName = ''
  status = false
  isNewMenu = false

  product$ = this.store.pipe(select(fromProducts.selectDealPreAddProduct))
  dealStep$ = this.store.pipe(select(fromProducts.selectDealStep))
  $itemDeal = this.store.pipe(select(selectDealItemToAdd))
  $dealPreAdd = this.store.pipe(select(selectDealPreAdd))
  deal$ = this.store.pipe(select(selectDealToAdd), take(1))

  dealImgPlaceholder$ = combineLatest([
    this.product$,
    this.dealStep$,
    this.$itemDeal,
    this.$dealPreAdd,
  ]).pipe(
    map(([product, , dealItemIndex, dealPreAdd]) => {
      this.product = product
      if (this.product) {
        if (this.product.picture == 'new_deal.png' || !this.product.picture) {
          return this.getDefaultImgPlaceholder(this.product)
        }
        if (this.product.imageUrl || this.product.imageUrl == '') {
          if (this.product.shape) {
            const shape =
              this.product.shape === ProductShape.Sliced ? ProductShape.Circle : this.product.shape
            return `${environment.staticFiles}${this.siteData?.name}/${shape}Pizza.png`
          }
          return this.product.imageUrl
        }
      }
      return this.getDefaultImgPlaceholder({
        type_name: this.type ?? ProductType.pizza,
        icon: this.type,
      })
    }),
    takeUntil(this.ngUnsubscribe),
  )

  getDefaultImgPlaceholder(product: Partial<IProductDeal | IProductOther>) {
    return this.businessMenuService.getDefaultImgPlaceholder(product)
  }

  constructor(
    protected store: Store,
    protected mobileDetectorService: MobileDetectorService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected productsService: ProductsService,
    private initSiteService: InitSiteService,
    private businessMenuService: BusinessMenuService,
    protected router: Router,
  ) {
    super(store, mobileDetectorService, changeDetectorRef, productsService, router)
    const { name } = initSiteService.selectSiteInitials()
    this.businessName = name
    this.store
      .pipe(select(selectIsNewMenu), takeUntil(this.ngUnsubscribe))
      .subscribe((isNewMenu) => {
        this.isNewMenu = isNewMenu
      })
    this.siteData$.subscribe((data) => (this.siteData = data))
  }

  ngOnInit(): void {
    this.selectedProductIndices = {
      pizza: 0,
      drink: 0,
      additionalOffer: 0,
    }
    if (!this.deal) {
      return
    }
    this.init()
    this.store.dispatch(ProductsActions.onSelectDealItem({ index: 0 }))
    this.subscribeToDealStep()
  }

  private subscribeToDealStep() {
    this.store
      .pipe(
        takeUntil(this.ngUnsubscribe),
        select(fromProducts.selectDealStep),
        withLatestFrom(
          this.store.pipe(select(fromProducts.selectDealItemToAdd)),
          this.store.pipe(select(fromProducts.selectDealPreAdd)),
          this.store.pipe(select(fromProducts.selectDealPreAddProduct)),
        ),
      )
      .subscribe(([step, index, dealPreAdd, dealPreAddProduct]) => {
        if (index === null || step === -1) {
          return
        }

        // TODO: check business type/theme
        const i = Math.floor(step / 2)
        if (!this.deal.items[i]) {
          return
        }
        this.type = this.deal.items[i].type_name
        if (step % 2 === 0) {
          // show products list
          this.showItemsList(i)
          // console.log(this.deal.items[i])
          if (dealPreAdd?.items[i]?.products[0]?.id) {
            const indec = this.products.findIndex(
              (pizza) => pizza?.id === dealPreAdd?.items[i]?.products[0]?.id,
            )
            this.toggleSelected(this.type, indec)
          } else {
            this.toggleSelected(this.product?.type_name, 0)
          }
        } else {
          // show product to add
          this.showDealItem(i, dealPreAdd, dealPreAddProduct)
        }
      })
  }

  private showItemsList(index: number) {
    this.showProductToAdd = false
    this.initTitle()
    ProductsActions.onAddProduct({ product: null })
    this.products = this.deal.items[index].products
  }

  private showDealItem(
    index: number,
    dealPreAdd: IProductDeal | null,
    dealPreAddProduct: IProduct | null,
  ) {
    let deal = cloneDeep(dealPreAdd)
    let productToAdd = dealPreAddProduct
    if (!!deal?.items[index]?.products.length) {
      let productPreAdd: IProduct
      if (dealPreAddProduct) {
        const preProd = this.productsService.mutateProduct(
          cloneDeep(dealPreAddProduct) as IProductOther,
        )
        // preProd.categories = []
        productPreAdd = preProd
      } else {
        productPreAdd = deal?.items[index].products[0]
        this.toggleSelected(this.product?.type_name, 0)
      }

      productToAdd = this.deal.items[index].products.find(
        (p) => p.id === productPreAdd.id,
      ) as IProductOther
      this.store.dispatch(ProductsActions.onAddProduct({ product: productToAdd }))
      productToAdd = this.productsService.mutateProduct(productToAdd)
      productPreAdd = this.productsService.mutateProduct(productPreAdd as IProductOther)
      this.productsService.compareToppingsPreAdd(productPreAdd, productToAdd as IProduct)
    }
  }

  onNextDealItem() {
    this.store.dispatch(ProductsActions.onAddProductToDeal())
  }

  private init() {
    this.type = this.deal.items[0].type_name
    this.initTitle()
    this.subscribeToAddProduct()
  }

  onBackClick() {
    this.toggleSelected(this.product?.type_name, 0)
    if (this.showProductToAdd) {
      this.showProductToAdd = false
    }
    this.store.dispatch(ProductsActions.onCategoryBack())
  }

  toggleSelected(type: any, index: number) {
    this.selectedProductIndices[type] = index
  }
  onNextClick() {
    this.store.dispatch(ProductsActions.onPageContinue())
  }
}
