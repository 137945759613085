import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {select, Store} from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { IProductDeal } from '../../../../../site/modules/product/product.model'
import { CartActions } from '../../actions'
import { IRemoveCartTopping } from './../../../../../site/modules/product/product.model'
import {Observable} from "rxjs";
import {IBusinessResponse} from "../../../../services/business/business.model";
import {selectBusinessDetails, selectSiteInitials} from "../../../loader/reducers";
import {environment} from "../../../../../../environments/environment";
import {ISiteInitials} from "../../../../initial-data/initial-data.model";

@Component({
  selector: 'bi-deal-in-cart',
  templateUrl: './deal-in-cart.component.html',
  styleUrls: ['./deal-in-cart.component.less'],
})
export class DealInCartComponent implements OnInit {
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  staticFilesPath = ''

  @Input()
  deal!: IProductDeal

  @Output()
  onRemoveTopping: EventEmitter<IRemoveCartTopping> = new EventEmitter()

  constructor(private store: Store) {
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
  }

  onRemove() {
    if (this.deal) {
      this.store.dispatch(CartActions.onRemoveProduct({ product: cloneDeep(this.deal) }))
    }
  }
}
