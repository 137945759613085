import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { BusinessApiService } from 'src/app/common/services/business/business.api.service'
import { InfoModalComponent } from '../../../common/components/info-modal/info-modal.component'
import { memoizeObservable } from '../decorators/memoize-observable/memoizeObservable.decorator'
import { environment } from './../../../../environments/environment'
import { selectBusinessDetails } from './../../../common/components/loader/reducers'
import {
  IBusinessResponse,
  ToppingMethod,
} from './../../../common/services/business/business.model'
import { IOrder } from './../order/order.model'
import { OrderService } from './../order/order.service'
import {
  IProduct,
  IProductCategory,
  IProductDeal,
  IProductOther,
  IProductsResponse,
  IToppingLayer,
  IToppingProduct,
  ProductType,
  ToppingLocation,
} from './product.model'
import { quartersCountMap, transformProductsIn } from './products.const'

@Injectable()
export class ProductsService {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))

  constructor(
    private http: HttpClient,
    private store: Store,
    private orderService: OrderService,
    private dialogRef: MatDialog,
  ) {}

  @memoizeObservable()
  getProducts(type: string): Observable<IProduct[]> {
    const order: IOrder = this.orderService.getOrder()
    return this.business$.pipe(
      filter((business) => !!business),
      mergeMap((business: IBusinessResponse | null) => {
        const id = business?.business.business_id
        return this.http.get<IProductsResponse>(`${environment.api2}products/${type}/${id}`).pipe(
          map((response: IProductsResponse) => response.products),
          map((products: IProduct[]) => transformProductsIn(products, order.deliveryOption)),
        )
      }),
    )
  }

  @memoizeObservable()
  getNewGeneralProducts(type: string, productId: string): Observable<IProduct[]> {
    const order: IOrder = this.orderService.getOrder()
    return this.business$.pipe(
      filter((business) => !!business),
      mergeMap((business: IBusinessResponse | null) => {
        const id = business?.business.business_id
        return this.http
          .get<IProductsResponse>(
            `${environment.api2}products/byType/${id}/${productId}?fetch=basic`,
          )
          .pipe(
            map((response: IProductsResponse) => response.products),
            map((products: IProduct[]) => transformProductsIn(products, order.deliveryOption)),
          )
      }),
    )
  }

  getProductsByCoupon(coupon: string): Observable<IProduct[]> {
    const deliveryOption = this.orderService.getDeliveryOption()
    return this.business$.pipe(
      filter((business) => !!business),
      mergeMap((business: IBusinessResponse | null) => {
        const id = business?.business.business_id
        return this.http
          .get<IProductsResponse>(`${environment.api2}products/coupon/${coupon}/${id}`)
          .pipe(
            map((response: IProductsResponse) => response.products),
            map((products: IProduct[]) => transformProductsIn(products, deliveryOption)),
          )
      }),
    )
  }

  mutateDeal(productToAdd: IProductDeal) {
    const product = { ...productToAdd }
    product.count = 1
    product.items = product.items.map((item) => {
      return {
        ...item,
        products: item.products.map((p: IProduct) => this.mutateProduct(p as IProductOther)),
      }
    })

    return product
  }

  mutateProduct(productToAdd: IProductOther) {
    const product = { ...productToAdd }
    product.categories = product.categories?.map((c) => {
      return {
        ...c,
        products: c.products.map((p) => ({ ...p })),
      }
    })
    return product
  }

  onAddTopping({
    productPreAdd,
    productToAdd,
    category,
    topping,
    location,
  }: {
    productPreAdd: IProductOther
    productToAdd: IProductOther
    category: number
    topping: number
    location?: ToppingLocation
  }) {
    if (!productToAdd.categories[category]) {
      return productPreAdd
    }

    const categoryToAdd = productToAdd.categories[category]
    const toppingToAdd = { ...categoryToAdd.products[topping] }

    // add category if not exists
    productPreAdd.categories = this.addPreAddCategory(productPreAdd.categories, categoryToAdd)
    const categoryIndex = this.searchCategoryIndexByID(productPreAdd.categories, categoryToAdd.id)

    // handle add product to category
    productPreAdd.categories[categoryIndex] = categoryToAdd.is_multiple_selection
      ? this.handleMultipleSelection(productPreAdd.categories[categoryIndex], toppingToAdd)
      : this.handleOneSelection(productPreAdd.categories[categoryIndex], toppingToAdd, location)

    if (categoryToAdd.is_topping_divided && location) {
      this.handleToppingPriceByMethod(productPreAdd, categoryIndex, toppingToAdd, location)
    }

    return productPreAdd
  }

  updateProductsCounterFromCart(products: IProduct[], type: ProductType) {
    const order = this.orderService.getOrder()
    const cartProducts = order.cart.filter(({ type_name }: IProduct) => type_name === type)
    products = products.map((p) => Object.assign({}, p))

    return products.map((product) => {
      const count = cartProducts.reduce((acc, cartProduct) => {
        if (cartProduct.id === product.id) {
          const count = cartProduct?.count || 0
          acc += count
        }
        return acc
      }, 0)
      product.count = count //countBy(cartProducts, ({ id }: IProduct) => id === product.id).true || 0
      return product
    })
  }

  private addPreAddCategory(
    categoriesPreAdd: IProductCategory[],
    category: IProductCategory,
  ): IProductCategory[] {
    if (!categoriesPreAdd?.length) {
      categoriesPreAdd = []
    }

    const categoryIndex = this.searchCategoryIndexByID(categoriesPreAdd, category.id)
    if (categoryIndex === -1) {
      const categoryToAdd = { ...category }
      categoryToAdd.products = []
      categoriesPreAdd.push(categoryToAdd)
    }
    return categoriesPreAdd
  }

  private searchCategoryIndexByID(categories: IProductCategory[], id: string): number {
    return categories.findIndex((c) => c.id === id)
  }

  private handleMultipleSelection(
    category: IProductCategory,
    topping: IToppingProduct,
  ): IProductCategory {
    topping.selected = true
    let productsCount = 0
    category.products.forEach((c) => {
      if (c.count) {
        productsCount += c.count
      }
    })

    if (this.shouldShowLimitAlert(category)) {
      return category
    }

    if (category.category_has_fixed_price && productsCount < category.products_fixed_price) {
      topping.price = category.fixed_price
    }
    const index = category.products.findIndex((t) => {
      return t.id === topping.id && t.price === topping.price
    })
    if (index !== -1) {
      const count = category.products[index].count
      category.products[index].selected = true
      category.products[index].count = count ? count + 1 : 1
    } else {
      topping.count = 1
      category.products.push(topping)
    }
    return category
  }

  private handleOneSelection(
    category: IProductCategory,
    topping: IToppingProduct,
    location?: ToppingLocation,
  ): IProductCategory {
    if (category.products_limit === 1) {
      category.products = []
    }

    topping.selected = true
    topping.count = 1

    category.products = category.products.filter(({ id }) => {
      return id != topping.id
    })

    if (this.shouldShowLimitAlert(category)) {
      return category
    }

    if (this.shouldSetToppingFixedPrice(category, location)) {
      topping.price = category.fixed_price
    }

    if (location) {
      topping.location = location
      const existingIndex = category.products.findIndex(({ id }) => id === topping.id)
      if (existingIndex > -1) {
        category.products[existingIndex] = topping
        return category
      }
    }
    category.products.push(topping)
    return category
  }

  shouldSetToppingFixedPrice(
    category: IProductCategory,
    location: ToppingLocation | undefined,
  ): boolean {
    if (!category.category_has_fixed_price) {
      return false
    }

    if (location && BusinessApiService.toppingMethod === ToppingMethod.ByLayer) {
      return this.shouoldAddFixedPriceByLayer(category)
    } else {
      return category.products.length < category.products_fixed_price
    }
  }

  shouoldAddFixedPriceByLayer(category: IProductCategory): boolean {
    const selectedProductsCount = this.getSelectetToppingsCount(category)
    return selectedProductsCount < category.products_fixed_price
  }

  getSelectetToppingsCount(category: IProductCategory): number {
    return category.products
      .filter((p) => p.count && p.count > 0)
      .reduce((count, topping) => {
        const toppingCount = topping.count ?? 0
        const toppingPart =
          !topping.location || topping.location === 'full'
            ? 1
            : topping.location.length === 2
            ? 0.25
            : 0.5
        return toppingCount * toppingPart + count
      }, 0)
  }

  shouldShowLimitAlert(category: IProductCategory): boolean {
    const count = category.products.reduce((c, topping) => {
      return c + (topping.count ?? 1)
    }, 0)

    if (category.products_limit >= 1 && count >= category.products_limit) {
      // category.products.splice(0, 1)
      this.dialogRef.open(InfoModalComponent, {
        height: '200px',
        width: '300px',
        panelClass: 'info-modal',
        data: { text: 'הוספת את מספר התוספות המקסימאלי', okBtnText: 'הבנתי' },
      })
      return true
    }
    return false
  }

  private handleToppingPriceByMethod(
    product: IProduct,
    categoryIndex: number,
    topping: IToppingProduct,
    location: ToppingLocation,
  ): void {
    let toppingMethod!: number
    this.business$.subscribe((business: IBusinessResponse | null) => {
      if (!business) {
        return
      }
      toppingMethod = business.business.topping_method
    })

    switch (toppingMethod) {
      case ToppingMethod.ByLocation:
        // topping price per location - divided
        this.setToppingPriceByLocation(topping, location)
        break
      case ToppingMethod.ByLayer:
        // layers price - the highest price per layer
        this.setLayersPrice(product.categories?.[categoryIndex])
        break
      case ToppingMethod.ByPrice:
        // topping has its full price regardless selected location
        break
    }

    // return category
  }

  setToppingPriceByLocation(topping: IToppingProduct, location: ToppingLocation): void {
    const pricePercent = 4 / quartersCountMap[location]
    topping.price = topping.originalPrice / pricePercent
  }

  setLayersPrice(category: IProductCategory | undefined): void {
    if (!category) {
      return
    }

    const layers: IToppingLayer[] = []
    const layerQuartersCount = 4
    const toppings = category.products

    // sort toppings by price from highest to lowest
    toppings.sort((a, b) => b.originalPrice - a.originalPrice)

    const selectedToppings = this.getSelectetToppingsCount(category)
    let currentQuartersInLayer = 0
    let layer: IToppingLayer | undefined

    toppings.forEach((topping, index) => {
      if (!topping.location) {
        return
      }
      if (selectedToppings <= category.products_fixed_price) {
        toppings[index].price = category.fixed_price
        toppings[index].originalPrice = category.fixed_price
      }
      const count = quartersCountMap[topping.location]
      if (!layer) {
        currentQuartersInLayer = count
        topping.price = topping.originalPrice
        layer = {
          price: topping.originalPrice,
          isDeal: false,
        }
      } else if (currentQuartersInLayer + count > layerQuartersCount) {
        layers.push(layer)
        topping.price = topping.originalPrice
        layer = {
          price: topping.originalPrice,
          isDeal: false,
        }
        currentQuartersInLayer += count - layerQuartersCount
      } else if (currentQuartersInLayer + count === layerQuartersCount) {
        layers.push(layer)
        layer = undefined
        currentQuartersInLayer = 0
        topping.price = 0
      } else if (currentQuartersInLayer + count < layerQuartersCount) {
        currentQuartersInLayer += count
        topping.price = 0
      }
    })

    if (layer) {
      layers.push(layer)
      layer = undefined
    }

    category.layers = layers
  }

  compareToppingsPreAdd(product: IProduct, productToAdd: IProduct) {
    product.categories?.forEach((category) => {
      let categoryToAdd = productToAdd?.categories?.find(({ id }) => id === category.id)
      if (categoryToAdd) {
        categoryToAdd.products.forEach((toppingToAdd) => {
          const toppings = category.products.filter(({ id }) => id === toppingToAdd.id)
          if (toppings.length) {
            toppingToAdd.count = toppings.reduce((total, { count }) => {
              return (count ?? 0) + total
            }, 0)

            if (!category.is_multiple_selection) {
              const topping = toppings[0]
              toppingToAdd.selected = topping.selected
              if (topping.location) {
                toppingToAdd.location = topping.location
              }
            }
          } else {
            toppingToAdd.count = 0
            toppingToAdd.selected = false
            toppingToAdd.location = undefined
          }
        })
      }
    })
  }
}
