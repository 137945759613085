import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { IConfirmCodeResponse } from './payment.model'
import {OrderService} from "../../modules/order/order.service";
import {InfoModalComponent} from "../../../common/components/info-modal/info-modal.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SiteRoutes} from "../../../routes.const";
import {Router} from "@angular/router";

@Injectable()
export class ConfirmCashService {
  constructor(private http: HttpClient, private store: Store, private orderService: OrderService,
              private router: Router,
              private matDialog: MatDialog
  ) {}

  sendConfirmCodeTo(business_id: string, phone: string, coupon: string = ''): Observable<string> {
    const params = new HttpParams()
      .set('apiCtrl', 'pizziria')
      .set('do', 'sendConfirmOrder')
      .set('business_id', business_id)
      .set('phone', phone)
      .set('coupon', coupon)

    return this.http.get<IConfirmCodeResponse>(environment.api1, { params }).pipe(
      map((response: IConfirmCodeResponse) => {
        if (response.coupon_status) {
          this.matDialog.open(InfoModalComponent, {
            panelClass: 'coupon-info-modal',
            disableClose: true,
            data: {
              text: 'הקופון כבר נוצל',
              okBtnText: 'שלח הזמנה ללא הקופון',
              cancelBtnText: 'החזר אותי לעגלת הקניות',
              okBtnCallback: () => {
                this.orderService.removeCoupon()
                this.matDialog.closeAll()
              },
              cancelBtnCallback: () => {
                this.router.navigate([SiteRoutes.Cart])
                this.matDialog.closeAll()
              }
            }
          })
        }
        return response.orderConfirmation
      }),
    )
  }

  validateConfirmationCode(code: string, confirmationId: string): Observable<boolean> {
    const params = new HttpParams().set('apiCtrl', 'pizziria').set('do', 'checkOrderValidation')

    return this.http
      .post(environment.api1, { uCode: code, confirm: confirmationId }, { params })
      .pipe(map((response: any) => response.status))
  }
}
