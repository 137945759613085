import { Component, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { ISiteInitials } from '../../../initial-data/initial-data.model'
import { navMenuItems } from '../../header/nav-menu.const'
import { selectBusinessDetails, selectSiteInitials } from '../../loader/reducers'
import { environment } from './../../../../../environments/environment'
import { IBusinessResponse } from './../../../../common/services/business/business.model'
import { SiteRoutes } from './../../../../routes.const'
import { DeliveryOption } from './../../../../site/modules/order/order.model'
import { OrderService } from './../../../../site/modules/order/order.service'

@Component({
  selector: 'bi-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.less'],
})
export class MobileFooterComponent implements OnInit {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  staticFilesPath = environment.staticFiles
  navItems = navMenuItems
  siteRoutes = SiteRoutes
  isRoutesDisabled = false
  cartData = {}
  isNewHeader = false

  constructor(private store: Store, private orderService: OrderService) {
    this.business$.subscribe((businessDetails) => {
      if (!businessDetails) {
        return
      }
      this.isNewHeader = !!businessDetails.business.menuMobile?.length
      if (this.isNewHeader) {
        this.navItems = businessDetails.business.menuMobile
      }
    })
  }

  ngOnInit(): void {
    this.initRoutesDisabled()
  }

  private initRoutesDisabled(): void {
    this.orderService.selectedDeliveryOptionSubject$
      .asObservable()
      .subscribe((opt: DeliveryOption | undefined) => {
        this.isRoutesDisabled = !opt
      })
  }

  scrollTop() {
    document.querySelector('.main-ui-view')?.scrollTo({ top: 0 })
  }
}
