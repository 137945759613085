<div class="deal-container" *ngIf="deal && siteData$ | ngrxPush as siteData">
  <div class="deal-details">
    <div class="name">{{deal.name}}:</div>
    <div class="price shekel">{{deal.price}}</div>
    <div class="remove" (click)="onRemove()">
      <span *ngIf="siteData.theme === 'light'">
        <img [src]="staticFilesPath + 'delete.svg'" alt="לוגו" />
      </span>
    </div>
  </div>

  <div class="deal-body">
    <ng-container *ngFor="let item of deal.items; let itemIndex = index">
      <ng-container *ngFor="let product of item.products; let productIndex = index">
        <bi-regular-product [product]="product" [inDeal]="true"></bi-regular-product>
      </ng-container>
    </ng-container>
  </div>
</div>
