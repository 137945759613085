<div class="info-modal-content">
  <h2 *ngIf="!data.iframe">{{dataIn.text}}</h2>
  <ng-container *ngIf="data.iframe">
    <iframe
      [src]="dataIn.iframe | safe"
      style="height: 600px; width: 350px"
      frameborder="0"
    ></iframe>
  </ng-container>
  <div class="action-btns-container">
    <button class="bi-btn" (click)="okBtnCallback()" *ngIf="!data.iframe" mat-dialog-close>
      {{okBtnText}}
    </button>
    <button class="bi-btn" (click)="cancelBtnCallback()" *ngIf="cancelBtnText" mat-dialog-close>
      {{cancelBtnText}}
    </button>
  </div>
</div>
