<div class="form-group">
  <div class="form-control">
    <!-- <label for="coupon">יש לך קופון?</label> -->
    <input
      minlength="4"
      type="text"
      id="coupon"
      [formControl]="couponCtr"
      autocomplete="off"
      name="coupon"
      placeholder="קופון"
    />
    <button mat-dialog-close class="bi-btn bi-sm bi-btn-scondary" (click)="onAddCoupon($event)" [disabled]="isLoading || couponCtr.invalid">הוסף קופון</button>
    <span *ngIf="isLoading">מחפש</span>
    <span *ngIf="errorMessage" class="error-text">{{errorMessage}}</span>
  </div>
</div>
