import { Component, Input, OnInit } from '@angular/core'
import { environment } from '../../../../../../environments/environment.development'
import {IProduct, IProductDeal, IProductOther, ProductType} from '../../../../modules/product/product.model'
import { Observable } from 'rxjs'
import { ISiteInitials } from '../../../../../common/initial-data/initial-data.model'
import { select, Store } from '@ngrx/store'
import { selectSiteInitials } from '../../../../../common/components/loader/reducers'
import {InitSiteService} from "../../../../../common/services/init-site/init-site.service";

@Component({
  selector: 'bi-pizza-item',
  templateUrl: './pizza-item.component.html',
  styleUrls: ['./pizza-item.component.less'],
})
export class PizzaItemComponent implements OnInit {
  placeholder = `${environment.images}pizza/familySize.png`
  @Input()
  item?: IProduct

  @Input()
  inDeal = false

  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  siteData: any
  imageUrl: string | undefined = '';
  businessName = ''


  constructor(private store: Store,
              private initSiteService: InitSiteService) {
    const {name} = initSiteService.selectSiteInitials()
    this.businessName = name
    this.siteData$.subscribe(data => this.siteData = data)
  }

  ngOnInit(): void {
    this.imageUrl = this.item?.imageUrl
    if (!this.item?.imageUrl || this.item?.imageUrl == 'new_deal.png' || this.item?.picture === 'new_deal.png' || this.item.picture === null) {
        this.imageUrl = `${environment.images}pizza/${this.item?.shape}.png`
    }
  }
}
