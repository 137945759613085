<div class="client-details" [formGroup]="detailsForm" [ngClass]="{'is-pickup': isPickup}">
  <div class="form-control">
    <input
      type="text"
      formControlName="f_name"
      name="f_name"
      class="prmBorder"
      placeholder="*שם פרטי"
      pattern="^(?!\s*$).+"
    />
  </div>

  <div class="form-control">
    <input
      type="text"
      formControlName="l_name"
      name="l_name"
      class="prmBorder"
      placeholder="*שם משפחה"
      pattern="^(?!\s*$).+"
    />
  </div>

  <div class="form-control">
    <input
      type="text"
      id="phone-number"
      formControlName="phone"
      name="phone"
      class="prmBorder"
      placeholder="*טלפון"
      maxlength='10'
      pattern="^(?!\s*$).+"
    />

    <div class="is-required" *ngIf="isPickup && !isMobile">* - שדה חובה</div>
  </div>

  <div class="form-control" formGroupName="notes">
    <textarea
      name="notes"
      formControlName="order"
      class="prmBorder notes"
      placeholder="הערות"
    ></textarea>
  </div>
</div>
