import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { ISiteInitials } from '../../../initial-data/initial-data.model'
import { InitSiteService } from '../../../services/init-site/init-site.service'
import { selectSiteInitials } from './../../loader/reducers/index'

@Component({
  selector: 'bi-add-remove-btns',
  templateUrl: './add-remove-btns.component.html',
  styleUrls: ['./add-remove-btns.component.less'],
})
export class AddRemoveBtnsComponent implements OnInit {
  @Input()
  count = 0

  @Input()
  allowMultipleSelection = true

  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  @Input()
  selectionLimit = 0

  @Input()
  showAddIcon = false

  @Output()
  onAdd: EventEmitter<undefined> = new EventEmitter()

  @Output()
  onRemove: EventEmitter<undefined> = new EventEmitter()

  staticFilesPath = ''
  businessName: string

  constructor(private store: Store, private initSiteService: InitSiteService) {
    const { name } = initSiteService.selectSiteInitials()
    this.businessName = name
  }

  ngOnInit(): void {
    if (!this.allowMultipleSelection) {
      this.store.pipe(take(1), select(selectSiteInitials)).subscribe((response) => {
        this.staticFilesPath = `${environment.staticFiles}${response.name}/icons/`
      })
    }
  }

  add(event: MouseEvent) {
    // if (this.isReachLimit()) {
    //   return
    // }
    event.stopPropagation()
    this.onAdd.emit()
  }

  remove(event: MouseEvent) {
    event.stopPropagation()

    if (this.count > 0) {
      this.onRemove.emit()
    }
  }

  private isReachLimit(): boolean {
    if (this.selectionLimit === 0) {
      return false
    }

    return this.count >= this.selectionLimit
  }
}
