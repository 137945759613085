import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TermsAndConditionsComponent } from './../components/terms-and-conditions/terms-and-conditions.component'

@Injectable()
export class TermsAndConditionsService {
  constructor(private dialog: MatDialog) {}

  openTermsAndConditions() {
    this.dialog.open(TermsAndConditionsComponent, {
      panelClass: 'terms-container',
      width: '100vw',
      height: '100vh',
    })
  }
}
