import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms'
import {environment} from '../../../environments/environment'
import {HttpClient, HttpErrorResponse} from '@angular/common/http'
import {select, Store} from '@ngrx/store'
import {selectBusinessDetails, selectSiteInitials} from '../../common/components/loader/reducers'
import {IBusiness, IBusinessResponse} from '../../common/services/business/business.model'
import {Observable, throwError} from "rxjs";
import {ThankYouComponent} from "../../common/components/thank-you/thank-you.component";
import {MatDialog} from "@angular/material/dialog";
import {InfoModalComponent} from "../../common/components/info-modal/info-modal.component";
import {SiteRoutes} from "../../routes.const";
import {Router} from "@angular/router";

@Component({
  selector: 'bi-unsubscribe-ads',
  templateUrl: './unsubscribe-ads.component.html',
  styleUrls: ['./unsubscribe-ads.component.less']
})
export class UnsubscribeAdsComponent implements OnInit {

  adsForm: UntypedFormGroup = new UntypedFormGroup({
    phone: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]{10}$")]),
    otp: new UntypedFormControl(),
  })

  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails));
  business!: IBusiness
  confirmation_id!: number
  isRemoved: any
  message = "";
  showMessage = false;
  staticFilesPath = ''

  constructor(private http: HttpClient, private store: Store, private dialog: MatDialog, private router: Router) {
    this.store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business) {
        this.business = business.business
      }
    })
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }

  ngOnInit(): void {
  }

  confirmPhone() {
    if (!this.confirmation_id) {
      this.http.post<{
        confirmation_id: number,
        is_canceled: boolean
      }>(`${environment.api2}business/ads/confirmPhone/${this.business.business_id}`, this.adsForm.value)
        .subscribe((res) => {
          this.confirmation_id = res.confirmation_id
          this.isRemoved = res.is_canceled
          if (this.isRemoved) {
            this.dialog.open(InfoModalComponent, {
              panelClass: 'info-modal',
              data: {
                text: 'הטלפון שלך לא נמצא במערכת  ',
                okBtnText: 'אשר'
              }
            })
          }
        })
    } else {
      this.http.post<{
        confirmation_id: number,
        status: boolean
      }>(`${environment.api2}business/ads/unsubscribe/${this.business.business_id}`, {
        phone: this.adsForm.value.phone,
        confirmation_id: this.confirmation_id,
        otp: this.adsForm.value.otp,
      })
        .subscribe((res) => {
            this.message = 'הוסרת מרשימת התפוצה '
            this.showMessage = true
            this.dialog.open(InfoModalComponent, {
              panelClass: 'info-modal',
              data: {
                text: 'הוסרת מרשימת התפוצה ',
                okBtnText: 'חזרה לדף הבית\n',
                okBtnCallback: () => {
                  this.dialog.closeAll()
                  this.router.navigate([SiteRoutes.Home])
                },
              }
            })
          },
          error => {
            console.log(error)
            this.dialog.open(InfoModalComponent, {
              panelClass: 'info-modal',
              data: {
                text: 'לא נמצא קוד מתאים',
                okBtnText: 'אשר'
              }
            })
          }
        )
    }
  }
}
