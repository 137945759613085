<header>
  <div class="hambuger" (click)="openSideNav()">
    <div class="line line1"></div>
    <div class="line line2"></div>
    <div class="line line3"></div>
  </div>
  <div class="logo-container">
    <a [routerLink]="siteRoutes.Home">
      <img [src]="staticFilesPath + 'small_logo.png'" alt="לוגו קטן" />
    </a>
  </div>
  <div [routerLink]="siteRoutes.Cart" class="cart-header">
    <span class="cart-count">({{cartCount}})</span>
    <span>
      <div class="menu-cart-item">
        <img [src]="staticFilesPath+'cart_logo.svg'" />
      </div>
    </span>
  </div>
</header>
<bi-mobile-nav [ngClass]="{'in': isSideNavOpened}" (onCloseNav)="onCloseNav()"></bi-mobile-nav>
