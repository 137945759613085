import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { PushModule } from '@ngrx/component'
import { ClientAddressService } from '../home/home/select-address/delivery-address/client-address.service'
import { SharedModule } from '../modules/shared.module'
import { AboutComponent } from './about/about.component'
import { AddressAndNotesComponent } from './address-and-notes/address-and-notes.component'
import { ClientDetailsComponent } from './address-and-notes/client-details/client-details.component'
import { SelectDeliveryAddressComponent } from './address-and-notes/select-delivery-address/select-delivery-address.component'
import { ConfirmCashPaymentComponent } from './confirm-cash-payment/confirm-cash-payment.component'
import { ConfirmCashService } from './confirm-cash-payment/confirm-cash.service'
import { ContactBringitComponent } from './contact/contact-bringit/contact-bringit.component'
import { ContactFormComponent } from './contact/contact-form/contact-form.component'
import { ContactPizziriaComponent } from './contact/contact-pizziria/contact-pizziria.component'
import { ContactService } from './contact/contact.service'
import { SelectPaymentTypeModalComponent } from './select-payment-type-modal/select-payment-type-modal.component'
import { SelectPaymentComponent } from './select-payment/select-payment.component'
import { SuggestImprovmentsComponent } from './suggest-improvments/suggest-improvments.component'
import { SuggestImprovmentsService } from './suggest-improvments/suggest-improvments.service'

const declarations = [
  SuggestImprovmentsComponent,
  AboutComponent,
  AddressAndNotesComponent,
  ClientDetailsComponent,
  SelectDeliveryAddressComponent,
  SelectPaymentComponent,
  SelectPaymentTypeModalComponent,
  ConfirmCashPaymentComponent,
  ContactPizziriaComponent,
  ContactBringitComponent,
  ContactFormComponent,
]

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    PushModule,
    CommonModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: declarations,
  exports: declarations,
  providers: [ClientAddressService, ConfirmCashService, SuggestImprovmentsService, ContactService],
})
export class AlikesModule {}
