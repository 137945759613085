import { Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import { IProduct } from '../../../../../site/modules/product/product.model'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { selectSiteInitials } from '../../../loader/reducers'
import { ProductsActions } from '../../actions'

@Component({
  selector: 'bi-pizza',
  templateUrl: './pizza.component.html',
  styleUrls: ['./pizza.component.less'],
})
export class PizzaComponent implements OnInit {
  @Input()
  product?: IProduct
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  siteData?: ISiteInitials | null

  @Input()
  inDeal = false

  constructor(private store: Store) {
    this.siteData$.subscribe((data) => (this.siteData = data)).unsubscribe()
  }

  ngOnInit(): void {

  }

  onAdd() {

    if (this.siteData?.theme === 'light'&& this.inDeal) {
      // animationOnAddProduct
      let productCard = document.querySelector('.deal-image-placeholder') as HTMLElement
      if (productCard) {
        productCard.classList.add('product-added')
        setTimeout(() => {
          productCard.classList.add('product-apear-size')
        }, 500)
        setTimeout(() => {
          productCard.classList.remove('product-added')
          productCard.classList.remove('product-apear-size')
        }, 1000)
      }
      // animationOnAddProduct end
      this.store.dispatch(
        ProductsActions.onSelectDealPreAddProduct({
          dealPreAddProduct: cloneDeep(this.product) as IProduct | null,
        }),
      )
      return
    }
    this.store.dispatch(
      ProductsActions.onAddProduct({ product: cloneDeep(this.product) as IProduct | null }),
    )
  }

}
