export const environment = {
  production: false,
  staging: false,
  development: false,
  test: true,
  preProd: false,
  api1: 'https://api.test.bringit.org.il?apiCtrl=',
  api2: 'https://api2.test.bringit.org.il/',
  files: 'https://sub.test.bringit.org.il/files/',
  images: 'https://sub.test.bringit.org.il/images/',
  staticFiles: 'https://sub.test.bringit.org.il/images/static/',
  paymentLink: 'https://test.pay.bringit.org.il/payment/',
  Cal_script: 'https://sandbox.cal-online.co.il/paycal/scripts/payCalButton.js',
}
