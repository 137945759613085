import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take, withLatestFrom } from 'rxjs/operators'
import { environment } from '../../../../../environments/environment'
import { IProduct, ProductShape } from '../../../../site/modules/product/product.model'
import { ISiteInitials } from '../../../initial-data/initial-data.model'
import { MobileDetectorService } from '../../../services/mobile-detector/mobile-detector.service'
import { ProductsActions } from '../actions'
import { MandatoryCategoryComponent } from '../common/pop-up/mandatory-category/mandatory-category/mandatory-category.component'
import { BusinessMenuService } from './../../../services/business/business-menu.service'
import { selectIsNewMenu, selectSiteInitials } from './../../loader/reducers/index'
import {
  selectCategoryToAdd,
  selectDealToAdd,
  selectIsCategoryContinueDisabled,
} from './../reducers/index'

@Component({
  selector: 'bi-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.less'],
})
export class AddProductComponent implements OnInit, OnChanges {
  currentIndex: any
  currentIndex$ = this.store.pipe(select(selectCategoryToAdd))
  isContinueDisabled$: Observable<boolean> = this.store.pipe(
    select(selectIsCategoryContinueDisabled),
  )

  @Input()
  product: IProduct | null = null

  imageUrl = ''
  productShape = ProductShape

  deal$ = this.store.pipe(select(selectDealToAdd), take(1))
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  isMobile: boolean
  isNewMenu = false
  isNewMenu$ = this.store.pipe(select(selectIsNewMenu))

  constructor(
    private store: Store,
    private businessMenuService: BusinessMenuService,
    protected mobileDetectorService: MobileDetectorService,
    private dialogRef: MatDialog,
  ) {
    this.isMobile = mobileDetectorService.isMobile()
  }

  ngOnInit(): void {
    this.currentIndex$.subscribe((index) => {
      if (index !== null && index !== undefined) {
        this.currentIndex = index
      }
    })
    this.init()
  }

  ngOnChanges({ product }: SimpleChanges): void {
    if (!product || product.firstChange) {
      return
    }

    this.init()
  }

  onContinueClick() {
    let canContinue = false

    this.isContinueDisabled$.subscribe((val) => {
      canContinue = !val
    })

    if (canContinue) {
      this.resetCategoryPosition()
      this.store.dispatch(ProductsActions.onPageContinue())
    } else this.openInfoModalMandatoryCategory()
  }

  private init() {
    this.store
      .pipe(select(selectSiteInitials), withLatestFrom(this.isNewMenu$), take(1))
      .subscribe(([site, isNewMenu]) => {
        this.isNewMenu = isNewMenu
        if (site && this.product) {
          if (this.product.shape) {
            this.imageUrl = `${environment.staticFiles}${site.name}/${this.product.shape}Pizza.png`
          } else if (
            this.product.picture == 'new_deal.png' ||
            !this.product.picture ||
            this.product.imageUrl == ''
          ) {
            this.imageUrl = this.getDefaultImgPlaceholder(this.product)
          } else {
            this.imageUrl = this.product.imageUrl
          }
        }
      })
  }

  onBackClick() {
    this.resetCategoryPosition()
    this.store.dispatch(ProductsActions.onCategoryBack())
  }

  getDefaultImgPlaceholder(product: IProduct) {
    return this.businessMenuService.getDefaultImgPlaceholder(product)
  }
  resetCategoryPosition() {
    let categoryBox = document.querySelector('.simplebar-content-wrapper') as HTMLElement
    if (categoryBox) {
      categoryBox.scrollTop = 0
    }
  }

  // dialogs

  openInfoModalMandatoryCategory() {
    this.dialogRef.open(MandatoryCategoryComponent)
  }
}
