import { Observable } from 'rxjs'
import { shareReplay } from 'rxjs/operators'

type Method<D> = (...args: any[]) => D
export type ObservableMethod<D> = (...args: any[]) => Observable<D>
export type ObservableCache<D> = Map<string, Observable<D>>

type Memoizable<T, D> = (
  target: T,
  propertyName: keyof T,
  descriptor: TypedPropertyDescriptor<Method<D>>,
) => TypedPropertyDescriptor<Method<D>>

type ObservableMemoizable<T, D> = Memoizable<T, Observable<D>>

export function memoizeObservable<T = any, D = any>(
  customCache?: ObservableCache<D>,
): ObservableMemoizable<T, D> {
  return (
    target: T,
    propertyName: keyof T,
    descriptor: TypedPropertyDescriptor<ObservableMethod<D>>,
  ): TypedPropertyDescriptor<ObservableMethod<D>> => {
    if (descriptor.value) {
      const originalMethod = descriptor.value
      const cache = customCache || new Map<string, Observable<D>>()

      descriptor.value = function (...args: any[]): Observable<D> {
        const key = JSON.stringify(args)
        if (!cache.has(key)) {
          const orig$ = originalMethod.apply(this, args)
          cache.set(key, orig$.pipe(shareReplay(1)))
        }
        return cache.get(key) as Observable<D>
      }

      return descriptor
    }

    throw new Error('@memoizeObservable is applicable only on methods.')
  }
}
