<ng-container *ngIf="business$|ngrxPush as business">
  <bi-card-standard>
    <div class="title" page-title></div>
    <div class="card-container box-padding" page-content>
      <h2>הסרה מרשימת תפוצה ומבצעים</h2>
      <form class="card-form" name="cardForm" [formGroup]="adsForm" (ngSubmit)="confirmPhone()">
        <h2>
          {{business.business.business_information.business_name_commercial}} |
          <span class="business-phone"
            >{{business.business.business_information.business_phone}}</span
          >
        </h2>
        <img
          [src]="staticFilesPath + 'medium_logo.png'"
          class="responsive"
          alt="לוגו"
          width="100px"
        />
        <p *ngIf="!confirmation_id">
          דע כי אחרי שתוסר מרשימת התפוצה לא תקבל יותר הנחות ומבצעים מבית עסק זה
        </p>
        <p *ngIf="confirmation_id">אנא הזן את הקוד שקיבלת בהודעה</p>
        <div class="card-details">
          <input
            *ngIf="!confirmation_id"
            type="phone"
            maxlength="10"
            formControlName="phone"
            name="phone"
            placeholder="*טלפון "
          />
          <input
            *ngIf="confirmation_id"
            type="number"
            maxlength="6"
            formControlName="otp"
            name="otp"
            placeholder="*קוד"
          />
        </div>
        <div class="card-footer">
          <button class="bi-btn bi-btn-ghost" type="submit" [disabled]="adsForm.invalid">
            אשר
          </button>
          <p class="my-1" style="color: red">{{message}}</p>
        </div>
      </form>
    </div>
  </bi-card-standard>
</ng-container>
