<div class="item-container" *ngIf="item">
  <div class="item-image">
    <img [src]="imageUrl" alt="שתיה" />
  </div>
  <div class="item-details">
    <div class="name">{{ item.name }}</div>
    <div class="item-price-container" *ngIf="!inDeal">
      <span>{{ inDeal ? 'כלול במחיר' : item.price | currency: 'ILS' : 'symbol' : '1.0-2' }}</span>
    </div>
  </div>
</div>
