import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { OrderActions } from '../../modules/order/actions'
import { selectBusinessDetails } from './../../../common/components/loader/reducers/index'
import { SiteRoutes } from './../../../routes.const'
import { OrderService } from './../../modules/order/order.service'
import { ConfirmCashService } from './confirm-cash.service'

@Component({
  selector: 'bi-confirm-cash-payment',
  templateUrl: './confirm-cash-payment.component.html',
  styleUrls: ['./confirm-cash-payment.component.less'],
})
export class ConfirmCashPaymentComponent implements OnInit {
  isSending = false
  isSent = false
  isError = false
  phone: string
  confirmationId = ''
  confirmationCode = new UntypedFormControl('')
  isErrorCode = false
  isConfirming = false

  constructor(
    private orderService: OrderService,
    private confirmCashService: ConfirmCashService,
    private store: Store,
    private router: Router,
  ) {
    this.phone = orderService.getUserInfo().phone
  }

  ngOnInit(): void {
    this.store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business) {
        this.sendConfirmingCode(business.business.business_id)
      }
    })
  }

  sendConfirmingCode(business_id: string) {
    if (!this.phone) {
      return
    }
    let coupon = this.orderService.getOrder().coupon

    this.isError = false
    this.isSending = true
    this.confirmCashService.sendConfirmCodeTo(business_id, this.phone, coupon).subscribe(
      (confirmationId: string) => {
        this.isError = false
        this.isSending = false
        this.isSent = true
        this.confirmationId = confirmationId
      },
      () => {
        this.isError = true
        this.isSending = false
        this.isSent = false
        this.confirmationId = ''
      },
    )
  }

  onAddConfirmCode() {
    this.isConfirming = true
    this.confirmCashService
      .validateConfirmationCode(this.confirmationCode.value, this.confirmationId)
      .subscribe(
        () => {
          this.isErrorCode = false
          this.isConfirming = false
          this.createOrder()
        },
        () => {
          this.isErrorCode = true
          this.isConfirming = false
        },
      )
  }

  private createOrder() {
    this.orderService.createOrder().subscribe(
      (r: any) => {
        this.store.dispatch(OrderActions.onCompletedOrder())
        localStorage.setItem('order_id', r.order_id)
        this.router.navigate([SiteRoutes.Tracker])
      },
      () => {
        alert('error')
      },
    )
  }

  resendCode(): void {
    window.location.reload();
  }
}
