import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectBusinessDetails, selectSiteInitials } from './../../../../common/components/loader/reducers'
import { IBusinessResponse } from './../../../../common/services/business/business.model'
import { SiteRoutes } from './../../../../routes.const'
import { TermsAndConditionsService } from './../../../services/terms-and-conditions.service'
import { FooterNav } from './../footer.const'
import { ISiteInitials } from '../../../initial-data/initial-data.model'

@Component({
  selector: 'bi-desktop-footer',
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.less'],
})
export class DesktopFooterComponent implements OnInit {
  business$: Observable<IBusinessResponse | null> = this.store.pipe(select(selectBusinessDetails))
  footerNav = FooterNav
  fullYear = new Date().getFullYear()
  siteRoutes = SiteRoutes
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  constructor(private store: Store, private termsAndConditionsService: TermsAndConditionsService) {}

  ngOnInit(): void {}

  openTermsAndConditions() {
    this.termsAndConditionsService.openTermsAndConditions()
  }
}
