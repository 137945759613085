import { Injectable } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'
// import * as Http from 'http'

@Injectable()
export class MobileDetectorService {
  // private isDeviceMobile

  constructor(private deviceService: DeviceDetectorService) {
    // this.isDeviceMobile = this.isMobile()
  }

  isMobile(): boolean {
    return this.deviceService.isMobile()
  }
  isTablet(): boolean {
    return this.deviceService.isTablet()
  }
  isDesktop(): boolean {
    return this.deviceService.isDesktop()
  }
}
