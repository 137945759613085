import { Component, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import {IProduct, IProductDeal, ProductType} from 'src/app/site/modules/product/product.model'
import { ISiteInitials } from '../../../../initial-data/initial-data.model'
import { selectSiteInitials } from '../../../loader/reducers'
import { ProductsActions } from '../../actions'
import { AddProductModalComponent } from '../add-product-modal/add-product-modal.component'
import {environment} from "../../../../../../environments/environment";
import {SiteRoutes} from "../../../../../routes.const";
import {Router} from "@angular/router";

@Component({
  selector: 'bi-popup-description',
  templateUrl: './popup-description.component.html',
  styleUrls: ['./popup-description.component.less'],
})
export class PopupDescriptionComponent {
  product!: IProduct | null
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))
  siteData?: ISiteInitials | null
  staticFilesPath = ''
  icons: { icon: string; count: number }[] = []

  constructor(
    protected store: Store,
    private dialogRef: MatDialogRef<PopupDescriptionComponent | AddProductModalComponent>,
    private matDialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.product = data.product
    this.siteData$.subscribe((data) => (this.siteData = data)).unsubscribe()
    store.pipe(select(selectSiteInitials)).subscribe((site) => {
      this.staticFilesPath = `${environment.staticFiles + site.name}/`
    })
  }


  ngOnInit() {
    if(this.product?.type_name !== 'deal') {
      if(this.product?.shape && (this.product?.imageUrl === 'new_deal.png' || !this.product?.imageUrl || !this.product?.picture)) {
        this.product!.imageUrl = `${this.staticFilesPath}/${this.product!.shape}Pizza.png`
        this.product.picture = 'pizza.png'
      }
    }
    else {
      this.initIcons()
    }
  }
  onAdd() {
    if(this.product?.type_name !== 'deal') {
      this.store.dispatch(ProductsActions.onAddProduct({ product: cloneDeep(this.product) }))
      if (this.product?.categories?.length && this.siteData?.theme === 'light') {
        this.showAddDealModal()
      }
    }
    this.dialogRef.close()
  }

  showAddDealModal() {
    let panelClass = this.product!.type_name + '_modal'
    if (this.product?.shape) {
      panelClass = this.product?.type_name + '_' +  this.product?.shape + '_modal'
    }
    this.matDialog.open(AddProductModalComponent, {
      data: {
        product: this.product,
      },
      panelClass: panelClass,
    })
  }


  private initIcons() {
    if (!this.product) {
      return
    }

    const counts: { [key: string]: { icon: string; count: number } } = {}
    this.product.items?.forEach((item) => {
      if (!counts[item.type_name]) {
        counts[item.type_name] = {
          count: 0,
          icon:
            (item.type_name === ProductType.pizza
              ? `${item.type_name}-${item.products[0].shape}`
              : item.type_name) + '-icon.svg',
        }
      }

      counts[item.type_name].count++
    })
    this.icons = Object.values(counts)
  }
}
