import { createAction, props } from '@ngrx/store'
import { IProduct, ToppingLocation } from '../../../../site/modules/product/product.model'
import { IProductDeal } from './../../../../site/modules/product/product.model'

/* DEAL */
export const onAddDeal = createAction(
  '[Products] On Add Deal',
  props<{ deal: IProductDeal | null }>(),
)
export const onSelectDealItem = createAction(
  '[Products] On Select Deal Item',
  props<{ index: number | null }>(),
)

export const onPreAddDeal = createAction(
  '[Products] On Pre Add Deal',
  props<{ dealPreAdd: IProductDeal | null }>(),
)

export const onAddProductToDeal = createAction('[Products] On Add Product To Deal')

export const onDealStep = createAction('[Products] On Deal Step', props<{ dealStep: number }>())

/* PRODUCT */
export const onAddProduct = createAction(
  '[Products] On Add Product',
  props<{ product: IProduct | null }>(),
)

export const onUpdateAddProduct = createAction(
  '[Products] On Update Add Product',
  props<{ product: IProduct | null }>(),
)

export const onAddProductUpdated = createAction(
  '[Products] On Add Product Updated',
  props<{ product: IProduct | null }>(),
)

export const onPreAddProduct = createAction(
  '[Products] On Pre Add Product',
  props<{ productPreAdd: IProduct | null }>(),
)

/* CATEGORIES */

export const onSelectCategoy = createAction(
  '[Products] On Select Category',
  props<{ index: number | null }>(),
)
export const onAddTopping = createAction(
  '[Products] On Add Topping',
  props<{ index: number | null }>(),
)
export const onAddDividedTopping = createAction(
  '[Products] On Add Divided Topping',
  props<{ location: ToppingLocation | null; index: number | null }>(),
)

export const onRemovePreAddTopping = createAction(
  '[Products] On Remove Pre Add Topping',
  props<{ id: string }>(),
)

export const onCategoryBack = createAction('[Products] On Category Back')
export const noAction = createAction('[General Actions] No Actions')

/* PAGE */
export const onPageContinue = createAction('[Products] On Page Continue')

export const onSelectDealPreAddProduct = createAction(
  '[Products] On Pre Add Product',
  props<{ dealPreAddProduct: IProduct | null }>(),
)
