import { SiteRoutes } from './../../../routes.const'
import { INavItems } from './nav-menu.model'
import { IBusiness } from '../../services/business/business.model'

export const navMenuItems: INavItems[] = [
  {
    name: 'מבצעים',
    icon: 'm-deal-icon.svg',
    url: `${SiteRoutes.Pizza}/${SiteRoutes.DealsSelect}`,
  },
  {
    name: 'פיצות',
    icon: 'm-pizza-icon.svg',
    url: `${SiteRoutes.Pizza}/${SiteRoutes.PizzaSelect}`,
  },
  {
    name: 'שתיה',
    icon: 'm-drink-icon.svg',
    url: SiteRoutes.Drinks,
  },
  {
    name: 'מוצרים נוספים',
    icon: 'm-additionalOffer-icon.svg',
    url: SiteRoutes.AdditionalOffer,
  },
  {
    name: 'מעקב',
    icon: 'm-track-icon.svg',
    url: SiteRoutes.Tracker,
  },
  {
    name: 'עגלה',
    icon: 'shopping-basket-icon.svg',
    url: SiteRoutes.Cart,
  },
]
