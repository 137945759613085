<div class="container popup-description" *ngIf="product">
  <div class="description-content" id="description-popup">
    <div mat-dialog-close class="button popup-close">X</div>
    <div class="img-container">
      <img class="responsive" src="{{product.imageUrl}}" alt="{{product.name}}/" />
    </div>
    <h2 mat-dialog-title>{{product.name}}</h2>
    <p class="description-text">{{product.description}}</p>
    <div class="price shekel">{{product.price}}</div>
    <ng-container>
      <div class="continue-container">
        <div class="btn-group">
          <button class="bi-btn full" (click)="onAdd()">הוסף</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
