<div class="product-container" *ngIf="product && siteData$ | ngrxPush as siteData">
  <div class="product-details">
    <div class="product-cart-image" [ngClass]="{'no-image': !product.picture}">
      <img class="responsive" [src]="getProductImage(this.product)" />
    </div>
    <div class="name">
      <span>{{product.name}}</span>
      <span *ngIf="product.count > 1"> x {{product.count}}</span>
      <span *ngIf="product.categories.length">:</span>
    </div>

    <ng-template [ngIf]="!inDeal && !product.isCoupon" [ngIfElse]="noPriceProduct">
      <div class="price shekel">{{product.price}}</div>
    </ng-template>

    <ng-template #noPriceProduct>
      <div class="price" *ngIf="!inDeal">{{ inDeal ? 'כלול במחיר' : 'כלול במחיר' }}</div>
    </ng-template>

    <div class="remove" (click)="onRemove()" *ngIf="canDeleteItem">
      <span *ngIf="siteData.theme === 'light'">
        <img [src]="staticFilesPath + 'delete.svg'" alt="לוגו" />
      </span>
    </div>
  </div>

  <div class="product-body">
    <div class="product-category" *ngFor="let category of product.categories; let index = index">
      <ng-container *ngIf="category.products.length">
        <bi-cart-category
          [category]="category"
          [shape]="product.shape"
          [canDeleteItem]="canDeleteItem"
          (removeTopping)="removeTopping($event, index)"
        ></bi-cart-category>
      </ng-container>
    </div>
  </div>
</div>
