<div class="terms-wrapper" *ngIf="siteData$ | ngrxPush as siteData">
  <div class="iframe-wrapper" style="height: 450px">
    <iframe
      class="doc"
      [src]="filesPath + siteData.name  + '.html' | safe"
      type="application/pdf"
    ></iframe>
  </div>
  <div class="action">
    <button class="bi-btn bi-btn-ghost full" (click)="closeDialog()">סגור</button>
  </div>
</div>
