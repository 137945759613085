import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { PushModule } from '@ngrx/component'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { SimplebarAngularModule } from 'simplebar-angular'
import { OutOfStockModule } from '../../directives/out-of-stock/out-of-stock.module'
import { ProductsService } from './../../../site/modules/product/products.service'
import { SharedModule } from './../../../site/modules/shared.module'
import { AddProductComponent } from './add-product/add-product.component'
import { CategoriesComponent } from './add-product/categories/categories.component'
import { CategoriesService } from './add-product/categories/categories.service'
import { CategoryTitleComponent } from './add-product/categories/category/category-title/category-title.component'
import { DividedCategoryComponent } from './add-product/categories/category/divided-category/divided-category.component'
import { DividedToppingModule } from './add-product/categories/category/divided-category/divided-topping/divided-topping.module'
import { NotDividedCategoryComponent } from './add-product/categories/category/not-divided-category/not-divided-category.component'
import { DrawToppingCircleComponent } from './add-product/draw-toppings/draw-topping-circle/draw-topping-circle.component'
import { DrawToppingRectangleComponent } from './add-product/draw-toppings/draw-topping-rectangle/draw-topping-rectangle.component'
import { AddRemoveBtnsComponent } from './add-remove-btns/add-remove-btns.component'
import { DealItemsMapComponent } from './deal-items-map/deal-items-map.component'
import { DealProductComponent } from './deal-product/deal-product.component'
import { DealsEffects } from './effects/deals.effects'
import { ProductsEffects } from './effects/products.effects'
import { ToppingsEffects } from './effects/toppings.effects'
import { ProductsWithCartComponent } from './products-with-cart/products-with-cart.component'
import { AddProductModalComponent } from './products/add-product-modal/add-product-modal.component'
import { AdditionalOfferComponent } from './products/additional-offer/additional-offer.component'
import { DrinkComponent } from './products/drink/drink.component'
import { GroupProductItemComponent } from './products/group-product-item/group-product-item.component'
import { PizzaComponent } from './products/pizza/pizza.component'
import { PopupDescriptionComponent } from './products/popup-description/popup-description.component'
import { ProductsComponent } from './products/products.component'
import { GoBackComponent } from './products/utils/go-back/go-back.component'
import { fromProducts } from './reducers'
import { productsFeatureKey } from './reducers/products.reducers'
const declarations = [
  AddProductComponent,
  DealProductComponent,
  DealItemsMapComponent,
  ProductsComponent,
  ProductsWithCartComponent,
  DrinkComponent,
  AddRemoveBtnsComponent,
  CategoriesComponent,
  DividedCategoryComponent,
  NotDividedCategoryComponent,
  AdditionalOfferComponent,
  PizzaComponent,
  DrawToppingCircleComponent,
  DrawToppingRectangleComponent,
  CategoryTitleComponent,
  PopupDescriptionComponent,
  AddProductModalComponent,
  GroupProductItemComponent,
  GoBackComponent,
]

@NgModule({
  imports: [
    MatDialogModule,
    CommonModule,
    PushModule,
    StoreModule.forFeature(productsFeatureKey, fromProducts.reducer),
    EffectsModule.forFeature([ProductsEffects, DealsEffects, ToppingsEffects]),
    SharedModule,
    DividedToppingModule,
    SimplebarAngularModule,
    OutOfStockModule,
  ],
  declarations: declarations,
  exports: declarations,
  providers: [ProductsService, CategoriesService],
})
export class ProductsModule {}
