export const environment = {
  production: false,
  staging: false,
  development: true,
  test: false,
  preProd: false,
  api1: 'https://api.dev.bringit.org.il',
  api2: 'https://api2.dev.bringit.org.il/',
  files: 'https://sub.dev.bringit.org.il/files/',
  images: 'https://sub.dev.bringit.org.il/images/',
  staticFiles: 'https://sub.dev.bringit.org.il/images/static/',
  paymentLink: 'https://dev.pay.bringit.org.il/payment/',
  Cal_script: 'https://sandbox.cal-online.co.il/paycal/scripts/payCalButton.js',
}
