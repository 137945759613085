import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { environment } from './../../../../../environments/environment'
import { InitSiteService } from './../../../../common/services/init-site/init-site.service'
import { selectLoadingSite } from './../reducers/index'
import { Router } from '@angular/router'
import { SiteRoutes } from '../../../../routes.const'

@Component({
  selector: 'bi-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
})
export class LoaderComponent implements OnInit {
  staticImagesPath = ''
  businessName = ''
  isDealPage: boolean = false

  isSiteInitailsLoading$ = this.store.pipe(select(selectLoadingSite))

  constructor(private store: Store, private initSiteService: InitSiteService, private route:Router) {}

  ngOnInit(): void {
    const site = this.initSiteService.selectSiteInitials()
    this.businessName = site.name
    this.staticImagesPath = `${environment.staticFiles}${this.businessName}`
    let route = this.route.url.substr(1, this.route.url.length)
    switch (route) {
      case `${SiteRoutes.Pizza}/${SiteRoutes.DealsSelect}`:
        this.isDealPage = true
        break
    }
  }
}
