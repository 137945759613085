import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { selectBusinessDetails } from '../../../common/components/loader/reducers'

@Component({
  selector: 'bi-android',
  templateUrl: './android.component.html',
  styleUrls: ['./android.component.less'],
})
export class AndroidComponent implements OnInit {
  showMessage = false

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(selectBusinessDetails)).subscribe((business) => {
      if (business?.business.android_app) {
        window.location.replace(business.business.android_app)
        this.showMessage = false
      } else {
        this.showMessage = true
      }
    })
  }
}
