import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { take } from 'rxjs/operators'
import {
  IProductCategory,
  ToppingLocation,
} from '../../../../../../../site/modules/product/product.model'
import { MobileDetectorService } from '../../../../../../services/mobile-detector/mobile-detector.service'
import { ProductsActions } from '../../../../actions'
import { CategoriesService } from '../../categories.service'
import { ProductShape } from './../../../../../../../site/modules/product/product.model'
import { selectProductPreAdd } from './../../../../reducers/index'
import {Observable} from "rxjs";
import {IBusinessResponse} from "../../../../../../services/business/business.model";
import {selectBusinessDetails, selectSiteInitials} from "../../../../../loader/reducers";
import {ISiteInitials} from "../../../../../../initial-data/initial-data.model";

@Component({
  selector: 'bi-divided-category',
  templateUrl: './divided-category.component.html',
  styleUrls: ['./divided-category.component.less'],
})
export class DividedCategoryComponent implements OnInit, OnChanges {
  siteData$: Observable<ISiteInitials | null> = this.store.pipe(select(selectSiteInitials))

  @Input()
  category!: IProductCategory

  isMobile: boolean
  showFixedPrice = false
  shape: ProductShape | undefined = ProductShape.Circle

  constructor(
    private store: Store,
    private mobileDetectorService: MobileDetectorService,
    private categoriesService: CategoriesService,
  ) {
    this.isMobile = mobileDetectorService.isMobile()
  }

  ngOnInit(): void {
    this.store.pipe(take(1), select(selectProductPreAdd)).subscribe((product) => {
      if (product) {
        this.shape = product.shape
      }
    })
  }

  ngOnChanges({ category }: SimpleChanges): void {
    if (category?.currentValue) {
      this.validateFixedPrice()
    }
  }

  onToggleTopping(location: ToppingLocation, index: number) {
    if (this.category.products[index].location === location) {
      this.onRemove(this.category.products[index].id)
    } else {
      this.onAddTopping(location, index)
    }
  }

  private validateFixedPrice() {
    this.showFixedPrice = this.categoriesService.isShowFixedPrice(this.category)
  }

  private onAddTopping(location: ToppingLocation, index: number) {
    this.store.dispatch(ProductsActions.onAddDividedTopping({ location, index }))
  }

  private onRemove(id: string) {
    this.store.dispatch(ProductsActions.onRemovePreAddTopping({ id }))
  }
}
